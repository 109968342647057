import type { ProductSearchParams } from '@/interfaces/item/productRequest'
import type { GetCartItemsRequest } from '@/interfaces/cart/cartItem'
import type { WatchlistApiRequest } from '@/interfaces/watchList/watchListApiRequest'
import type { USER_SALES_HISTORY_SORT } from '@/interfaces/user/transaction'
import type { UserInventoryApiRequest } from '@/interfaces/inventory/inventoryApiRequest'
import type { PaginatedApiRequest } from '@/interfaces/api/pagination'
import type { AddToploadersPutArgs, ShipmentDetailsGetArgs, ShipmentItemsGetArgs } from '@/interfaces/shipments/current'

import { stringify } from 'querystring'

import URI from 'urijs'

import {
  type PromotionFilterParams,
  transformSortOrder,
  transformSellerFilter,
} from '@/interfaces/promotions/promotion'

// TODO: Organize better, use these URLs in the future

export const productDetailsURL = (params: ProductSearchParams) => {
  const { itemId, productId, gradeId, ownerId } = params
  let url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ProductDetails/ProductDetails?`

  if (itemId) {
    url += `itemId=${itemId}`
  } else {
    url += `productId=${productId}&gradeId=${gradeId}`
  }
  if (ownerId) {
    url += `&owner=${ownerId}`
  }
  return url
}

export const listingDetailsURL = (productId: string, gradeId?: string, ownerId?: string): string => {
  const searchURL = new URI(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ListingDetails`).query({
    productId,
    gradeId,
    ownerId,
  })
  return searchURL.toString()
}

export const auctionListingURL = (auctionId: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Auctions/ListingDetails/${auctionId}`

export const cartURL = (cartId: string) => `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ShoppingCart/${cartId}`

export const activePromotionsURL = (promotionsProps?: PromotionFilterParams) => {
  return new URI(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/promotions/active`)
    .query({
      sellerFilter: transformSellerFilter(promotionsProps?.seller),
      sortOrder: transformSortOrder(promotionsProps?.sortOrder),
      categoryPath: promotionsProps?.type,
      pageSize: promotionsProps?.pageSize,
      pageNumber: promotionsProps?.pageNumber,
    })
    .toString()
}

export const shipmentHistoryURL = (props: PaginatedApiRequest) => {
  return new URI(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/user/shipping`).query({ ...props }).toString()
}

export const modifyShipmentURL = () => {
  return new URI(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory/ShipmentItems`).toString()
}

export const shipmentDetailsURL = (props: ShipmentDetailsGetArgs) => {
  return new URI(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/user/shipping/details`).query({ ...props }).toString()
}

export const shipmentItemsURL = (props: ShipmentItemsGetArgs) => {
  return new URI(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/user/shipping/items`).query({ ...props }).toString()
}

export const inventoryAddToploaderURL = (props: AddToploadersPutArgs) => {
  return new URI(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory/TopLoaders`)
    .query({ ...props })
    .toString()
}

export const savedSearchesCountURL = (savedSearchIds: number[]) => {
  return new URI(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/SavedSearches/counts`)
    .query({ id: savedSearchIds })
    .toString()
}

export const searchSuggestionsURL = (searchTerm: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Search/autocomplete?text=${searchTerm}`
}

export const productSalesURL = (productId: number) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ProductDetails/Sales/${productId}`

export const baseCartURL = (cartId: string) => `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ShoppingCart/${cartId}`

export const userFundsSummaryURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/FundsSummary`

export const hasSufficientAvailableCreditURL = (fundsNeeded?: number) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/hasSufficientAvailableCredit?fundsNeeded=${fundsNeeded}`
}

export const countriesURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Countries`

export const cartCheckoutURL = (cartId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ShoppingCart/${cartId}/checkout`
}

export const cartDeliveryMethodsURL = (cartId: string, countryCode: string, stateCode: string, zipCode: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ShoppingCart/${cartId}/shippingOptions?CountryCode=${countryCode}&PostalCode=${zipCode}&StateCode=${stateCode}`
}

const shoppingCartURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ShoppingCart`

export const shoppingCartItemsURL = (cartId: string, requestProps?: GetCartItemsRequest) => {
  return new URI(`${shoppingCartURL}/${cartId}`).query({ ...requestProps }).toString()
}

export const editShoppingCartItemURL = (cartId: string, itemId: string) =>
  `${shoppingCartURL}/${cartId}/?itemId=${itemId}`

export const cartMergeURL = (cartId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ShoppingCart/${cartId}/merge`
}

const baseWatchlistURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/WatchList`

export const watchListItemsURL = (data?: WatchlistApiRequest) => {
  if (typeof data !== 'undefined' && Object.keys(data).length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = new URLSearchParams(data as any)
    return `${baseWatchlistURL}?${params}`
  } else {
    return baseWatchlistURL
  }
}

export const watchListAddRemoveUpdateURL = (itemId: string) => {
  return `${baseWatchlistURL}/${itemId}`
}

export const userSalesURL = (pageNumber?: number, pageSize?: number, sort?: USER_SALES_HISTORY_SORT) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/user/transaction/sales${
    (pageSize && pageNumber) || sort ? `?${stringify({ pageSize, pageNumber, sort })}` : ``
  }`

export const userPurchasesURL = (pageNumber?: number, pageSize?: number) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/user/transaction/purchase${
    pageSize && pageNumber ? `?${stringify({ pageSize, pageNumber })}` : ``
  }`

export const baseOfferURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Offer`

export const rejectOfferURL = (transactionId: string) => `${baseOfferURL}/${transactionId}/Reject`

export const counterOfferURL = (transactionId: string, offerAmount: number) =>
  `${baseOfferURL}/${transactionId}/Counter?offerAmount=${offerAmount}`

export const cancelOfferURL = (transactionId: string) => `${baseOfferURL}/${transactionId}/Cancel`

export const acceptOfferURL = (transactionId: string) => `${baseOfferURL}/${transactionId}/Accept`

export const pendingOffersURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/Offer/Pending`

export const offerItemsURL = (transactionId: string, pageSize: number, pageNumber: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Offer/${transactionId}/ItemsPaged${
    pageSize || pageNumber ? `?${stringify({ pageSize, pageNumber })}` : ''
  }`

export const browseListURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/Search/BrowseList`

// TODO: will be updated to use different sort and page params
export const getUserOfferHistoryURL = (pageSize: number, pageNumber: number, isActive: boolean) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Offer/HistoryPaged${
    pageSize || pageNumber || isActive ? `?${stringify({ pageSize, pageNumber, isActive })}` : ''
  }`

export const submitOfferURL = `${baseOfferURL}/MakeOfferDetails`

export const offerConversationURL = (transactionId: number) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Offer/Conversation?transactionId=${transactionId}`

export const offerSummaryURL = (transactionId: string | number) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/offer/${transactionId}`

export const creditRequestURL = (amount: string, cartId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/Credit/Request?CreditPurchaseAmount=${amount}&CartId=${cartId}`
}

export const storeCreditBillingSummaryURL = (amount: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/Credit/BillingSummary/${amount}`
}

export const userSummaryURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/user`

export const userStatsURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/user/stats`

export const userContactInfoURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/ContactInfo`

export const userAgreementURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/user/agreement`

export const userAuthenticatedURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/User/Authenticated`

export const invoiceSummaryURL = (invoiceId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Invoice/${invoiceId}/summary`
}

export const orderStatusURL = (invoiceId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Shipment/Details?invoice=${invoiceId}`
}

export const invoiceLockURL = (invoiceId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Invoice/${invoiceId}/LockItems`
}

export const invoiceListURL = (invoiceId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Invoice/${invoiceId}/list`
}

export const upgradeItemURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory/UpgradeItem`

export const auctionSubmissionURL = (itemId: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/${itemId}/Auctions/Submit`

export const eliteProcessingSubmissionURL = (itemId: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/${itemId}/Reprocessing/Submit`

export const gradingSubmissionURL = (itemId: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/${itemId}/Grading/Submit/`

export const setItemPriceURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory/SavePrice`

export const setListItemPriceURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory/SaveListPrice`

export const setItemForSaleStateURL = (itemId: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory/${itemId}/SetIsForSale`

export const ownedMatchingItemsForSaleURL = (itemId: string, pageNumber?: number, pageSize?: number) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory/MatchByOwner/${itemId}${
    pageSize && pageNumber ? `?${stringify({ pageSize, pageNumber })}` : ``
  }`

export const matchingItemsForSaleURL = (itemId: string, pageNumber?: number, pageSize?: number) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ListingDetails/Matching/${itemId}${
    pageSize && pageNumber ? `?${stringify({ pageSize, pageNumber })}` : ``
  }`
export const itemManagementDetailsURL = (itemId: number | string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/${itemId}`

export const inventoryItemURL = (itemId: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory/${itemId}`

export const getUserInventoryURL = (data?: UserInventoryApiRequest) => {
  const baseURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/User/Inventory`
  if (typeof data !== 'undefined' && Object.keys(data).length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = new URLSearchParams(data as any)
    return `${baseURL}?${params}`
  } else {
    return baseURL
  }
}

export const getGradingSubmissionsURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/Grading/InProgress`

export const getGradingHistoryURL = (pageNumber?: number, pageSize?: number) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/Grading/History${
    pageSize && pageNumber ? `?${stringify({ pageSize, pageNumber })}` : ``
  }`

export const getProcessingOptionsURL = (itemId: string, declaredValue: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/${itemId}/Reprocessing/Options?declaredValue=${declaredValue}`

export const getGradingOptionsURL = (itemId: string, declaredValue?: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/${itemId}/Grading/Options${
    declaredValue ? `/?declaredValue=${declaredValue}` : ''
  }`

export const getAuctionOptionsURL = (itemId: string, declaredValue: string) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/InventoryManagement/${itemId}/Auctions/Options/?declaredValue=${declaredValue}`

export const instantPurchaseCartURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/InstantPurchase/ShoppingCart`

export const instantPurchaseURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/InstantPurchase/Item`

export const postCorrectionURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Corrections/Report`

export const getIssueOptionsURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/Corrections/IssueOptions`

export const topLoaderURL = (cartId: string, itemId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ShoppingCart/${cartId}/TopLoader?itemId=${itemId}`
}

export const cartSummaryURL = (cartId: string) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/ShoppingCart/${cartId}/summary`
}

export const searchURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/Search/search`

export const recentSearchesListURL = () => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/search/recent/list`
}

export const recentSearchesURL = (recentSearchID: number) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/search/recent/${recentSearchID}`
}
