import type { BaseLink } from '@/interfaces/baseLink'

export const disableTooltipBasedOnLength = (title: string, count: number): boolean => {
  return title.length < count
}

export const isActiveRoute = (activeRoute: string, route: string | BaseLink[]): boolean => {
  if (typeof route === 'string') {
    return activeRoute === route
  }
  return route.some(({ href }) => href === activeRoute)
}
