export const CUSTOM_AMOUNT = 'Custom Amount'

export const DEFAULT_STORE_CREDIT_AMOUNT = '100'

export const CREDIT_AMOUNT_FIELDS = [
  {
    value: '25',
  },
  {
    value: '40',
  },
  {
    value: '60',
  },
  {
    value: DEFAULT_STORE_CREDIT_AMOUNT,
  },
  {
    value: '150',
  },
  {
    value: '250',
  },
  {
    value: '400',
  },
  {
    value: '600',
  },
  {
    value: '1000',
  },
  {
    value: '1500',
  },
  {
    value: '2500',
  },
  {
    value: CUSTOM_AMOUNT,
  },
]
