import type { Optional } from '@/types/utility'

import { ApplicationInsights, DistributedTracingModes, type Snippet } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { useSession } from 'next-auth/react'
import {
  createContext,
  type FC,
  type JSX,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react'
import { isSafeInteger, toSafeInteger } from 'lodash'

import { stripToNull } from '@/helpers/utils'

type AppInsightsWebConfig = Snippet['config']
interface ITelemetryContext {
  client: Optional<ApplicationInsights>
  plugin: Optional<ReactPlugin>
  loaded: boolean
}

const DEFAULT_APPLICATION_INSIGHTS_WEB_CONFIG: AppInsightsWebConfig = {
  disableTelemetry: false,
  autoTrackPageVisitTime: true,
  disableInstrumentationKeyValidation: false,
  distributedTracingMode: DistributedTracingModes.W3C,
  enableAjaxErrorStatusText: true,
  enableAjaxPerfTracking: false,
  enableAutoRouteTracking: true,
  enableCorsCorrelation: true,
  enableRequestHeaderTracking: true,
  enableResponseHeaderTracking: true,
  enableSessionStorageBuffer: true,
  enableUnhandledPromiseRejectionTracking: true,
  // @see https://github.com/microsoft/ApplicationInsights-JS/issues/2145
  disableFetchTracking: true,
  isBrowserLinkTrackingEnabled: true,
  loggingLevelConsole: 0,
  loggingLevelTelemetry: 2,
  samplingPercentage: 10,
  extensions: [],
  correlationHeaderDomains: ['localhost', 'beta.comc.com', 'beta.dev.comc.com', 'comc.com', 'beta.test.comc.com'],
  correlationHeaderExcludedDomains: ['launchdarkly.com'],
  customHeaders: [{ header: 'cache-control', value: 'no-cache' }],
}

const DEFAULT_TELEMETRY_CONTEXT = {
  loaded: false,
  client: undefined,
  plugin: undefined,
}
/** Set these here to get populated at build time */
const connectionString = process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING
const samplingPercentage = process.env.NEXT_PUBLIC_APPINSIGHTS_SAMPLING_PERCENTAGE
const disableTelemetry = stripToNull(process.env.NEXT_PUBLIC_DISABLE_APPINSIGHTS) === 'true'
const enableDebug = stripToNull(process.env.NEXT_PUBLIC_APPINSIGHTS_DEBUG) === 'true'
const appId = stripToNull(process.env.NEXT_PUBLIC_APPINSIGHTS_APP_ID) || 'local'

function createAppInsightsContext(): ITelemetryContext {
  const ctx: ITelemetryContext = { ...DEFAULT_TELEMETRY_CONTEXT }
  if (connectionString) {
    const plugin = new ReactPlugin()
    const config: AppInsightsWebConfig = {
      ...DEFAULT_APPLICATION_INSIGHTS_WEB_CONFIG,
      disableTelemetry,
      connectionString,
      enableDebug,
      extensions: [plugin],
      appId,
      samplingPercentage: isSafeInteger(samplingPercentage)
        ? toSafeInteger(samplingPercentage)
        : DEFAULT_APPLICATION_INSIGHTS_WEB_CONFIG.samplingPercentage,
    }
    ctx.client = new ApplicationInsights({ config })
    ctx.plugin = plugin
  }
  return ctx
}

type ApplicationInsightsProviderProps = PropsWithChildren

const TelemetryContext = createContext<ITelemetryContext>(DEFAULT_TELEMETRY_CONTEXT)

const NoOpTelemetryProvider: FC<ApplicationInsightsProviderProps> = ({ children }): JSX.Element => {
  return <>{children}</>
}

const AppInsightTelemetryProvider: FC<ApplicationInsightsProviderProps> = ({ children }): JSX.Element => {
  const { data: session } = useSession()
  const [context, setContext] = useState<ITelemetryContext>(DEFAULT_TELEMETRY_CONTEXT)
  const [loaded, setLoaded] = useState(false)
  const [loadError, setLoadError] = useState(false)
  // Run on mount
  useMemo(() => {
    if (!loaded && !loadError) {
      const ctx = createAppInsightsContext()
      if (ctx.client) {
        try {
          ctx.client.loadAppInsights()
          ctx.loaded = true
          setLoaded(true)
          setContext(ctx)
        } catch (e) {
          const err = new Error('Unable to load Application Insights', { cause: e })
          setLoadError(true)
          console.error(err)
        }
      } else {
        console.warn('Application Insights: Missing required configuration.')
        setLoadError(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loaded && !loadError) {
      if (session?.user) {
        const user = session.user
        context.client!.setAuthenticatedUserContext(user.name, user.id, true)
      } else {
        context.client!.clearAuthenticatedUserContext()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user, loaded])

  return <TelemetryContext.Provider value={context}>{children}</TelemetryContext.Provider>
}

const TelemetryProvider: FC<ApplicationInsightsProviderProps> = (props): JSX.Element => {
  return typeof window === 'undefined' ? (
    <NoOpTelemetryProvider {...props} />
  ) : (
    <AppInsightTelemetryProvider {...props} />
  )
}

export const useTelemetryProvider = (): ITelemetryContext => useContext(TelemetryContext)

export default TelemetryProvider
