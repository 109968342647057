import type { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import type {
  IAppInsightsErrorBoundaryProps,
  IAppInsightsErrorBoundaryState,
} from '@microsoft/applicationinsights-react-js/types/AppInsightsErrorBoundary'

import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { Component, type FC, type ErrorInfo, type ReactNode } from 'react'

import { useTelemetryProvider } from '@/components/contextProviders/TelemetryProvider'

interface BoundaryProps extends Omit<IAppInsightsErrorBoundaryProps, 'appInsights'> {
  children: React.ReactElement
  fallbackComponent?: ReactNode
  appInsights?: ReactPlugin
}
interface State extends IAppInsightsErrorBoundaryState {
  hasError: boolean
}
class ErrorBoundary extends Component<BoundaryProps, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.NEXT_PUBLIC_CONSOLE_ERROR_LOGGING === 'true' && process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
      console.error('Uncaught error:', error, errorInfo)
    }
    if (this.props.appInsights) {
      this.props.appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: errorInfo,
      })
    }
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent
    }
    return this.props.children
  }
}

type BoundaryWrapperProps = {
  fallbackComponent?: ReactNode
  children: React.ReactElement
}

const ErrorBoundaryWrapper: FC<BoundaryWrapperProps> = ({ children, fallbackComponent }) => {
  const { plugin } = useTelemetryProvider()
  return (
    <ErrorBoundary appInsights={plugin} onError={() => <></>} fallbackComponent={fallbackComponent}>
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryWrapper
