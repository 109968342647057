import type { Optional } from '@/types/utility'
import type { CreateOrderRequestBody, OrderResponseBody } from '@paypal/paypal-js'
import type { InstantPurchaseRequest } from '@/interfaces/item/instantPurchase/instantPurchaseRequest'
import type { InstantPurchaseResult } from '@/interfaces/item/instantPurchase/instantPurchaseResult'
import type { ProductDetailsParams } from '@/interfaces/item/productRequest'
import type { StoreCreditCheckoutParams } from '@/interfaces/user/storeCredit/storeCreditChekout'

import {
  type ChangeEvent,
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useMutation } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

import useToggleState from '@/hooks/useToggleState'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { getProductDetails } from '@/pages/api/productDetails'
import { useItemDetailsProvider } from './itemDetailsProvider'
import { clientAPIRequest } from '@/helpers'
import useStoreCreditPaypalOrder from '@/hooks/data/useStoreCreditPaypalOrder'

type StoreCreditContext = {
  selectedCreditAmount: string
  setSelectedCreditAmount: Dispatch<SetStateAction<string>>
  customAmount: {
    value: string
    isValid: boolean
  }
  setCustomAmount: Dispatch<SetStateAction<{ value: string; isValid: boolean }>>
  isReceiveNewsAndPromotions: boolean
  isReceiveNewsAndPromotionsToggleHandler: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  instantPurchaseMutation: (payload: { purchaseParams: InstantPurchaseRequest; accessToken: string }) => void
  instantPurchaseResult?: InstantPurchaseResult
  isInstantPurchaseLoading?: boolean
  setInstantPurchaseResult: Dispatch<SetStateAction<InstantPurchaseResult | undefined>>
  storeCreditCheckoutParams: StoreCreditCheckoutParams | undefined
  setStoreCreditCheckoutParams: Dispatch<SetStateAction<StoreCreditCheckoutParams | undefined>>
  isStoreCreditPaypalOrderLoading: boolean
  storeCreditPaypalOrder?: CreateOrderRequestBody
  storeCreditPaypalTransactionResult: Optional<OrderResponseBody>
  setStoreCreditPaypalTransactionResult: Dispatch<SetStateAction<Optional<OrderResponseBody>>>
}

const storeCreditContext = createContext<StoreCreditContext>({
  selectedCreditAmount: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedCreditAmount: () => {},
  customAmount: {
    value: '',
    isValid: true,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCustomAmount: () => {},
  isReceiveNewsAndPromotions: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  isReceiveNewsAndPromotionsToggleHandler: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  instantPurchaseMutation: () => {},
  instantPurchaseResult: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInstantPurchaseResult: () => {},
  storeCreditCheckoutParams: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStoreCreditCheckoutParams: () => {},
  isStoreCreditPaypalOrderLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  storeCreditPaypalOrder: {} as CreateOrderRequestBody,
  storeCreditPaypalTransactionResult: undefined,
  setStoreCreditPaypalTransactionResult: () => undefined,
})

export default function StoreCreditProvider({ children }: PropsWithChildren): JSX.Element {
  const [selectedCreditAmount, setSelectedCreditAmount] = useState<string>('')
  const [customAmount, setCustomAmount] = useState<{ value: string; isValid: boolean }>({
    value: '',
    isValid: true,
  })
  const [instantPurchaseResult, setInstantPurchaseResult] = useState<InstantPurchaseResult>()
  const [storeCreditCheckoutParams, setStoreCreditCheckoutParams] = useState<StoreCreditCheckoutParams>()
  const [storeCreditPaypalTransactionResult, setStoreCreditPaypalTransactionResult] = useState<OrderResponseBody>()
  const { toggleState: isReceiveNewsAndPromotions, toggleStateHandler: isReceiveNewsAndPromotionsToggleHandler } =
    useToggleState()
  const { setItem } = useItemDetailsProvider()
  const { data: session } = useSession()
  const { isStoreCreditPaypalOrderLoading, storeCreditPaypalOrder, fetchStoreCreditPaypalOrder } =
    useStoreCreditPaypalOrder(storeCreditCheckoutParams)

  const postInstantPurchase = useCallback(
    async (purchaseParams: InstantPurchaseRequest) => {
      return clientAPIRequest<InstantPurchaseResult, unknown>(
        '/api/instantPurchase/item',
        RequestMethods.POST,
        purchaseParams,
        session
      )
    },
    [session]
  )

  const { mutate: instantPurchaseMutation, isPending: isInstantPurchaseLoading } = useMutation({
    mutationFn: (payload: { purchaseParams: InstantPurchaseRequest; accessToken: string }) =>
      postInstantPurchase(payload.purchaseParams),
    onSuccess: async (_data, variables) => {
      setInstantPurchaseResult(_data)

      const productDetailsParams: ProductDetailsParams = {
        itemId: variables.purchaseParams.itemId,
        accessToken: variables.accessToken,
      }
      const productDetailsByItemId = await getProductDetails(productDetailsParams)
      const item = productDetailsByItemId?.inventoryProducts[0]
      setItem(item)
    },
  })

  useEffect(() => {
    if (storeCreditCheckoutParams) {
      fetchStoreCreditPaypalOrder().then(() => Promise.resolve())
    }
  }, [storeCreditCheckoutParams, fetchStoreCreditPaypalOrder])

  const providerValue = useMemo(
    () => ({
      customAmount,
      setCustomAmount,
      selectedCreditAmount,
      setSelectedCreditAmount,
      isReceiveNewsAndPromotions,
      isReceiveNewsAndPromotionsToggleHandler,
      instantPurchaseMutation,
      isInstantPurchaseLoading,
      instantPurchaseResult,
      setInstantPurchaseResult,
      storeCreditCheckoutParams,
      setStoreCreditCheckoutParams,
      isStoreCreditPaypalOrderLoading,
      storeCreditPaypalOrder,
      storeCreditPaypalTransactionResult,
      setStoreCreditPaypalTransactionResult,
    }),
    [
      customAmount,
      selectedCreditAmount,
      isReceiveNewsAndPromotions,
      isReceiveNewsAndPromotionsToggleHandler,
      instantPurchaseMutation,
      isInstantPurchaseLoading,
      instantPurchaseResult,
      storeCreditCheckoutParams,
      isStoreCreditPaypalOrderLoading,
      storeCreditPaypalOrder,
      storeCreditPaypalTransactionResult,
      setStoreCreditPaypalTransactionResult,
    ]
  )

  return <storeCreditContext.Provider value={providerValue}>{children}</storeCreditContext.Provider>
}

export const useStoreCreditProvider = (): StoreCreditContext => useContext(storeCreditContext)
