/** @source https://fonts.google.com/icons?selected=Material+Symbols+Outlined:payments:&icon.style=Filled **/

import { createSvgIcon } from '@mui/material'

const path = (
  <path d="M540-420q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM160-280v-520h760v520H160ZM40-160v-520h60v460h700v60H40Zm180-480q42 0 71-29t29-71H220v100Zm640 0v-100H760q0 42 29 71t71 29ZM760-340h100v-100q-42 0-71 29t-29 71Zm-540 0h100q0-42-29-71t-71-29v100Z" />
)
const svg = (
  <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
    {path}
  </svg>
)

const PaymentsFilledSymbol = createSvgIcon(svg, 'PaymentsFilled')
export default PaymentsFilledSymbol
