// Slight rework of animateCSS function here https://animate.style/#javascript

export const useAnimation = <T extends HTMLElement>() => {
  const animateCSS = (element: T, animation: string, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve) => {
      const animationName = `${prefix}${animation}`

      element.classList.add(`${prefix}animated`, animationName)

      // When the animation ends, we clean the classes and resolve the Promise
      function handleAnimationEnd(event: AnimationEvent) {
        event.stopPropagation()
        element.classList.remove(`${prefix}animated`, animationName)
        resolve('Animation ended')
      }

      element.addEventListener('animationend', handleAnimationEnd, { once: true })
    })

  return {
    animateCSS,
  }
}
