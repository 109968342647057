import { useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'

import { NextAuthStatuses } from '@/interfaces/auth/nextAuth'

export default function useAuthState() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const { data: session, status } = useSession()

  useEffect(() => {
    if (status === NextAuthStatuses.LOADING) return
    if (status === NextAuthStatuses.UNAUTHENTICATED) {
      setIsAuthenticated(false)
    } else if (status === NextAuthStatuses.AUTHENTICATED) {
      const accessToken = session?.accessToken
      setIsAuthenticated(!!accessToken)
    }
  }, [session, status])

  return { isAuthenticated }
}
