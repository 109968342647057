import type { NextApiRequest, NextApiResponse } from 'next'
import type { ProductDetailsResults } from '@/interfaces/item/productDetailsResult'
import type { ProductDetailsParams } from '@/interfaces/item/productRequest'

import { getToken } from 'next-auth/jwt'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import { productDetailsURL } from '@/constants/apiURLs'

type ProductDetailsResponse = {
  data?: ProductDetailsResults
  error?: unknown
}

// TODO: Not sure if there is a way to make this function use a helper
export const getProductDetails = async (params: ProductDetailsParams): Promise<ProductDetailsResults | undefined> => {
  const { accessToken, ...reqParams } = params
  const data = await fetch(productDetailsURL(reqParams), {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
  }).then((response) => {
    return response.status === 200 ? response.json() : undefined
  })
  return data
}

const productDetailsHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<ProductDetailsResponse>
): Promise<unknown> => {
  if (req.method !== RequestMethods.POST) {
    res.status(405).end('Method Not Allowed')
  }

  const session = await getToken({
    req,
    secret: process.env.NEXTAUTH_SECRET,
  })
  const token = session?.accessToken

  const { body } = req
  const { itemId, productId, gradeId, ownerId, accessToken } = JSON.parse(body)

  return new Promise((resolve: (value?: unknown | PromiseLike<unknown>) => void) => {
    try {
      getProductDetails({ itemId, productId, gradeId, ownerId, accessToken: token || accessToken }).then((data) => {
        if (data) {
          res.status(200).json({ data })
        } else {
          res.status(204).end()
        }
        resolve()
      })
    } catch (e) {
      res.status(400).json({ error: e })
      resolve()
    }
  })
}

export default productDetailsHandler
