import type { FC } from 'react'

import { signOut } from 'next-auth/react'
import { ListItemText, MenuItem, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'

import { STRINGS } from '@/constants'

const SignOutMenuItem: FC = () => {
  return (
    <MenuItem sx={{ px: 4 }} onClick={() => signOut({ callbackUrl: '/' })}>
      <ListItemText>{STRINGS.SIGN_OUT_ACTION}</ListItemText>
      <Typography color="text.secondary" ml={5}>
        <LogoutIcon sx={{ verticalAlign: 'middle' }} />
      </Typography>
    </MenuItem>
  )
}

export default SignOutMenuItem
