import type { InvoiceListItem } from '@/interfaces/invoice/invoiceList'

export const INVOICE_ITEMS_PER_PAGE = 10

export type INVOICE_ITEM_SORT_OPTION = {
  title: string
  property: keyof InvoiceListItem | 'sort'
}

export const DEFAULT_INVOICE_ITEM_SORT: INVOICE_ITEM_SORT_OPTION = {
  title: 'Sort',
  property: 'sort',
}

export const INVOICE_ITEMS_SORT_OPTIONS: INVOICE_ITEM_SORT_OPTION[] = [
  {
    title: 'Default Sort',
    property: 'sort',
  },
  {
    title: 'Item ID',
    property: 'itemId',
  },
  {
    title: 'Set Name',
    property: 'setName',
  },
  {
    title: 'Description',
    property: 'description',
  },
  {
    title: 'Price',
    property: 'purchasePrice',
  },
  {
    title: 'Listing Number',
    property: 'listingNumber',
  },
]
