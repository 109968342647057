'use client'

import type URI from 'urijs'

import * as React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import NextLink, { type LinkProps as NextLinkProps } from 'next/link'
import MuiLink, { type LinkProps as MuiLinkProps } from '@mui/material/Link'
import { styled } from '@mui/material/styles'

import { isExternalLink, toURI } from '@/helpers/urlParser'

type UrlLike = string | URI | URL

/** Add support for the sx prop for consistency with the other branches. */
const Anchor = styled('a')({})

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as' | 'passHref' | 'onMouseEnter' | 'onClick' | 'onTouchStart'> {
  to: NextLinkProps['href']
  linkAs?: NextLinkProps['as']
}

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
  function NextLinkComposed(props, ref) {
    const { to, linkAs, replace, scroll, shallow, prefetch, legacyBehavior = true, locale, ...other } = props

    return (
      <NextLink
        href={to}
        prefetch={prefetch}
        as={linkAs}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref
        locale={locale}
        legacyBehavior={legacyBehavior}
      >
        <Anchor ref={ref} {...other} />
      </NextLink>
    )
  }
)

type LinkUnstyledValueMap<Unstyled, T> = Unstyled extends true ? T : never

type BaseLinkProps<Unstyled extends boolean | undefined> = Omit<
  MuiLinkProps,
  'href' | LinkUnstyledValueMap<Unstyled, 'color'>
> & {
  activeClassName?: string
  noLinkStyle?: Unstyled
}

type ExternalLinkProps<Unstyled extends boolean | undefined> = BaseLinkProps<Unstyled> & {
  href: URI
}
const ExternalLink = React.forwardRef(function ExternalLink<Unstyled extends boolean | undefined>(
  props: ExternalLinkProps<Unstyled>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const { noLinkStyle, href: uri, ...other } = props
  const _href = uri.href()

  if (noLinkStyle) {
    return <Anchor ref={ref} href={_href} {...other} />
  }

  return <MuiLink ref={ref} href={_href} {...other} />
})

type InternalLinkProps<Unstyled extends boolean | undefined> = BaseLinkProps<Unstyled> & {
  href: URI
  as?: NextLinkProps['as']
  /** Useful when the `as` prop is shallow by styled() */
  linkAs?: NextLinkProps['as']
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'>
const InternalLink = React.forwardRef(function InternalLink<Unstyled extends boolean | undefined>(
  props: InternalLinkProps<Unstyled>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const {
    activeClassName = 'active',
    as,
    className: classNameProps,
    href: uri,
    legacyBehavior,
    linkAs: linkAsProp,
    locale,
    noLinkStyle,
    prefetch,
    replace,
    scroll,
    shallow,
    ...other
  } = props

  const router = useRouter()

  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === uri.pathname() && activeClassName,
  })

  const linkAs = linkAsProp || as
  const _href = uri.href()
  const nextjsProps = {
    href: _href,
    to: _href,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    legacyBehavior,
    locale,
  }

  if (noLinkStyle) {
    return <NextLinkComposed className={className} ref={ref} {...nextjsProps} {...other} />
  }

  return <MuiLink component={NextLinkComposed} className={className} ref={ref} {...nextjsProps} {...other} />
})

export type LinkProps<
  NoRouter extends boolean | undefined = false,
  Unstyled extends boolean | undefined = false,
> = Omit<ExternalLinkProps<Unstyled>, 'href'> &
  Omit<InternalLinkProps<Unstyled>, 'href'> & {
    href: NextLinkProps['href'] | UrlLike
    /** Do not use next/router and use a simple `<a>` tag */
    noRouter?: NoRouter
  }
const Link = React.forwardRef(function Link<
  NoRouter extends boolean | undefined = false,
  Unstyled extends boolean | undefined = false,
>(props: LinkProps<NoRouter, Unstyled>, ref: React.ForwardedRef<HTMLAnchorElement>) {
  const { href, noRouter, ...other } = props

  const uri = toURI(href).normalize()

  if (noRouter || isExternalLink(uri)) {
    return <ExternalLink href={uri} ref={ref} {...other} />
  } else {
    return <InternalLink href={uri} ref={ref} {...other} />
  }
})
export default Link
