/** @source https://fonts.google.com/icons?selected=Material+Symbols+Outlined:payments:&icon.style=Outlined **/

import { createSvgIcon } from '@mui/material'

const path = (
  <path d="M540-420q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM160-280v-520h760v520H160Zm160-60h440q0-42 29-71t71-29v-200q-42 0-71-29t-29-71H320q0 42-29 71t-71 29v200q42 0 71 29t29 71ZM40-160v-520h60v460h700v60H40Zm180-180v-400 400Z" />
)
const svg = (
  <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
    {path}
  </svg>
)
const PaymentsOutlinedSymbol = createSvgIcon(svg, 'Payments')

export default PaymentsOutlinedSymbol
