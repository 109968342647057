import type { ObjWItemId } from './inventory/inventoryItemResults'

export interface ResultWImage extends ObjWItemId {
  listingPath?: string
  sharePath?: string
  imageId: string
}

export interface ImageWStockImage extends ResultWImage {
  stockImage?: boolean
}

export interface ImageWIsStockImage extends ResultWImage {
  isStockImage?: boolean
}

export enum CARD_SIDE {
  FRONT = 'front',
  BACK = 'back',
}

export enum CARD_RESOLUTION {
  THUMB = 'thumb',
  BIG_THUMB = 'bigthumb2',
  BIGGER_THUMB = 'biggerthumb',
  ORIGINAL = 'original',
  ZOOM = 'zoom',
  GOOGLE = 'google',
  EBAY = 'ebay',
}
