import { getLegacyUrl } from './vintageLinks'

const FOOTER_SECTIONS = {
  'About Us': {
    title: 'About Us',
    links: [
      {
        text: 'Company Info' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/AboutUs.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Contact Us' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/ContactUs.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Job Openings',
        props: {
          href: 'https://blog.comc.com/comc-job-board/',
          target: '_',
        },
      },
      {
        text: 'Testimonials' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/Testimonials.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'FAQ',
        props: {
          href: 'https://comc.zendesk.com/hc/en-us',
          target: '_',
        },
      },
      {
        text: 'Event Calendar' /*  TODO: If this data is in the database we could make a cool page for this */,
        props: {
          href: 'https://blog.comc.com/upcoming-card-shows-and-appearances',
          target: '_',
        },
      },
    ],
  },
  Buy: {
    title: 'Buy',
    links: [
      {
        text: 'Shipping Rates' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/shipping.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Return Policy' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/returns.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Buy Collectibles' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/buy.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Add Store Credit' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/addcredit.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Items Stored Remotely' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/RemoteItems.aspx').href(),
          target: '_',
        },
      },
    ],
  },
  Sell: {
    title: 'Sell',
    links: [
      {
        text: 'Sell Trading Cards' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/Sell.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Selling Rates',
        props: {
          href: 'https://blog.comc.com/selling-rates/',
          target: '_',
        },
      },
      {
        text: 'How To Ship To Us' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/AddingYourCards.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Supported Trading Cards',
        props: {
          href: 'https://blog.comc.com/comc-supported-trading-cards/',
          target: '_',
        },
      },
      {
        text: 'Determining Card Price' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/GetSuggestedPrices.aspx').href(),
          target: '_',
        },
      },
    ],
  },
  'Other Services': {
    title: 'Other Services',
    links: [
      {
        text: 'COMC Mailbox',
        props: {
          href: 'https://blog.comc.com/mailbox-service',
          target: '_',
        },
      },
      {
        text: 'Grading' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/grading.aspx').href(),
          target: '_',
        },
      },
      {
        text: 'Using Our Images' /*  TODO: Add Page */,
        props: {
          href: getLegacyUrl('/help/UsingImages.aspx').href(),
          target: '_',
        },
      },
    ],
  },
  'Business Partners': {
    title: 'Business Partners',
    links: [
      {
        text: 'BCW Supplies',
        props: {
          href: 'https://www.bcwsupplies.com/',
          target: '_',
        },
      },
      {
        text: 'Blowout Cards',
        props: {
          href: 'https://blowoutcards.com/',
          target: '_',
        },
      },
    ],
  },
  Connect: {
    title: 'Connect',
    links: [
      {
        text: 'Subscribe to our Newsletter',
        props: {
          href: 'https://visitor.r20.constantcontact.com/manage/optin/ea?v=001Yn0Ph-NopiBdyKPFluUgrg%3D%3D',
          target: '_',
        },
      },
      {
        text: 'Follow our Blog',
        props: {
          href: 'https://blog.comc.com/',
          target: '_',
        },
      },
    ],
  },
}

export default FOOTER_SECTIONS
