import type { ListingDetailsResult } from '@/interfaces/item/listingDetailsResult'

import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

import { listingDetailsURL } from '@/constants/apiURLs'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { directAPIRequest } from '@/helpers'
import { NextAuthStatuses } from '@/interfaces/auth/nextAuth'

const useListingDetails = (productId?: number, gradeId?: number, ownerId?: string) => {
  const { data: session, status } = useSession()

  const {
    data: listingDetails,
    isLoading: isListingDetailsLoading,
    isError: isListingDetailsError,
    ...props
  } = useQuery<unknown, unknown, ListingDetailsResult>({
    queryKey: ['listingDetails', { productId, ownerId }],
    queryFn: () =>
      directAPIRequest(
        [RequestMethods.GET],
        listingDetailsURL(productId!.toString(), gradeId ? gradeId.toString() : undefined, ownerId),
        false,
        {
          method: RequestMethods.GET,
          session,
        }
      ),
    enabled: !!productId && status !== NextAuthStatuses.LOADING,
    initialData: undefined,
    placeholderData: (prev: unknown) => prev,
  })

  return { listingDetails, isListingDetailsLoading, isListingDetailsError, props }
}

export default useListingDetails
