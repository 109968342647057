import type { ChildProps } from '@/interfaces/baseProps'

import { type FC, useEffect, useState } from 'react'
import { Container, Box } from '@mui/material'

import Header from './Header'
import ScrollToTop from './ScrollToTop'
import Footer from './Footer'
import TermsAndConditionsModal from '@/components/ui/user/TermsAndConditionsModal'
import { useUserProvider } from '@/store/userProvider'
import { EnvironmentAlert } from './EnvironmentAlert'
import ErrorBoundaryWrapper from '@/components/ui/ErrorBoundary'
import useFlags from '@/helpers/useFlags'

const Layout: FC<ChildProps> = ({ children }) => {
  const { isUserSummaryLoading, needsActivation } = useUserProvider()
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)
  const { betaWebSiteBranding } = useFlags()

  useEffect(() => {
    if (!isUserSummaryLoading && typeof needsActivation !== 'undefined') {
      setShowTermsAndConditions(needsActivation)
    }
  }, [needsActivation, isUserSummaryLoading])

  return (
    <>
      <Container
        sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', minHeight: '100vh' }}
        disableGutters
        maxWidth={false}
      >
        <Box minHeight="98vh">
          <ErrorBoundaryWrapper>{betaWebSiteBranding && <EnvironmentAlert />}</ErrorBoundaryWrapper>
          <ErrorBoundaryWrapper>
            <Header />
          </ErrorBoundaryWrapper>
          <ErrorBoundaryWrapper>
            <Box id="main" component="main" sx={{ flexGrow: 1 }} position="relative">
              {children}
            </Box>
          </ErrorBoundaryWrapper>
        </Box>
        <ErrorBoundaryWrapper>
          <Footer />
        </ErrorBoundaryWrapper>
      </Container>
      {!isUserSummaryLoading && typeof needsActivation !== 'undefined' && showTermsAndConditions && (
        <TermsAndConditionsModal modalState={showTermsAndConditions} />
      )}
      <ScrollToTop />
    </>
  )
}

export default Layout
