import { createContext, type PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import { DEFAULT_PRODUCT_INVENTORY_ITEMS_PER_PAGE } from '@/constants/product'
import theme from '@/themes/comcTheme'

// type SearchPageSettings = {
//   sort: string
// }

// type ProductPageSettings = {
//   magnifyOnHover: boolean
//   sideBySide: boolean
// }

type ViewSettings = 'tileView' | 'tableView'

type UserSettingsContext = {
  userSort?: string
  setUserSort: (newVal: string) => void
  userMagnifyOnHover?: boolean
  setUserMagnifyOnHover: (newVal: boolean) => void
  userSideBySide?: boolean
  setUserSideBySide: (newVal: boolean) => void
  userShowBacks?: boolean
  setUserShowBacks: (newVal: boolean) => void
  inventoryItemsNumberPerPage: number
  setInventoryItemsNumberPerPage: (newVal: number) => void
  selectedView: ViewSettings
  setSelectedView: (newVal: ViewSettings) => void
}

const UserSettingsContext = createContext<UserSettingsContext>({
  setUserSort: () => {
    /* do nothing */
  },
  setUserMagnifyOnHover: () => {
    /* do nothing */
  },
  setUserSideBySide: () => {
    /* do nothing */
  },
  setUserShowBacks: () => {
    /* do nothing */
  },
  inventoryItemsNumberPerPage: 4,
  setInventoryItemsNumberPerPage: () => {
    /* do nothing */
  },
  selectedView: 'tileView',
  setSelectedView: () => {
    /* do nothing */
  },
})

function getInitialState(key: string) {
  if (typeof localStorage === 'undefined') {
    return
  }
  const value = localStorage.getItem(key)
  return value ? JSON.parse(value) : undefined
}

export default function UserSettingsProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down('md'))
  const [userSort, setUserSort] = useState<string>(getInitialState('sort'))
  const [userMagnifyOnHover, setUserMagnifyOnHover] = useState<boolean>(getInitialState('magnifyOnHover'))
  const [userSideBySide, setUserSideBySide] = useState<boolean>(getInitialState('sideBySide'))
  const [userShowBacks, setUserShowBacks] = useState<boolean>(getInitialState('sideBySide'))
  const [inventoryItemsNumberPerPage, setInventoryItemsNumberPerPage] = useState<number>(
    getInitialState('inventoryItemsNumberPerPage') || DEFAULT_PRODUCT_INVENTORY_ITEMS_PER_PAGE
  )
  const [selectedView, setSelectedView] = useState<'tileView' | 'tableView'>(
    getInitialState('view') || (isSmallScreenSize ? 'tileView' : 'tableView')
  )

  useEffect(() => {
    if (!selectedView) {
      return
    }
    localStorage.setItem('view', JSON.stringify(selectedView))
  }, [selectedView])

  useEffect(() => {
    if (!userSort) {
      return
    }
    localStorage.setItem('sort', JSON.stringify(userSort))
  }, [userSort])

  useEffect(() => {
    if (typeof userMagnifyOnHover === 'undefined') {
      return
    }
    localStorage.setItem('magnifyOnHover', JSON.stringify(userMagnifyOnHover))
  }, [userMagnifyOnHover])

  useEffect(() => {
    if (typeof userSideBySide === 'undefined') {
      return
    }
    localStorage.setItem('sideBySide', JSON.stringify(userSideBySide))
  }, [userSideBySide])

  useEffect(() => {
    if (!inventoryItemsNumberPerPage) {
      return
    }
    localStorage.setItem('inventoryItemsNumberPerPage', JSON.stringify(inventoryItemsNumberPerPage))
  }, [inventoryItemsNumberPerPage])

  const userSettingsValue = useMemo(
    () => ({
      userSort,
      userMagnifyOnHover,
      userSideBySide,
      userShowBacks,
      inventoryItemsNumberPerPage,
      selectedView,
      setUserSort,
      setUserMagnifyOnHover,
      setUserSideBySide,
      setUserShowBacks,
      setInventoryItemsNumberPerPage,
      setSelectedView,
    }),
    [userSort, userMagnifyOnHover, userSideBySide, userShowBacks, inventoryItemsNumberPerPage, selectedView]
  )

  return <UserSettingsContext.Provider value={userSettingsValue}>{children}</UserSettingsContext.Provider>
}

export const useUserSettingsProvider = (): UserSettingsContext => useContext(UserSettingsContext)
