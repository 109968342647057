import { signIn as nextSignIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import useAuthState from './useAuthState'
import { DASHBOARD_BASE_HREF } from '@/constants'
import { strip } from '@/helpers'

export default function useSignIn() {
  const { isAuthenticated } = useAuthState()
  const router = useRouter()
  const signIn = useCallback(
    ({
      alternateCallbackUrl,
      force = false,
      callbackFn,
    }: { alternateCallbackUrl?: string; force?: boolean; callbackFn?: () => void } = {}) => {
      const defaultCallbackUrl = router.asPath === '/' ? DASHBOARD_BASE_HREF : router.asPath
      const callbackUrl = strip(alternateCallbackUrl, defaultCallbackUrl)
      if (!isAuthenticated || force) {
        return nextSignIn('azure-ad-b2c', { callbackUrl }, { prompt: 'login' })
      } else if (callbackFn) {
        callbackFn()
      }
    },
    [router.asPath, isAuthenticated]
  )
  return {
    signIn,
  }
}
