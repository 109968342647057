export enum RequestMethods {
  GET = 'GET',
  DELETE = 'DELETE',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export interface PatchParams {
  value?: string | number | boolean | null
  path?: string
  op?: string
  from?: string
}
