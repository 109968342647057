import { CARD_IMAGE_BASE_URL } from '@/constants'
import { CARD_RESOLUTION, CARD_SIDE } from '@/interfaces/resultWImage'

export const currentItemPrice = (isOnSale: boolean, discountedPrice: number, price: number) => {
  return isOnSale ? discountedPrice : price
}

export const needsAttention = (shipmentTransactionId?: number, askingPrice?: number, notForSale?: boolean) => {
  return !shipmentTransactionId && !askingPrice && !notForSale
}

export const userHasMonopoly = (itemQtyForSale?: number, ownedItemQtyForSale?: number) => {
  return itemQtyForSale === ownedItemQtyForSale
}

export const parentSetName = (setName: string, parentSetName?: string) => {
  return setName.replace(`${parentSetName} - ` || '', '')
}

// TODO: Add tests for this
export const getImageURL = (
  imageId: string,
  listingPath?: string,
  size: CARD_RESOLUTION = CARD_RESOLUTION.BIGGER_THUMB,
  cardSide: CARD_SIDE = CARD_SIDE.FRONT
) => {
  return `${CARD_IMAGE_BASE_URL}/i/${listingPath
    ?.substring(0, 250)
    .replaceAll('_', '-')
    .replaceAll('+', '-')}.jpg?id=${imageId}&size=${size}${cardSide === CARD_SIDE.BACK ? `&side=back` : ''}`
}
