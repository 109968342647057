import type { SearchParams, SearchParamsPayload } from '@/interfaces/searchRequest'
import type { AvailabilityOptions, ListingFormatOptions } from '@/interfaces/filters'

import { type ParsedUrlQueryInput, stringify } from 'querystring'

import { get, has, isEqual, isInteger, isNil, pickBy, toSafeInteger } from 'lodash'

import { sortEnumToQueryValue, sortQueryValueToItemSort } from '@/constants/sort'
import { isNotBlank, stripToNull } from '@/helpers'

export interface SavedSearchProps {
  title: string
  search: string
  subSearch: string
  sort: string
  playerId: number | null
  teamId: number | null
  /**
   * @see SearchTerms.attributes
   */
  attributePaths: string
  /**
   * @see SearchParams.ownerName
   */
  owner: string
  gradeActionPath: string
  graderPath: string
  gradePath: string
  /**
   * @see SearchParams.sportPath
   */
  sportPath: string
  decadePath: string | null
  yearPath: string | null
  yearMin?: number | null
  yearMax?: number | null
  parentSetPath: string
  setPath: string
  listingFormat: string
  /**
   * Turn on email alerts?
   * @ignore Not implemented server-side
   */
  shouldNotify: boolean
}

/**
 * For creating new SavedSearch in the API
 */
export interface SavedSearchRequest extends Partial<NonNullable<SavedSearchProps>> {
  title: string
  /**
   * @see AvailabilityOptions
   * @todo refactor to use enums
   */
  searchSoldOut?: string
}

/**
 * Individual SavedSearch object from SavedSearchesResponse
 * String values always come back as set to empty string.
 * @example {
 *             "id": 176,
 *             "title": "Babe Ruth - no params",
 *             "search": "",
 *             "subSearch": "",
 *             "sort": "",
 *             "playerId": null,
 *             "teamId": null,
 *             "attributePaths": "",
 *             "owner": "",
 *             "gradeActionPath": "",
 *             "graderPath": "",
 *             "gradePath": "",
 *             "sportPath": "",
 *             "decadePath": null,
 *             "yearPath": null,
 *             "yearMin": null,
 *             "yearMax": null,
 *             "parentSetPath": "",
 *             "setPath": "",
 *             "soldOutOption": "Ignore",
 *             "listingFormat": "All",
 *             "shouldNotify": false,
 *             "lastUserSearchDate": "2023-06-15T19:01:31.527Z",
 *             "lastAutomatedSearchDate": null,
 *             "newResultCount": null
 *         }
 *         {
 *             "id": 175,
 *             "title": "Babe Ruth - every param",
 *             "search": "Babe Ruth",
 *             "subSearch": "Donruss",
 *             "sort": "b",
 *             "playerId": 3451,
 *             "teamId": 1059,
 *             "attributePaths": "HOF,SN",
 *             "owner": "jimmertime7",
 *             "gradeActionPath": "Graded",
 *             "graderPath": "PSA",
 *             "gradePath": "9",
 *             "sportPath": "Baseball",
 *             "decadePath": null,
 *             "yearPath": null,
 *             "yearMin": 2002,
 *             "yearMax": 2020,
 *             "parentSetPath": "Baseball/2002/Donruss_Diamond_Kings",
 *             "setPath": "Baseball/2002/Donruss_Diamond_Kings_-_Base_-_Gold_Foil",
 *             "soldOutOption": "Ignore",
 *             "listingFormat": "All",
 *             "shouldNotify": false,
 *             "lastUserSearchDate": "2023-06-14T17:29:06.993Z",
 *             "lastAutomatedSearchDate": null
 *         }
 */
export interface SavedSearchResponse extends Readonly<SavedSearchProps> {
  readonly id: number
  readonly lastUserSearchDate: string
  readonly lastAutomatedSearchDate: string | null
  readonly newResultCount: number | null
  /**
   * @see AvailabilityOptions
   * @todo refactor to use enums
   */
  readonly soldOutOption: string
}

/**
 * @note There is no pagination support
 * @interface SavedSearchesResponse
 * @version 1.0
 */
export interface SavedSearchesResponse {
  savedSearches: SavedSearchResponse[]
  retrieveNewResultCount: boolean
}

// TODO: Convert namespace to generic class
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SavedSearchUtils {
  type SearchParamsLike = SearchParams | SearchParamsPayload
  /**
   * Convert {@link SearchParams} to a new SavedSearch object that can be posted to the Web API
   * @param filteredSearchParams {SearchParams}
   */
  export function toSavedSearchRequest(title: string, searchParams: SearchParamsLike): SavedSearchRequest {
    const filteredSearchParams = pickBy<SearchParamsLike>(searchParams, isNotBlank)
    const request = { title } as SavedSearchRequest

    if (filteredSearchParams.searchTerm) {
      request.search = filteredSearchParams.searchTerm
    }

    if (filteredSearchParams.subSearch) {
      request.subSearch = filteredSearchParams.subSearch
    }

    if (filteredSearchParams.ownerName) {
      request.owner = filteredSearchParams.ownerName
    }

    if (filteredSearchParams.playerId) {
      request.playerId = filteredSearchParams.playerId
    }

    if (filteredSearchParams.teamId) {
      request.teamId = filteredSearchParams.teamId
    }

    if (filteredSearchParams.parentSetPath) {
      request.parentSetPath = filteredSearchParams.parentSetPath
    }

    if (filteredSearchParams.setPath) {
      request.setPath = filteredSearchParams.setPath
    }

    if (filteredSearchParams.sportPath) {
      request.sportPath = filteredSearchParams.sportPath
    }

    if (filteredSearchParams.gradeActionPath) {
      request.gradeActionPath = filteredSearchParams.gradeActionPath
    }

    if (filteredSearchParams.graderPath) {
      request.graderPath = filteredSearchParams.graderPath
    }

    if (filteredSearchParams.gradePath) {
      request.gradePath = filteredSearchParams.gradePath
    }

    if (filteredSearchParams.attributePaths) {
      request.attributePaths = filteredSearchParams.attributePaths
    }

    if (filteredSearchParams.listingFormat) {
      request.listingFormat = filteredSearchParams.listingFormat
    }

    if (filteredSearchParams.availability) {
      request.searchSoldOut = filteredSearchParams.availability
    }

    if (filteredSearchParams.sort) {
      const sort = stripToNull(sortEnumToQueryValue(filteredSearchParams.sort))
      if (sort) {
        request.sort = sort
      }
    }

    if (filteredSearchParams.decadePath) {
      request.decadePath = filteredSearchParams.decadePath
    }

    if (filteredSearchParams.yearPath) {
      request.yearPath = filteredSearchParams.yearPath
    }

    return request
  }

  export function toSearchParams(savedSearch: SavedSearchResponse): SearchParams {
    const query = {} as SearchParams

    if (isNotBlank(savedSearch.search)) {
      query.searchTerm = savedSearch.search || ''
    }
    if (isNotBlank(savedSearch.subSearch)) {
      query.subSearch = savedSearch.subSearch
    }

    if (isNotBlank(savedSearch.owner)) {
      query.ownerName = savedSearch.owner
    }

    if (savedSearch.playerId) {
      query.playerId = savedSearch.playerId
    }

    if (savedSearch.teamId) {
      query.teamId = savedSearch.teamId
    }

    if (isNotBlank(savedSearch.gradeActionPath)) {
      query.gradeActionPath = savedSearch.gradeActionPath
    }

    if (isNotBlank(savedSearch.graderPath)) {
      query.graderPath = savedSearch.graderPath
    }

    if (isNotBlank(savedSearch.gradePath)) {
      query.gradePath = savedSearch.gradePath
    }

    if (isNotBlank(savedSearch.sportPath)) {
      query.sportPath = savedSearch.sportPath
    }

    if (isNotBlank(savedSearch.setPath)) {
      query.setPath = savedSearch.setPath
    }

    if (isNotBlank(savedSearch.parentSetPath)) {
      query.parentSetPath = savedSearch.parentSetPath
    }

    if (isNotBlank(savedSearch.decadePath)) {
      query.decadePath = savedSearch.decadePath
    }

    if (isNotBlank(savedSearch.yearPath)) {
      query.yearPath = savedSearch.yearPath
    }

    if (isNotBlank(savedSearch.attributePaths)) {
      query.attributePaths = savedSearch.attributePaths
    }

    if (isNotBlank(savedSearch.soldOutOption)) {
      query.availability = savedSearch.soldOutOption as AvailabilityOptions
    }

    if (isNotBlank(savedSearch.listingFormat)) {
      query.listingFormat = savedSearch.listingFormat as ListingFormatOptions
    }

    if (isNotBlank(savedSearch.sort)) {
      const sort = sortQueryValueToItemSort(savedSearch.sort)
      if (!isNil(sort)) {
        query.sort = sort
      }
    }

    return query
  }

  export function toQueryInput(savedSearch: SavedSearchResponse): ParsedUrlQueryInput {
    const searchParams = toSearchParams(savedSearch)
    return { ...pickBy(searchParams, isNotBlank) } as ParsedUrlQueryInput
  }

  /**
   * Convert {SavedSearch} to a {@link SearchParams}-style query string.
   * @param savedSearch {SavedSearchResponse}
   */
  export function toQueryString(savedSearch: SavedSearchResponse): string {
    const query = SavedSearchUtils.toQueryInput(savedSearch)

    return stringify(query)
  }

  export function toSearchUrl(savedSearch?: SavedSearchResponse): string {
    return !savedSearch ? '/search' : `/search?${toQueryString(savedSearch)}`
  }

  /**
   * Compare a SavedSearchRequest with a SavedSearchResponse
   * Note: Excludes {@link SavedSearchProps.sort} and other properties that are specific to {@link SavedSearchResponse}
   */
  export function equals(a: SavedSearchRequest, b?: SavedSearchResponse) {
    return (
      !isNil(a) &&
      !isNil(b) &&
      isEqual(stripToNull(a.attributePaths), stripToNull(b.attributePaths)) &&
      isEqual(stripToNull(a.gradeActionPath), stripToNull(b.gradeActionPath)) &&
      isEqual(stripToNull(a.gradePath), stripToNull(b.gradePath)) &&
      isEqual(stripToNull(a.graderPath), stripToNull(b.graderPath)) &&
      isEqual(stripToNull(a.listingFormat), stripToNull(b.listingFormat)) &&
      isEqual(stripToNull(a.owner), stripToNull(b.owner)) &&
      isEqual(stripToNull(a.parentSetPath), stripToNull(b.parentSetPath)) &&
      isEqual(stripToNull(a.search), stripToNull(b.search)) &&
      isEqual(stripToNull(a.setPath), stripToNull(b.setPath)) &&
      isEqual(stripToNull(a.subSearch), stripToNull(b.subSearch)) &&
      isEqual(stripToNull(a.sportPath), stripToNull(b.sportPath)) &&
      isEqual(stripToNull(a.searchSoldOut), stripToNull(b.soldOutOption)) &&
      isEqual(toSafeInteger(a.playerId), toSafeInteger(b.playerId)) &&
      isEqual(toSafeInteger(a.teamId), toSafeInteger(b.teamId)) &&
      isEqual(toSafeInteger(a.yearMax), toSafeInteger(b.yearMax)) &&
      isEqual(toSafeInteger(a.yearMin), toSafeInteger(b.yearMin)) &&
      isEqual(toSafeInteger(a.yearPath), toSafeInteger(b.yearPath)) &&
      isEqual(toSafeInteger(a.decadePath), toSafeInteger(b.decadePath))
    )
  }
  export function includes(
    resourceOrId?: SavedSearchRequest | SavedSearchResponse | number,
    list?: SavedSearchResponse[]
  ) {
    const found = SavedSearchUtils.find(resourceOrId, list)

    return !isNil(found)
  }

  export function isSavedSearchResponse(
    resource: SavedSearchResponse | SavedSearchRequest | number
  ): resource is SavedSearchResponse {
    return !isNil(get(resource, 'id'))
  }

  export function getIdOrDefault(resourceOrId?: SavedSearchResponse | SavedSearchRequest | number, fallback?: number) {
    let id = fallback
    if (!isNil(resourceOrId)) {
      if (isInteger(resourceOrId)) {
        id = resourceOrId as number
      } else if (has(resourceOrId, 'id')) {
        id = get(resourceOrId, 'id', fallback)
      }
    }
    return id
  }

  export function find(resourceOrId?: SavedSearchRequest | SavedSearchResponse | number, list?: SavedSearchResponse[]) {
    let id: number | undefined
    let found
    if (!isNil(resourceOrId) && isNotBlank(list)) {
      id = SavedSearchUtils.getIdOrDefault(resourceOrId)
      if (id) {
        found = list?.find((resp) => resp.id === id)
      } else {
        found = list?.find((resp) => SavedSearchUtils.equals(resourceOrId as SavedSearchRequest, resp))
      }
    }
    return found
  }
}

export const SAVED_SEARCH_TITLE_MAX_CHARS = 610

export const DEFAULT_SAVED_SEARCH_RESPONSE: SavedSearchResponse = {
  id: -999,
  title: 'Default',
  search: '',
  subSearch: '',
  sort: '',
  playerId: null,
  teamId: null,
  attributePaths: '',
  owner: '',
  gradeActionPath: '',
  graderPath: '',
  gradePath: '',
  sportPath: '',
  decadePath: null,
  yearPath: null,
  yearMin: null,
  yearMax: null,
  parentSetPath: '',
  setPath: '',
  soldOutOption: 'Ignore',
  listingFormat: 'All',
  shouldNotify: false,
  lastUserSearchDate: '2023-06-15T19:01:31.527Z',
  lastAutomatedSearchDate: null,
  newResultCount: null,
}
