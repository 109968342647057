import type { PaginatedApiRequest } from '@/interfaces/api/pagination'

export const OFFERS_PER_PAGE = 100

export const DEFAULT_OFFERS_PARAMS: PaginatedApiRequest = {
  pageSize: OFFERS_PER_PAGE,
  pageNumber: 1,
}

export const OFFER_ITEMS_PER_PAGE = 10

export const DEFAULT_OFFER_ITEMS_PARAMS: PaginatedApiRequest = {
  pageSize: OFFER_ITEMS_PER_PAGE,
  pageNumber: 1,
}

// When backend updates API it may be possibly to use this enum in the OfferHistoryItem model
export enum OFFER_STATUS {
  CLOSED = 'All',
  ACCEPTED = 'Accepted',
  COUNTERED = 'Countered',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
  EXPIRED = 'Expired',
  OPEN = 'Open',
}

export const CLOSED_OFFER_STATUSES = [
  OFFER_STATUS.CLOSED,
  OFFER_STATUS.ACCEPTED,
  OFFER_STATUS.COUNTERED,
  OFFER_STATUS.REJECTED,
  OFFER_STATUS.EXPIRED,
]

export const EXPIRED_OFFER_STATUSES = [OFFER_STATUS.EXPIRED, OFFER_STATUS.CANCELLED]

export enum DETAILED_OFFER_STATUS_MESSAGE {
  ACCEPTED = 'Offer Accepted',
  COUNTER_REJECTED = 'Counter Offer Rejected',
  REJECTED = 'Offer Rejected',
  EXPIRED = 'Offer Expired',
  NEW_INCOMING = 'Incoming Offer',
  NEW_SENT = 'Offer Sent',
  COUNTERED_INCOMING = 'Counter Offer',
  COUNTERED_SENT = 'Counter Offer Sent',
}

export enum OFFER_TYPE {
  PURCHASE = 'Purchase',
  SALE = 'Sale',
}
