import type { ProductDetailsResults } from '@/interfaces/item/productDetailsResult'
import type { ProductSearchParams } from '@/interfaces/item/productRequest'

import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

import { productDetailsURL } from '@/constants/apiURLs'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { directAPIRequest } from '@/helpers'
import { NextAuthStatuses } from '@/interfaces/auth/nextAuth'

const useProductDetails = (productSearchParams?: ProductSearchParams) => {
  const { data: session, status } = useSession()

  // Comment: Not sure what props should be pulled out by default
  const {
    data: productDetails,
    isLoading: isProductDetailsLoading,
    isError: isProductDetailsError,
    refetch: refetchProductDetails,
    ...props
  } = useQuery<unknown, unknown, ProductDetailsResults>({
    queryKey: ['productDetails', { productSearchParams }],
    queryFn: () =>
      directAPIRequest([RequestMethods.GET], productDetailsURL(productSearchParams!), false, {
        method: RequestMethods.GET,
        session,
      }),
    enabled: !!productSearchParams && status !== NextAuthStatuses.LOADING,
    initialData: undefined,
    placeholderData: (prev: unknown) => prev,
  })

  return { productDetails, isProductDetailsLoading, isProductDetailsError, refetchProductDetails, ...props }
}

export default useProductDetails
