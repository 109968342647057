import type { FC } from 'react'

import { Box, CircularProgress } from '@mui/material'

const PageLoader: FC = () => {
  return (
    <Box display="flex" height="100%" width="100%" position="absolute" justifyContent="center">
      <CircularProgress size={80} sx={{ top: '25%', position: 'relative' }} />
    </Box>
  )
}

export default PageLoader
