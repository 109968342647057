import type { CartSummary } from '@/interfaces/cart/cartSummary'

export const DEFAULT_CART_SUMMARY: CartSummary = {
  cartId: '',
  universalCart: true,
  countOfItems: 0,
  topLoaders: 0,
  averageItemId: 0,
  sumOfListPrice: 0.0,
  sumOfVisibleItemShipping: 0.0,
  sumOfVisiblePrice: 0.0,
  sumOfPrice: 0.0,
  sumOfVisiblePriceWithoutTOP: 0.0,
}

export const TOPLOADER_PRICE = 0.35

export const CART_ITEMS_PER_PAGE = 10
