import { type PropsWithChildren, useEffect } from 'react'
import { signIn, useSession } from 'next-auth/react'
import { Box } from '@mui/material'

import PageLoader from './PageLoader'
import { NextAuthStatuses } from '@/interfaces/auth/nextAuth'

const AuthGuard = ({ children }: PropsWithChildren) => {
  const { status } = useSession()

  useEffect(() => {
    if (status === NextAuthStatuses.UNAUTHENTICATED) {
      signIn('azure-ad-b2c', {}, { prompt: 'login' }).then(() => Promise.resolve())
    }
  }, [status])

  if (status === NextAuthStatuses.LOADING || status === NextAuthStatuses.UNAUTHENTICATED) {
    return (
      <Box position="relative" width="100%" minHeight="100vh">
        <PageLoader />
      </Box>
    )
  }

  return <>{children}</>
}

export default AuthGuard
