import type { ItemDetailsModalState } from '@/interfaces/inventory/itemDetailsModalInformation'
import type { ObjWItemId, UserInventoryItem } from '@/interfaces/inventory/inventoryItemResults'
import type { ProductDetailsResults } from '@/interfaces/item/productDetailsResult'
import type { UserSale } from '@/interfaces/user/transaction'

import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useMutation } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

import useToggleState from '@/hooks/useToggleState'
import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { getProductDetails } from '@/pages/api/productDetails'

export type OpenItemDetailsModalArgs = {
  itemId: number
  isOwned?: boolean
  productDetails?: ProductDetailsResults
  userSale?: UserSale
}

type ItemDetailsModalContext = {
  itemDetailsModalOpenState: boolean
  toggleItemDetailsModalOpenState: () => void
  openItemDetailsModal: (item: UserInventoryItem) => void
  // TODO: Update this method to take in more generic "Basic Product Details" instead of UserSale
  openItemDetailsModalFromItemId: (args: OpenItemDetailsModalArgs) => void
  setModalState: Dispatch<SetStateAction<ItemDetailsModalState | undefined>>
  modalState?: ItemDetailsModalState
  refreshModalData: (isOwned: boolean) => void
}

const ItemDetailsModalContext = createContext<ItemDetailsModalContext>({
  itemDetailsModalOpenState: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleItemDetailsModalOpenState: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openItemDetailsModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openItemDetailsModalFromItemId: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModalState: () => {},
  refreshModalData: () => {},
})

// TODO: This entire provider should be reworked to use a single API request, backend work required
export default function ItemDetailsModalProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
  const [modalState, setModalState] = useState<ItemDetailsModalState>()
  const { toggleState: itemDetailsModalOpenState, toggleStateHandler: toggleItemDetailsModalOpenState } =
    useToggleState()
  const { data: session } = useSession()

  const getInventoryItemDetails = useCallback(
    async (itemId: number) => {
      return clientAPIRequest<UserInventoryItem, ObjWItemId>(
        '/api/inventory/item',
        RequestMethods.GET,
        {
          itemId,
        },
        session
      )
    },
    [session]
  )

  const { mutate: fetchInventoryItemDetails } = useMutation({
    mutationFn: (payload: ObjWItemId) => getInventoryItemDetails(payload.itemId),
    onSuccess(data) {
      setModalState({
        selectedItem: data,
        selectedItemId: data.itemId,
        isOwnedByCurrentUser: true,
      })
    },
  })

  const getItemDetails = useCallback(
    (args: OpenItemDetailsModalArgs) => {
      const { itemId, isOwned, productDetails, userSale } = args
      if (!productDetails) {
        // TODO: Need error state
        return
      }
      if (
        isOwned ||
        (productDetails.inventoryProducts &&
          productDetails.inventoryProducts.length > 0 &&
          productDetails.inventoryProducts[0].owned)
      ) {
        fetchInventoryItemDetails({ itemId })
        // Need Error state here
      } else {
        const itemForSale = productDetails.inventoryProducts.find((x) => x.itemId === itemId)
        if (!itemForSale && !userSale) {
          // TODO: Need error state
          return
        }
        setModalState({
          isOwnedByCurrentUser: false,
          selectedItemId: itemId,
          selectedItem: {
            ...userSale,
            ...productDetails,
            ...itemForSale,
            price: itemForSale?.price || 0,
            discountedPrice: itemForSale?.discountedPrice || 0,
            isOnSale: itemForSale?.isOnSale || false,
            owner: itemForSale?.owner || '',
            imageId: (itemForSale?.imageId || userSale?.imageId)!,
            itemId,
          },
        })
      }
    },
    [fetchInventoryItemDetails]
  )

  const openItemDetailsModalFromItemId = useCallback(
    async (args: OpenItemDetailsModalArgs) => {
      const { itemId, isOwned, productDetails, userSale } = args
      setModalState({ selectedItemId: itemId, selectedItem: undefined, isOwnedByCurrentUser: undefined })
      toggleItemDetailsModalOpenState()
      let productDetailsByItemId = productDetails
      if (!productDetailsByItemId) {
        productDetailsByItemId = await getProductDetails({ itemId, accessToken: session?.accessToken })
      }
      getItemDetails({
        itemId,
        isOwned,
        productDetails: productDetailsByItemId,
        userSale,
      })
    },
    [toggleItemDetailsModalOpenState, getItemDetails, session]
  )

  const openItemDetailsModal = useCallback(
    (item: UserInventoryItem) => {
      setModalState({ selectedItem: item, selectedItemId: item.itemId, isOwnedByCurrentUser: true })
      toggleItemDetailsModalOpenState()
    },
    [toggleItemDetailsModalOpenState]
  )

  useEffect(() => {
    if (!itemDetailsModalOpenState) {
      setModalState(undefined)
    }
  }, [itemDetailsModalOpenState])

  const refreshModalData = useCallback(
    async (isOwned: boolean) => {
      if (!modalState?.selectedItemId) {
        return
      }
      const itemId = modalState.selectedItemId
      const productDetailsByItemId = await getProductDetails({ itemId, accessToken: session?.accessToken })
      getItemDetails({ itemId, isOwned, productDetails: productDetailsByItemId })
    },
    [modalState?.selectedItemId, getItemDetails, session]
  )

  const itemDetailsModalValue = useMemo(
    () => ({
      modalState,
      setModalState,
      itemDetailsModalOpenState,
      toggleItemDetailsModalOpenState,
      openItemDetailsModal,
      openItemDetailsModalFromItemId,
      refreshModalData,
    }),
    [
      itemDetailsModalOpenState,
      openItemDetailsModal,
      modalState,
      toggleItemDetailsModalOpenState,
      openItemDetailsModalFromItemId,
      refreshModalData,
    ]
  )

  return <ItemDetailsModalContext.Provider value={itemDetailsModalValue}>{children}</ItemDetailsModalContext.Provider>
}

export const useItemDetailsModalProvider = (): ItemDetailsModalContext => useContext(ItemDetailsModalContext)
