import type { PaginatedApiRequest, PaginatedApiResponse, PaginatedItem } from '@/interfaces/api/pagination'
import type { ObjWItemId } from './inventoryItemResults'

import { RequestMethods } from '@/interfaces/api/requestMethods'

export const MATCHING_INVENTORY_ITEMS_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const OWNED_MATCHING_INVENTORY_ITEMS_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const GRADING_SUBMISSIONS_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const GRADING_HISTORY_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const PROCESSING_SERVICES_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const GRADING_SERVICES_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const AUCTION_SERVICES_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const SUBMIT_FOR_PROCESSING_ACCEPTED_REQUEST_METHODS = [RequestMethods.POST]
export const SUBMIT_FOR_GRADING_ACCEPTED_REQUEST_METHODS = [RequestMethods.POST]
export const SUBMIT_FOR_AUCTION_ACCEPTED_REQUEST_METHODS = [RequestMethods.POST]
export const USER_INVENTORY_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const SET_LIST_PRICE_ACCEPTED_REQUEST_METHODS = [RequestMethods.POST]
export const SET_PRICE_ACCEPTED_REQUEST_METHODS = [RequestMethods.POST]
export const SET_FOR_SALE_STATE_ACCEPTED_REQUEST_METHODS = [RequestMethods.PUT]
export const INVENTORY_ITEM_DETAILS_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const ITEM_MANAGEMENT_DETAILS_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const ITEM_UPGRADE_ACCEPTED_REQUEST_METHODS = [RequestMethods.POST]

export enum INVENTORY_PRICE_SETTING {
  ALL = 'All',
  WITHOUT = 'Without',
  WITHOUT_NOT_SHIP = 'WithoutNotShip',
  WITH = 'With',
  ATTENTION = 'Attention',
  FOR_SALE = 'ForSale',
  NOT_FOR_SALE = 'NotForSale',
  IN_SHIPMENT = 'InShipment',
  PURCHASED = 'Purchased',
  NOT_PURCHASED = 'NotPurchased',
  NOT_EXEMPT = 'NotExempt',
  ENHANCED_SECURITY = 'EnhancedSecurity',
  // TOO_HIGH = 'TooHigh', This is commented out for now, though it is a possible option
}

export const INVENTORY_PRICE_SETTING_FILTER_TEXT = {
  [INVENTORY_PRICE_SETTING.ALL]: 'All items',
  [INVENTORY_PRICE_SETTING.WITH]: 'WITH price',
  [INVENTORY_PRICE_SETTING.WITHOUT_NOT_SHIP]: 'WITHOUT price not flagged to ship',
  [INVENTORY_PRICE_SETTING.WITHOUT]: 'WITHOUT price',
  [INVENTORY_PRICE_SETTING.ATTENTION]: 'Needs attention',
  [INVENTORY_PRICE_SETTING.IN_SHIPMENT]: 'Items to be shipped',
  [INVENTORY_PRICE_SETTING.NOT_PURCHASED]: 'I am the original owner',
  [INVENTORY_PRICE_SETTING.ENHANCED_SECURITY]: 'Subject to enhance security fees',
  [INVENTORY_PRICE_SETTING.NOT_EXEMPT]: 'Subject to storage fees',
}

export enum INVENTORY_SORT {
  HIGHEST_SUGGESTED_PRICE = 'HighestSuggestedPrice',
  LOWEST_SUGGESTED_PRICE = 'LowestSuggestedPrice',
  HIGHEST_ASKING_PRICE = 'HighestAskingPrice',
  LOWEST_ASKING_PRICE = 'LowestAskingPrice',
  HIGHEST_ACQUISITON_PRICE = 'HighestAcquisitionPrice',
  LOWEST_ACQUISITON_PRICE = 'LowestAcquisitionPrice',
  ACQUISITION_DATE = 'AcquisitionDate',
  ACQUISITION_DATE_OLD = 'AcquisitionDateOld',
  PRICE_MODIFIED = 'PriceModified',
  PRICE_MODIFIED_OLD = 'PriceModifiedOld',
  PRODUCT_MODIFIED = 'ProductModified',
  LIST_PRICE = 'ListPrice',
  OLD_PRODUCTS = 'OldProducts',
  NEW_PRODUCTS = 'NewProducts',
  SPORT = 'Sport',
  PLAYER_SUBJECT = 'PlayerSubject',
  LEAST_FOR_SALE = 'LeastForSale',
  LEAST_FOR_SALE_BY_ME = 'LeastForSaleByMe',
  LOWEST_PRINT_RUN = 'LowestPrintRun',
}

export interface UserInventoryApiRequest {
  search?: string
  priceSetting?: INVENTORY_PRICE_SETTING
  sort?: INVENTORY_SORT
  startRowIndex?: number
  maximumRows?: number
}

export interface MatchingInventoryItemsRequest extends ObjWItemId, PaginatedApiRequest {}

export interface InventoryItemMatch extends ObjWItemId, PaginatedItem {
  productId: number
  gradeId: number
  conditionNotes?: string
  owner?: string
  askingPrice: number
  itemShipping: number
  price: number
  listPrice: number
  discountedListPrice: number
  discountedPrice: number
  auctionPromotionId?: number
}

export interface InventoryItemOwnerMatch extends ObjWItemId, PaginatedItem {
  productId: number
  gradeId: number
  conditionNotes?: string
  ownerId: number
  askingPrice: number
  itemShipping: number
  price: number
  listPrice: number
  acquisitionPrice?: number
  acquisitionDate?: string
}

interface ItemPriceStatistics {
  minPrice?: number
  maxPrice?: number
  averagePrice?: number
  medianPrice?: number
}

export interface MatchingInventoryItemsResponse extends PaginatedApiResponse<InventoryItemMatch>, ItemPriceStatistics {}

export interface MatchingInventoryItemsByOwnerResponse
  extends PaginatedApiResponse<InventoryItemOwnerMatch>,
    ItemPriceStatistics {
  totalItemsForSale: number
}
