import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { userQueryKeys } from '@/interfaces/user/userQueries'
import { userAgreementURL } from '@/constants/middlewareApiURLs'

const useUserAgreement = () => {
  const getUserAgreement = useCallback(
    async () => clientAPIRequest<string, unknown>(userAgreementURL, RequestMethods.GET),
    []
  )

  const { data: userAgreement, isLoading: userAgreementLoading } = useQuery({
    queryKey: [userQueryKeys.GetUserAgreement],
    queryFn: () => getUserAgreement(),
  })

  return { userAgreement, userAgreementLoading }
}

export default useUserAgreement
