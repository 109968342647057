import { MAX_RESULTS_NUMBER } from '@/constants'

export const getTotalPages = (totalRows: number, hitsPerPage: number): number => {
  const totalPages = Math.ceil(totalRows / hitsPerPage)
  return totalPages * hitsPerPage > MAX_RESULTS_NUMBER ? Math.ceil(MAX_RESULTS_NUMBER / hitsPerPage) : totalPages
}

export const getStartIndex = (page: string | number, maxRows: number, isZeroIndexed = true): number => {
  const maxRowsOffset = isZeroIndexed ? 0 : 1
  return +page * maxRows - (maxRows - maxRowsOffset)
}
