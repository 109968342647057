import type { Nullable, Optional } from '@/types/utility'
import type { UrlInit } from '@/types/url'

import nodeurl from 'url'

import 'core-js/actual/url/can-parse'
import URI from 'urijs'
import { isNil } from 'lodash'

import { isBlank } from './utils'

export const reduceQueryParam = (queryValue: string | string[]) => {
  return Array.isArray(queryValue) ? queryValue[0] : queryValue
}

export function canonicalizePath(init?: string | URL | null | URI) {
  const safeInit = isBlank(init) ? '' : init
  const uri = new URI(safeInit, '/')
  return uri.pathname()
}

export function isExternalLink<Init extends UrlInit, Base extends UrlInit>(init?: Init | null, base?: Base): boolean {
  const link = toURI(init, base)
  return URL.canParse(link.href()) && link.is('absolute')
}

export function stringifyUrl<
  Init extends UrlInit,
  F extends Optional<string> = undefined,
  R = [F] extends [string] ? string : Optional<string>,
>(init?: Init | null, fallback: Optional<F> = undefined): R {
  let composed: Optional<string>
  if (!isNil(init)) {
    if (typeof init === 'string') {
      composed = init.trim()
    } else if (init instanceof URI) {
      composed = init.href()
    } else if (init instanceof URL) {
      composed = init.href
    } else {
      composed = nodeurl.format(init)
    }
  }
  return (composed || fallback) as R
}

export function toURI<Init extends UrlInit, Base extends UrlInit>(init?: Init | null, base?: Base): URI {
  const safeInit = init || base || ''
  let _init: URI = new URI('')
  let composed: Optional<URI>

  if (safeInit instanceof URI) {
    _init = new URI(safeInit)
  } else {
    const temp = stringifyUrl(safeInit, '')
    _init = new URI(temp)
  }

  if (base && _init.is('url') && (_init.is('relative') || !_init.protocol().length)) {
    const tempBase = stringifyUrl(base)
    if (!isBlank(tempBase)) {
      composed = new URI(_init, tempBase)
    }
  }

  composed ??= _init

  return composed || _init
}

export class SafeUrlSearchParams extends URLSearchParams {
  getDefault<T>(name: string, fallback: T) {
    const value = super.get(name)
    if (value) {
      return value
    } else {
      return fallback
    }
  }

  getAllDefault<T>(name: string, fallback: T) {
    const values = super.getAll(name)
    if (values) {
      return values
    } else {
      return fallback
    }
  }
}

// URLSearchParams but removes null and undefined values automatically
// returns the search params as a string to put in a URL
export function urlSearchParamsFromObj(params: Record<string, Nullable<string | number>>): string {
  const searchParams = new URLSearchParams()

  for (const [property, value] of Object.entries(params)) {
    if (value !== null && value !== undefined) {
      searchParams.append(property, `${value}`)
    }
  }

  return searchParams.toString()
}
