import { type FC, useEffect, useRef } from 'react'
import { Box, IconButton, Badge, Stack } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'

import { useCartProvider } from '@/store/cartProvider'
import AuthNavItem from './auth/AuthNavItem'
import { useAnimation } from '@/hooks/useAnimation'

const NavigationItems: FC = () => {
  const { successfulAddToCart, setSuccessfulAddToCart, cartToUse } = useCartProvider()
  const shoppingCartIcon = useRef<HTMLAnchorElement>()
  const { animateCSS } = useAnimation<HTMLAnchorElement>()

  useEffect(() => {
    if (successfulAddToCart) {
      setSuccessfulAddToCart(undefined)
      if (shoppingCartIcon.current) {
        animateCSS(shoppingCartIcon.current, 'headShake')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfulAddToCart, setSuccessfulAddToCart])

  return (
    <Stack direction="row" alignItems="center">
      <Box my={1}>
        <AuthNavItem />
      </Box>
      <Box ml={1} data-test-id="nav-cart-count">
        <IconButton
          size="large"
          aria-label="view cart"
          color="inherit"
          href="/cart"
          ref={(e) => {
            if (e) {
              shoppingCartIcon.current = e
            }
          }}
        >
          <Badge badgeContent={cartToUse.countOfItems} color="primary" max={99}>
            <ShoppingCart fontSize="large" />
          </Badge>
        </IconButton>
      </Box>
    </Stack>
  )
}

export default NavigationItems
