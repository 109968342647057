const useScrollToTop = (scrollBehavior: 'smooth' | 'instant' | 'auto' = 'smooth') => {
  const scrollToTopHandler = (event?: React.MouseEvent<HTMLDivElement>) => {
    const anchorParent = event ? (event.target as HTMLDivElement).ownerDocument : document
    const anchor = anchorParent.querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: scrollBehavior,
      })
    }
  }

  return {
    scrollToTopHandler,
  }
}

export default useScrollToTop
