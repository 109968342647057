import type { NavPageLink } from '@/interfaces/navigation/navPageLink'

import { LocalOffer } from '@mui/icons-material'
import GavelIcon from '@mui/icons-material/Gavel'

import {
  CHARITY_ACCOUNTS,
  EXPLORE_BRAND_SETS,
  EXPLORE_PLAYERS,
  EXPLORE_TEAMS,
  EXPLORE_TOP_SELLERS,
} from '@/constants/vintageLinks'
import { promotionsHref } from '@/constants'
import { PROMOTION_TYPE } from '@/interfaces/promotions/promotion'

export const LOWER_APP_BAR_PAGES: NavPageLink[] = [
  {
    text: 'On Sale',
    icon: LocalOffer,
    href: promotionsHref({ type: PROMOTION_TYPE.SALE }),
    jumpout: true,
    color: 'brandGreen.main',
  },
  {
    text: 'Auctions',
    icon: GavelIcon,
    href: '/search?searchTerm=&pageNum=1&hitsPerPage=100&availability=AVAILABLE&listingFormat=AUCTIONS&sort=Ending+Soonest',
    color: 'brandGreen.main',
  },
  {
    text: 'Players',
    href: EXPLORE_PLAYERS.toString(),
    jumpout: true,
  },
  {
    text: 'Teams',
    href: EXPLORE_TEAMS.toString(),
    jumpout: true,
  },
  {
    text: 'Brands & Sets',
    href: EXPLORE_BRAND_SETS.toString(),
    jumpout: true,
  },
  {
    text: 'Top Sellers & Buyers',
    href: EXPLORE_TOP_SELLERS.toString(),
    jumpout: true,
  },
  {
    text: 'Charity Accounts',
    href: CHARITY_ACCOUNTS.toString(),
    jumpout: true,
  },
  {
    text: 'Port Sales',
    href: promotionsHref({ type: PROMOTION_TYPE.PORT_SALE }),
    jumpout: true,
  },
]
