import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk'

import flagOverrides from '@/localFlags'

// a way to override flags for development until launchdarkly supports this https://github.com/launchdarkly/react-client-sdk/issues/53
// view flagOverrides.ts in the root directory for more information
const useFlags = () => {
  const ldFlags = useLDFlags()

  const mergedFlags = { ...ldFlags, ...flagOverrides }
  return mergedFlags
}

export default useFlags
