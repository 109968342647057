import type { ImageDialogItem } from '@/interfaces/item/ItemImageDialog'

import { createContext, type PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'

import useToggleState from '@/hooks/useToggleState'
import { useProductDataProvider } from './productDataProvider'

type ItemImageDialogContext = {
  itemImageDialogOpenState: boolean
  toggleItemImageDialogOpenState: () => void
  openItemImageDialog: (item: ImageDialogItem, showBack?: boolean) => void
  showBack: boolean
  toggleShowBack: () => void
  selectedItem?: ImageDialogItem
}

const ItemImageDialogContext = createContext<ItemImageDialogContext>({
  itemImageDialogOpenState: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleItemImageDialogOpenState: () => {},
  showBack: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleShowBack: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openItemImageDialog: () => {},
})

export default function ItemImageDialogProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
  const { productDetails } = useProductDataProvider()
  const [selectedItem, setSelectedItem] = useState<ImageDialogItem | undefined>(undefined)

  const {
    toggleState: showBack,
    toggleStateHandler: toggleShowBack,
    setToggleState: setShowBack,
  } = useToggleState(false)
  const { toggleState: itemImageDialogOpenState, toggleStateHandler: toggleItemImageDialogOpenState } = useToggleState()

  const getItemDescription = useCallback(
    (item: ImageDialogItem) => {
      let itemDescription: string | undefined = ''
      if ('description' in item) {
        itemDescription = item.description
      } else {
        itemDescription = productDetails?.description || ''
      }
      return itemDescription
    },
    [productDetails]
  )

  const openItemImageDialog = useCallback(
    (item: ImageDialogItem, showBack = false) => {
      setShowBack(showBack)
      setSelectedItem({ ...item, description: getItemDescription(item) })
      toggleItemImageDialogOpenState()
    },
    [getItemDescription, setShowBack, toggleItemImageDialogOpenState]
  )

  const itemImageDialogValue = useMemo(
    () => ({
      selectedItem,
      setSelectedItem,
      showBack,
      toggleShowBack,
      itemImageDialogOpenState,
      toggleItemImageDialogOpenState,
      openItemImageDialog,
    }),
    [
      itemImageDialogOpenState,
      openItemImageDialog,
      selectedItem,
      showBack,
      toggleItemImageDialogOpenState,
      toggleShowBack,
    ]
  )

  return <ItemImageDialogContext.Provider value={itemImageDialogValue}>{children}</ItemImageDialogContext.Provider>
}

export const useImageItemDialogProvider = (): ItemImageDialogContext => useContext(ItemImageDialogContext)
