import type { ObjWItemId } from '@/interfaces/inventory/inventoryItemResults'
import type { CreateOrderRequestBody } from '@paypal/paypal-js'
import type { CartSummaries, CartSummary } from '@/interfaces/cart/cartSummary'
import type {
  DeliveryMethod,
  DeliveryMethodAPIResponse,
  DeliveryMethodRequest,
} from '@/interfaces/cart/cartDeliveryMethod'
import type { CartCheckoutRequest } from '@/interfaces/cart/cartCheckout'
import type { CartItem, GetCartItemsRequest, GetCartItemsResponse, TemporaryCartItem } from '@/interfaces/cart/cartItem'
import type { CartInstantPurchaseResult } from '@/interfaces/cart/cartInstantPurchase'
import type { ContactInformation } from '@/interfaces/user/contactInformation'

import { useRouter } from 'next/router'
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { v4 } from 'uuid'

import { cartQueryKeys } from '@/interfaces/cart/cartQueries'
import { CART_ITEMS_PER_PAGE, TOPLOADER_PRICE } from '@/constants'
import { useUserProvider } from './userProvider'
import { useProductDataProvider } from './productDataProvider'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { CartPaymentMethods } from '@/interfaces/cart/cartPaymentMethods'
import { clientAPIRequest } from '@/helpers'
import { NextAuthStatuses } from '@/interfaces/auth/nextAuth'
import useFlags from '@/helpers/useFlags'
import useCartSummary from '@/hooks/data/useCartSummary'
import useUserActivationState from '@/hooks/useUserActivationState'

type CartContext = {
  cartItems: CartItem[] | []
  isCartItemsLoading: boolean
  isCartItemsError: boolean
  paginatedCartItems: CartItem[] | []
  cartPage: number
  setCartPage: Dispatch<SetStateAction<number>>
  selectedPaymentMethod: CartPaymentMethods | undefined
  setSelectedPaymentMethod: Dispatch<SetStateAction<CartPaymentMethods | undefined>>
  selectedDeliveryMethodName: string | undefined
  setSelectedDeliveryMethodName: Dispatch<SetStateAction<string>>
  shippingAddress?: ContactInformation
  setShippingAddress: Dispatch<SetStateAction<ContactInformation | undefined>>
  unvalidatedShippingAddress?: ContactInformation
  setUnvalidatedShippingAddress: Dispatch<SetStateAction<ContactInformation | undefined>>
  cartToUse: CartSummary
  showMergeCartDialog: boolean
  setShowMergeCartDialog: Dispatch<SetStateAction<boolean>>
  cartSummaries: CartSummaries
  isCartSummaryLoading: boolean
  isCartSummaryError: boolean
  fetchCartSummaries: () => void
  isCartDeliveryMethodsLoading: boolean
  isCartDeliveryMethodsError: boolean
  cartDeliveryMethods: DeliveryMethodAPIResponse | undefined
  fetchCartDeliveryMethods: (options: { cartId: string }) => void
  shippingAmount: number
  setShippingAmount: Dispatch<SetStateAction<number>>
  taxesAmount: number
  setTaxesAmount: Dispatch<SetStateAction<number>>
  setShippingAndTaxes: (deliveryMethod: DeliveryMethod | undefined) => void
  cartTotal: number
  addOrRemoveCartItemByItemIdMutation: (payload: {
    itemId: number
    method: RequestMethods.PUT | RequestMethods.DELETE
    isLastItem: boolean
    cartId?: string
  }) => void
  isAddCartItemByItemIdLoading: boolean
  successfulAddToCart?: boolean
  setSuccessfulAddToCart: Dispatch<SetStateAction<boolean | undefined>>
  currentCartItemLoading?: number
  setCurrentCartItemLoading: Dispatch<SetStateAction<number | undefined>>
  addOrRemoveToploaderByItemIdMutation: (payload: {
    itemId: number
    method: RequestMethods.PUT | RequestMethods.DELETE
    isLastItem: boolean
    cartId?: string
  }) => void
  isAddOrRemoveToploaderByItemIdMutationLoading: boolean
  isGiftReceipt: boolean
  setIsGiftReceipt: Dispatch<SetStateAction<boolean>>
  giftMessage?: string
  setGiftMessage: Dispatch<SetStateAction<string | undefined>>
  isReceiveNewsAndPromotions: boolean
  setIsReceiveNewsAndPromotions: Dispatch<SetStateAction<boolean>>
  checkoutOrderParams: CartCheckoutRequest | undefined
  setCheckoutOrderParams: Dispatch<SetStateAction<CartCheckoutRequest | undefined>>
  isPaypalOrderLoading: boolean
  isPaypalOrderError: boolean
  paypalOrder?: CreateOrderRequestBody
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchPaypalOrder: (options: { cartId: string }) => void
  cartInstantPurchaseMutation: (payload: { cartId: string; accessToken: string }) => void
  isCartInstantPurchaseMutationLoading: boolean
  cartInstantPurchaseResult?: CartInstantPurchaseResult
  attemptedToMergeCarts: boolean
}

const cartContext = createContext<CartContext>({
  cartItems: [],
  paginatedCartItems: [],
  isCartItemsLoading: false,
  isCartItemsError: false,
  cartPage: 1,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCartPage: () => {},
  selectedPaymentMethod: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedPaymentMethod: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShippingAddress: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUnvalidatedShippingAddress: () => {},
  selectedDeliveryMethodName: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedDeliveryMethodName: () => {},
  cartSummaries: {} as CartSummaries,
  cartToUse: {} as CartSummary,
  showMergeCartDialog: false,
  setShowMergeCartDialog: () => {},
  isCartSummaryLoading: false,
  isCartSummaryError: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchCartSummaries: () => {},
  isCartDeliveryMethodsLoading: false,
  isCartDeliveryMethodsError: false,
  cartDeliveryMethods: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchCartDeliveryMethods: () => {},
  shippingAmount: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShippingAmount: () => {},
  taxesAmount: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTaxesAmount: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShippingAndTaxes: () => {},
  cartTotal: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addOrRemoveCartItemByItemIdMutation: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSuccessfulAddToCart: () => {},
  isAddCartItemByItemIdLoading: false,
  currentCartItemLoading: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentCartItemLoading: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addOrRemoveToploaderByItemIdMutation: () => {},
  isAddOrRemoveToploaderByItemIdMutationLoading: false,
  isGiftReceipt: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsGiftReceipt: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setGiftMessage: () => {},
  isReceiveNewsAndPromotions: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsReceiveNewsAndPromotions: () => {},
  checkoutOrderParams: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCheckoutOrderParams: () => {},
  isPaypalOrderLoading: false,
  isPaypalOrderError: false,
  paypalOrder: {} as CreateOrderRequestBody,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchPaypalOrder: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cartInstantPurchaseMutation: () => {},
  cartInstantPurchaseResult: undefined,
  isCartInstantPurchaseMutationLoading: false,
  attemptedToMergeCarts: true,
})

const queryClient = new QueryClient()

// TODO: This can be split into multiple smaller providers, one for the cartItems specifically, one for checkout, etc.
export default function CartProvider({ children }: PropsWithChildren): JSX.Element {
  const [defaultCartID, setDefaultCartID] = useState('')
  const { purchaseWithPaypal } = useFlags()
  const { contactInformation } = useUserProvider()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cartIdToCheckout, setCartIdToCheckout] = useState('')
  const [cartItems, setCartItems] = useState<CartItem[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [cartToUse, setCartToUse] = useState(DEFAULT_CART_SUMMARY)
  const [currentCartItemLoading, setCurrentCartItemLoading] = useState<number | undefined>(undefined)
  const [paginatedCartItems, setPaginatedCartItems] = useState<CartItem[]>([])
  const [cartPage, setCartPage] = useState(1)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<CartPaymentMethods | undefined>(
    purchaseWithPaypal ? undefined : CartPaymentMethods.STORE_CREDIT
  )
  const [selectedDeliveryMethodName, setSelectedDeliveryMethodName] = useState<string>('')
  const [shippingAmount, setShippingAmount] = useState(0)
  const [taxesAmount, setTaxesAmount] = useState(0)
  const [cartTotal, setCartTotal] = useState(0)
  const [isGiftReceipt, setIsGiftReceipt] = useState(false)
  const [attemptedToMergeCarts, setAttemptedToMergeCarts] = useState(true)
  const [showMergeCartDialog, setShowMergeCartDialog] = useState(false)
  const [isReceiveNewsAndPromotions, setIsReceiveNewsAndPromotions] = useState(false)
  const [giftMessage, setGiftMessage] = useState<string>()
  const [checkoutOrderParams, setCheckoutOrderParams] = useState<CartCheckoutRequest | undefined>(undefined)
  const [cartInstantPurchaseResult, setCartInstantPurchaseResult] = useState<CartInstantPurchaseResult | undefined>(
    undefined
  )
  const [shippingAddress, setShippingAddress] = useState<ContactInformation | undefined>(contactInformation)
  const [unvalidatedShippingAddress, setUnvalidatedShippingAddress] = useState<ContactInformation>()
  const [successfulAddToCart, setSuccessfulAddToCart] = useState<boolean>()
  const { setIsDisableCartButtons } = useProductDataProvider()
  const router = useRouter()
  const { data: session, status } = useSession()
  const { userActivated } = useUserActivationState()
  // TODO: Unsure about this change
  const { cartToUse, cartSummaries, isCartSummaryLoading, isCartSummaryError, fetchCartSummaries } = useCartSummary()
  const setShippingAndTaxes = useCallback(
    (deliveryMethod: DeliveryMethod | undefined) => {
      if (deliveryMethod) {
        setShippingAmount(deliveryMethod.pickPackAndPostageFee)
        setTaxesAmount(deliveryMethod.salesTax)
      } else {
        setShippingAmount(0)
        setTaxesAmount(0)
      }
    },
    [setShippingAmount, setTaxesAmount]
  )

  const getCartItems = useCallback(
    async (cartId: string) => {
      return clientAPIRequest<GetCartItemsResponse, GetCartItemsRequest>(
        `/api/cart/${cartId}`,
        RequestMethods.GET,
        undefined,
        session
      )
    },
    [session]
  )

  const getCartDeliveryMethods = useCallback(
    async (cartId: string, address: { countryCode: string; state: string; postalCode: string }) => {
      const params = {
        countryCode: address.countryCode,
        stateCode: address.state,
        zipCode: address.postalCode,
      }
      return clientAPIRequest<DeliveryMethodAPIResponse, DeliveryMethodRequest>(
        `/api/cart/${cartId}/deliveryMethod`,
        RequestMethods.GET,
        params,
        session
      )
    },
    [session]
  )

  const addOrRemoveCartItemByItemId = useCallback(
    async (itemId: number, method: RequestMethods.PUT | RequestMethods.DELETE, cartId?: string) => {
      return clientAPIRequest<CartSummaries, ObjWItemId>(
        `/api/cart/${cartId || defaultCartID}`,
        method,
        { itemId },
        session
      )
    },
    [defaultCartID, session]
  )

  const addOrRemoveToploaderByItemId = useCallback(
    async (itemId: number, method: RequestMethods.PUT | RequestMethods.DELETE, cartId?: string) => {
      return clientAPIRequest<CartSummaries, ObjWItemId>(
        `/api/cart/${cartId || defaultCartID}/toploader`,
        method,
        {
          itemId,
        },
        session
      )
    },
    [defaultCartID, session]
  )

  const getPaypalOrder = useCallback(
    async (cartId: string, checkoutParams: CartCheckoutRequest) => {
      return clientAPIRequest<CreateOrderRequestBody, CartCheckoutRequest>(
        `/api/cart/${cartId || defaultCartID}/checkout`,
        RequestMethods.POST,
        checkoutParams,
        session
      )
    },
    [defaultCartID, session]
  )

  const {
    isPending: isCartItemsLoading,
    isError: isCartItemsError,
    mutate: fetchCartItems,
  } = useMutation({
    mutationFn: (payload: { cartId: string }) => getCartItems(payload.cartId),
    onSuccess: (data) => {
      setCartItems(data.items as CartItem[])
    },
  })

  useEffect(() => {
    if (userActivated && cartSummaries.localCart.countOfItems > 0) {
      setShowMergeCartDialog(true)
    }
  }, [userActivated, cartSummaries])

  const {
    isPending: isCartDeliveryMethodsLoading,
    isError: isCartDeliveryMethodsError,
    data: cartDeliveryMethods,
    mutate: fetchCartDeliveryMethods,
  } = useMutation({
    mutationFn: (payload: { cartId: string }) =>
      getCartDeliveryMethods(payload.cartId, (unvalidatedShippingAddress || shippingAddress) as ContactInformation),
    onError: () => {
      setSelectedDeliveryMethodName('')
      setShippingAndTaxes(undefined)
    },
    onSuccess: (data) => {
      if (!data.options) {
        throw Error('No delivery options available')
      }
      setSelectedDeliveryMethodName(data.options[0].name as string)
      setShippingAndTaxes(data.options[0])
      if (unvalidatedShippingAddress) {
        setShippingAddress(unvalidatedShippingAddress)
        setUnvalidatedShippingAddress(undefined)
      }
    },
  })

  const {
    isPending: isPaypalOrderLoading,
    isError: isPaypalOrderError,
    data: paypalOrder,
    mutate: fetchPaypalOrder,
  } = useMutation({
    mutationFn: (payload: { cartId: string }) =>
      getPaypalOrder(payload.cartId, checkoutOrderParams as unknown as CartCheckoutRequest),
  })

  const {
    mutate: addOrRemoveCartItemByItemIdMutation,
    // isSuccess: isAddCartItemByItemIdSuccess,
    isPending: isAddCartItemByItemIdLoading,
    // isError: isAddCartItemByItemIdError,
  } = useMutation({
    mutationFn: (payload: {
      itemId: number
      method: RequestMethods.PUT | RequestMethods.DELETE
      isLastItem: boolean
      cartId?: string
    }) => addOrRemoveCartItemByItemId(payload.itemId, payload.method, payload.cartId),
    onMutate: async (payload: {
      itemId: number
      method: RequestMethods.PUT | RequestMethods.DELETE
      isLastItem: boolean
      cartId?: string
    }) => {
      await queryClient.cancelQueries({ queryKey: [cartQueryKeys.GetCartItems] })

      const previousCartItems = queryClient.getQueryData<CartItem[]>([cartQueryKeys.GetCartItems])

      if (previousCartItems) {
        if (payload.method === RequestMethods.PUT) {
          if (payload.cartId) {
            fetchCartItems({ cartId: payload.cartId })
          }
        } else {
          queryClient.setQueryData<CartItem[] | TemporaryCartItem[]>(
            [cartQueryKeys.GetCartItems],
            previousCartItems.filter((cartItem) => cartItem.itemId !== payload.itemId)
          )
        }
      }

      return { previousCartItems }
    },
    // eslint-disable-next-line n/handle-callback-err
    onError: (err, variables, context) => {
      if (context?.previousCartItems) {
        queryClient.setQueryData<CartItem[]>([cartQueryKeys.GetCartItems], context.previousCartItems)
      }
      setCurrentCartItemLoading(undefined)
      setIsDisableCartButtons(false)
    },
    onSuccess: (_data, variables) => {
      fetchCartSummaries()
      setSuccessfulAddToCart(_data && variables.method === RequestMethods.PUT)
      setCurrentCartItemLoading(undefined)
      if (variables.isLastItem) {
        if (variables.cartId) {
          fetchCartItems({ cartId: variables.cartId })
        }
        if (variables.cartId) {
          fetchCartDeliveryMethods({ cartId: variables.cartId })
        }
        setIsDisableCartButtons(false)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [cartQueryKeys.GetCartItems] }).then(() => Promise.resolve())
    },
  })

  const { mutate: addOrRemoveToploaderByItemIdMutation, isPending: isAddOrRemoveToploaderByItemIdMutationLoading } =
    useMutation({
      mutationFn: (payload: {
        itemId: number
        method: RequestMethods.PUT | RequestMethods.DELETE
        isLastItem: boolean
        cartId?: string
      }) => addOrRemoveToploaderByItemId(payload.itemId, payload.method, payload.cartId),
      onMutate: async (payload: {
        itemId: number
        method: RequestMethods.PUT | RequestMethods.DELETE
        isLastItem: boolean
        cartId?: string
      }) => {
        await queryClient.cancelQueries({ queryKey: [cartQueryKeys.GetCartItems] })

        const previousCartItems = queryClient.getQueryData<CartItem[]>([cartQueryKeys.GetCartItems])

        if (previousCartItems) {
          const cartItems = previousCartItems.filter((cartItem) => cartItem.itemId !== payload.itemId)
          if (payload.method === RequestMethods.PUT) {
            const updatedCartItem = previousCartItems
              .filter((cartItem) => cartItem.itemId === payload.itemId)
              .map((item) => ({ ...item, addTopLoader: true }))
            queryClient.setQueryData<CartItem[]>([cartQueryKeys.GetCartItems], [...cartItems, ...updatedCartItem])
          } else {
            const updatedCartItem = previousCartItems
              .filter((cartItem) => cartItem.itemId === payload.itemId)
              .map((item) => ({ ...item, addTopLoader: false }))

            queryClient.setQueryData<CartItem[]>([cartQueryKeys.GetCartItems], [...cartItems, ...updatedCartItem])
          }
        }

        return { previousCartItems }
      },
      // eslint-disable-next-line n/handle-callback-err
      onError: (err, variables, context) => {
        if (context?.previousCartItems) {
          queryClient.setQueryData<CartItem[]>([cartQueryKeys.GetCartItems], context.previousCartItems)
        }
      },
      onSuccess: (_data, variables) => {
        if (variables.isLastItem) {
          if (variables.cartId) {
            fetchCartItems({ cartId: variables.cartId })
          }
          if (variables.cartId) {
            fetchCartDeliveryMethods({ cartId: variables.cartId })
          }
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [cartQueryKeys.GetCartItems] }).then(() => Promise.resolve())
      },
    })

  const postCartInstantPurchase = useCallback(
    async (cartId: string) => {
      return clientAPIRequest<CartInstantPurchaseResult, unknown>(
        '/api/instantPurchase/shoppingCart',
        RequestMethods.POST,
        { cartId },
        session
      )
    },
    [session]
  )

  const { mutate: cartInstantPurchaseMutation, isPending: isCartInstantPurchaseMutationLoading } = useMutation({
    mutationFn: (payload: { cartId: string }) => postCartInstantPurchase(payload.cartId),
    onSuccess: (_data, variables) => {
      setCartInstantPurchaseResult(_data)
      fetchCartSummaries()
      fetchCartItems({ cartId: variables.cartId })
    },
  })

  useEffect(() => {
    if (!cartItems || isCartItemsError || isCartItemsLoading) {
      return
    }
    if (cartItems.length > CART_ITEMS_PER_PAGE) {
      const startIndex = cartPage * CART_ITEMS_PER_PAGE - CART_ITEMS_PER_PAGE
      const endIndex = cartPage * CART_ITEMS_PER_PAGE
      setPaginatedCartItems(cartItems.slice(startIndex, endIndex))
    } else {
      setPaginatedCartItems(cartItems)
    }
  }, [cartItems, cartPage, isCartItemsError, isCartItemsLoading])

  useEffect(() => {
    if (router.pathname.includes('cart') && (unvalidatedShippingAddress || shippingAddress) && cartToUse.cartId) {
      fetchCartDeliveryMethods({ cartId: cartToUse.cartId })
    }
  }, [cartToUse, fetchCartDeliveryMethods, unvalidatedShippingAddress, shippingAddress, router])

  useEffect(() => {
    if (contactInformation) {
      setShippingAddress(contactInformation)
      return () => {
        window.removeEventListener('storage', getLocalShippingAddress)
      }
    }
    getLocalShippingAddress()
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', getLocalShippingAddress, false)
    }
    return () => {
      window.removeEventListener('storage', getLocalShippingAddress)
    }
  }, [contactInformation])

  const getLocalShippingAddress = () => {
    const localShippingAddress = localStorage.getItem('shippingAddress')
    if (localShippingAddress) {
      const localAddress = JSON.parse(localShippingAddress) as ContactInformation
      setShippingAddress(localAddress)
    } else {
      setShippingAddress(undefined)
    }
  }

  useEffect(() => {
    if (shippingAddress) {
      localStorage.setItem('shippingAddress', JSON.stringify(shippingAddress))
    }
  }, [shippingAddress])

  useEffect(() => {
    if (selectedDeliveryMethodName) {
      const selectedDeliveryOption = cartDeliveryMethods?.options?.filter(
        (deliveryOption: DeliveryMethod) => deliveryOption.name === selectedDeliveryMethodName
      )[0]
      if (selectedDeliveryOption) {
        const checkoutParams = {
          shippingOptionId: selectedDeliveryOption.id,
          shippingAddress,
        } as CartCheckoutRequest
        if (isGiftReceipt) {
          checkoutParams.giftReceipt = true
          checkoutParams.giftMessage = giftMessage
        }
        if (isReceiveNewsAndPromotions) {
          checkoutParams.emailNewsletterOptIn = true
        }

        setCheckoutOrderParams(checkoutParams)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliveryMethodName, giftMessage, isGiftReceipt, isReceiveNewsAndPromotions, shippingAddress])

  useEffect(() => {
    // TODO: This should have better placement
    if (typeof localStorage === 'undefined') {
      return
    }
    let localCartID = v4()
    if (!localStorage.getItem('comc:$anonCartToken')) {
      localStorage.setItem('comc:$anonCartToken', localCartID)
    } else {
      localCartID = localStorage.getItem('comc:$anonCartToken')!
    }
    const cartId = cartSummaries?.universalCart ? cartSummaries.universalCart.cartId : localCartID
    setDefaultCartID(cartId)
  }, [cartSummaries])

  useEffect(() => {
    if (!cartSummaries?.localCart.cartId) {
      return
    }
    if (status === NextAuthStatuses.UNAUTHENTICATED) {
      fetchCartItems({ cartId: cartSummaries.localCart.cartId })
    } else if (status === NextAuthStatuses.AUTHENTICATED && cartSummaries.universalCart) {
      fetchCartItems({ cartId: cartSummaries.universalCart.cartId })
    }
  }, [status, fetchCartItems, cartSummaries])

  useEffect(() => {
    if (checkoutOrderParams && selectedPaymentMethod === CartPaymentMethods.PAYPAL) {
      fetchPaypalOrder({ cartId: cartIdToCheckout })
    }
  }, [cartIdToCheckout, checkoutOrderParams, fetchPaypalOrder, selectedPaymentMethod])

  useEffect(() => {
    let cartTotal = cartToUse.sumOfVisiblePrice
    if (selectedPaymentMethod === CartPaymentMethods.PAYPAL) {
      cartTotal = cartToUse.sumOfVisiblePrice + cartToUse.topLoaders * TOPLOADER_PRICE + shippingAmount + taxesAmount
    }
    setCartTotal(cartTotal)
  }, [cartToUse, selectedPaymentMethod, shippingAmount, taxesAmount])

  useEffect(() => {
    if (status === NextAuthStatuses.UNAUTHENTICATED) {
      localStorage.setItem('mergeCartAttempt', '')
    }
  }, [status])

  useEffect(() => {
    if (typeof localStorage === 'undefined') {
      return
    }
    setAttemptedToMergeCarts(!!localStorage.getItem('mergeCartAttempt'))
  }, [])

  const cartValue = useMemo(
    () => ({
      cartItems,
      isCartItemsLoading,
      isCartItemsError,
      paginatedCartItems,
      cartPage,
      setCartPage,
      selectedPaymentMethod,
      setSelectedPaymentMethod,
      shippingAddress,
      setShippingAddress,
      unvalidatedShippingAddress,
      setUnvalidatedShippingAddress,
      selectedDeliveryMethodName,
      setSelectedDeliveryMethodName,
      cartSummaries,
      cartToUse,
      isCartSummaryLoading,
      isCartSummaryError,
      fetchCartSummaries,
      isCartDeliveryMethodsLoading,
      isCartDeliveryMethodsError,
      cartDeliveryMethods,
      fetchCartDeliveryMethods,
      cartTotal,
      shippingAmount,
      taxesAmount,
      setShippingAmount,
      setTaxesAmount,
      setShippingAndTaxes,
      addOrRemoveCartItemByItemIdMutation,
      successfulAddToCart,
      setSuccessfulAddToCart,
      isAddCartItemByItemIdLoading,
      currentCartItemLoading,
      setCurrentCartItemLoading,
      addOrRemoveToploaderByItemIdMutation,
      isAddOrRemoveToploaderByItemIdMutationLoading,
      isGiftReceipt,
      setIsGiftReceipt,
      giftMessage,
      setGiftMessage,
      isReceiveNewsAndPromotions,
      setIsReceiveNewsAndPromotions,
      checkoutOrderParams,
      setCheckoutOrderParams,
      isPaypalOrderLoading,
      isPaypalOrderError,
      paypalOrder,
      fetchPaypalOrder,
      cartInstantPurchaseResult,
      cartInstantPurchaseMutation,
      isCartInstantPurchaseMutationLoading,
      showMergeCartDialog,
      setShowMergeCartDialog,
      attemptedToMergeCarts,
    }),
    [
      cartItems,
      isCartItemsLoading,
      isCartItemsError,
      paginatedCartItems,
      cartPage,
      selectedPaymentMethod,
      shippingAddress,
      unvalidatedShippingAddress,
      selectedDeliveryMethodName,
      cartToUse,
      cartSummaries,
      isCartSummaryLoading,
      isCartSummaryError,
      fetchCartSummaries,
      isCartDeliveryMethodsLoading,
      isCartDeliveryMethodsError,
      cartDeliveryMethods,
      fetchCartDeliveryMethods,
      cartTotal,
      shippingAmount,
      taxesAmount,
      setShippingAndTaxes,
      addOrRemoveCartItemByItemIdMutation,
      successfulAddToCart,
      setSuccessfulAddToCart,
      isAddCartItemByItemIdLoading,
      currentCartItemLoading,
      addOrRemoveToploaderByItemIdMutation,
      isAddOrRemoveToploaderByItemIdMutationLoading,
      isGiftReceipt,
      giftMessage,
      isReceiveNewsAndPromotions,
      checkoutOrderParams,
      isPaypalOrderLoading,
      isPaypalOrderError,
      paypalOrder,
      fetchPaypalOrder,
      cartInstantPurchaseResult,
      cartInstantPurchaseMutation,
      isCartInstantPurchaseMutationLoading,
      showMergeCartDialog,
      setShowMergeCartDialog,
      attemptedToMergeCarts,
    ]
  )

  return <cartContext.Provider value={cartValue}>{children}</cartContext.Provider>
}

export const useCartProvider = (): CartContext => useContext(cartContext)
