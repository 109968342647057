export const HTTP_HEADER = {
  ACCEPT: 'accept',
  ACCEPT_CHARSET: 'accept-charset',
  ACCEPT_ENCODING: 'accept-encoding',
  ACCEPT_LANGUAGE: 'accept-language',
  ACCEPT_RANGES: 'accept-ranges',
  ACCESS_CONTROL_ALLOW_CREDENTIALS: 'access-control-allow-credentials',
  ACCESS_CONTROL_ALLOW_HEADERS: 'access-control-allow-headers',
  ACCESS_CONTROL_ALLOW_METHODS: 'access-control-allow-methods',
  ACCESS_CONTROL_ALLOW_ORIGIN: 'access-control-allow-origin',
  ACCESS_CONTROL_EXPOSE_HEADERS: 'access-control-expose-headers',
  ACCESS_CONTROL_MAX_AGE: 'access-control-max-age',
  ACCESS_CONTROL_REQUEST_HEADERS: 'access-control-request-headers',
  ACCESS_CONTROL_REQUEST_METHOD: 'access-control-request-method',
  AGE: 'age',
  ALLOW: 'allow',
  ALT_SVC: 'alt-svc',
  AUTHORITY: ':authority',
  AUTHORIZATION: 'authorization',
  CACHE_CONTROL: 'cache-control',
  CONNECTION: 'connection',
  CONTENT_DISPOSITION: 'content-disposition',
  CONTENT_ENCODING: 'content-encoding',
  CONTENT_LANGUAGE: 'content-language',
  CONTENT_LENGTH: 'content-length',
  CONTENT_LOCATION: 'content-location',
  CONTENT_MD5: 'content-md5',
  CONTENT_RANGE: 'content-range',
  CONTENT_SECURITY_POLICY: 'content-security-policy',
  CONTENT_TYPE: 'content-type',
  COOKIE: 'cookie',
  DATE: 'date',
  DNT: 'dnt',
  EARLY_DATA: 'early-data',
  ETAG: 'etag',
  EXPECT: 'expect',
  EXPECT_CT: 'expect-ct',
  EXPIRES: 'expires',
  FORWARDED: 'forwarded',
  FROM: 'from',
  HOST: 'host',
  HTTP2_SETTINGS: 'http2-settings',
  IF_MATCH: 'if-match',
  IF_MODIFIED_SINCE: 'if-modified-since',
  IF_NONE_MATCH: 'if-none-match',
  IF_RANGE: 'if-range',
  IF_UNMODIFIED_SINCE: 'if-unmodified-since',
  KEEP_ALIVE: 'keep-alive',
  LAST_MODIFIED: 'last-modified',
  LINK: 'link',
  LOCATION: 'location',
  MAX_FORWARDS: 'max-forwards',
  METHOD: ':method',
  ORIGIN: 'origin',
  PATH: ':path',
  PREFER: 'prefer',
  PRIORITY: 'priority',
  PROTOCOL: ':protocol',
  PROXY_AUTHENTICATE: 'proxy-authenticate',
  PROXY_AUTHORIZATION: 'proxy-authorization',
  PROXY_CONNECTION: 'proxy-connection',
  PURPOSE: 'purpose',
  RANGE: 'range',
  REFERER: 'referer',
  REFRESH: 'refresh',
  RETRY_AFTER: 'retry-after',
  SCHEME: ':scheme',
  SERVER: 'server',
  SET_COOKIE: 'set-cookie',
  STATUS: ':status',
  STRICT_TRANSPORT_SECURITY: 'strict-transport-security',
  TE: 'te',
  TIMING_ALLOW_ORIGIN: 'timing-allow-origin',
  TK: 'tk',
  TRAILER: 'trailer',
  TRANSFER_ENCODING: 'transfer-encoding',
  UPGRADE: 'upgrade',
  UPGRADE_INSECURE_REQUESTS: 'upgrade-insecure-requests',
  USER_AGENT: 'user-agent',
  VARY: 'vary',
  VIA: 'via',
  WARNING: 'warning',
  WWW_AUTHENTICATE: 'www-authenticate',
  X_CONTENT_TYPE_OPTIONS: 'x-content-type-options',
  X_FORWARDED_FOR: 'x-forwarded-for',
  X_FRAME_OPTIONS: 'x-frame-options',
  X_XSS_PROTECTION: 'x-xss-protection',
  // NOTE: Custom headers from COMC Web API
  API_SUPPORTED_VERSIONS: 'api-supported-versions',
  X_API_VERSION: 'x-api-version',
}

export const HTTP_STATUS = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  EARLY_HINTS: 103,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  BANDWIDTH_LIMIT_EXCEEDED: 509,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
}
