import type { Nullable } from '@/types/utility'
import type { SearchParamsPayload } from './searchRequest'

export enum SearchSuggestionType {
  SEARCH = 'SEARCH',
  SAVED_SEARCH = 'SAVED_SEARCH',
  RECENT_SEARCH = 'RECENT_SEARCH',
  AUTOCOMPLETION = 'AUTOCOMPLETION',
}

export type SearchSuggestionParams = SearchParamsPayload & {
  searchTerm: string
  newResultCount?: Nullable<number>
}

export interface SearchSuggestion {
  id?: number
  title: string
  type: SearchSuggestionType
  params: SearchSuggestionParams
}
