export const currencyFormatter = (maximumSignificantDigits: number | undefined) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits,
  })
}

export const formatCurrency = (amount: number, showCents = true): string => {
  const maximumSignificantDigits = showCents ? undefined : 2
  return !isNaN(amount) && typeof amount !== 'undefined'
    ? currencyFormatter(maximumSignificantDigits).format(amount)
    : ''
}

export const convertCurrencyToNumberString = (amount: string): string => {
  if (!amount) {
    return amount
  }
  return amount.replace(/[^0-9.]/g, '')
}

export const convertCurrencyStringToNumber = (currencyStr: string): number => {
  const numberValue = parseFloat(currencyStr.replaceAll('$', '') || '0')
  return isNaN(numberValue) ? 0 : numberValue
}
