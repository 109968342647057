import type { ItemManagementDetails } from '@/interfaces/inventory/inventoryItemManagementDetails'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'
import { itemManagementDetailsURL } from '@/constants/apiURLs'

const useItemManagementDetails = (isItemOwned: boolean, itemId?: number) => {
  const { data: managementDetails } = useAuthenticatedQuery<ItemManagementDetails>(
    [RequestMethods.GET],
    RequestMethods.GET,
    itemManagementDetailsURL(itemId!),
    {
      queryKey: ['getItemManagementDetails', { itemId }],
      enabled: !!itemId && isItemOwned,
    }
  )

  return { managementDetails }
}

export default useItemManagementDetails
