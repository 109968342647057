import { RequestMethods } from '@/interfaces/api/requestMethods'

export const WATCH_LIST_ACCEPTED_REQUEST_METHODS = [RequestMethods.GET]
export const ADD_REMOVE_OR_UPDATE_WATCH_LIST_ACCEPTED_REQUEST_METHODS = [
  RequestMethods.PUT,
  RequestMethods.DELETE,
  RequestMethods.PATCH,
]

export enum WatchlistSortOptions {
  NEWEST = 'Newest',
  OLDEST = 'Oldest',
  LOWEST_PRICE = 'LowestPrice',
  HIGHEST_PRICE = 'HighestPrice',
  RECENTLY_ADDED = 'RecentlyAdded',
}

export interface WatchlistApiRequest {
  sort?: string
  startRowIndex?: number
  maximumRows?: number
}
