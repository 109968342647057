import type { SvgIcon } from '@mui/material'
import type URI from 'urijs'
import type { TabLink } from '@/interfaces/baseTab'

import type { DashboardSections } from '@/constants'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExternalHref = URL | ((...args: any[]) => URL | URI) | URI

interface ExternalHrefMixin {
  /** TODO: Fix handling of externalHref. The union type it has *will* cause bugs. */
  externalHref: ExternalHref
}

export type DashboardSubSection = {
  tabName: string
  href?: URL | URI
}

export interface DashboardNodeLink extends TabLink, ExternalHrefMixin {
  readonly guid: string
  readonly key: string
  readonly parentKey: DashboardSections
  readonly enabled: boolean
  readonly tabName?: string
  readonly href: string
  readonly text: string
  readonly subSections?: DashboardSubSection[]
  readonly initalActiveTab?: string
  asUri: () => URI
}

export interface IDashboardSection extends Partial<ExternalHrefMixin> {
  readonly key: DashboardSections
  readonly slug: string
  readonly primaryText: string
  readonly secondaryText?: string
  readonly pages: DashboardNodeLink[]
  readonly icon: typeof SvgIcon
  readonly activeIcon: typeof SvgIcon
}

export class DashboardSection implements IDashboardSection {
  readonly key: DashboardSections
  readonly slug: string
  readonly primaryText: string
  readonly secondaryText?: string
  readonly pages: DashboardNodeLink[]
  readonly icon: typeof SvgIcon
  readonly activeIcon: typeof SvgIcon
  readonly externalHref?: ExternalHref

  constructor(init: DashboardSection) {
    this.key = init.key
    this.slug = init.slug
    this.primaryText = init.primaryText
    this.secondaryText = init.secondaryText
    this.pages = init.pages
    this.icon = init.icon
    this.activeIcon = init.activeIcon
    this.externalHref = init.externalHref
  }
}

export type DashboardRoute = {
  guid: string
  section: DashboardSection
  link: DashboardNodeLink
  pathname: string
}
