import type { SavedSearchesApiRequest } from '@/interfaces/savedSearches/savedSearchesApiRequest'

import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { type RouterEvent, useRouter } from 'next/router'

import { DEFAULT_SAVED_SEARCHES_PARAMS, savedSearchesUri, SavedSearchQueryKeys } from '@/constants/savedSearches'
import { clientAPIRequest, urlToSearchParams } from '@/helpers'
import { type PatchParams, RequestMethods } from '@/interfaces/api/requestMethods'
import {
  type SavedSearchesResponse,
  type SavedSearchRequest,
  type SavedSearchResponse,
  SavedSearchUtils,
} from '@/interfaces/savedSearches/savedSearches'
import { SEARCH_BASE_HREF } from '@/constants'
import { useSearchResultsProvider } from './searchResultsProvider'
import { type SearchSuggestion, SearchSuggestionType } from '@/interfaces/autocompleteSearchOption'
import useSavedSearchCount, { type SavedSearchCount } from '@/hooks/data/useSavedSearchCount'
import useUserActivationState from '@/hooks/useUserActivationState'

interface GetSavedSearchQueryParams {
  key?: SavedSearchQueryKeys
  params?: SavedSearchesApiRequest
}

type SavedSearchesContext = {
  // TODO: convert to map structure
  savedSearches?: SavedSearchResponse[]
  activeSavedSearch?: SavedSearchResponse
  setActiveSavedSearch: Dispatch<SetStateAction<SavedSearchResponse | undefined>>
  hasNewResultCounts: boolean
  setHasNewResultCounts: Dispatch<SetStateAction<boolean>>
  isSavedSearchesLoading: boolean
  isSavedSearchesError: boolean
  fetchSavedSearches: () => void
  hasSavedSearch: (resourceOrId?: SavedSearchResponse | SavedSearchRequest | number) => boolean
  getSavedSearch: (resourceOrId?: SavedSearchResponse | SavedSearchRequest | number) => SavedSearchResponse | undefined
  addedRemovedOrUpdatedSavedSavedSearch?: SavedSearchResponse
  addRemoveOrUpdateSavedSearchMutation: (payload: {
    resource: SavedSearchResponse | SavedSearchRequest
    method: RequestMethods.DELETE | RequestMethods.PATCH | RequestMethods.POST
    patch?: PatchParams[]
  }) => void
  isAddRemoveOrUpdateSavedSearchMutationLoading: boolean
  isAddRemoveOrUpdateSavedSearchMutationSuccess: boolean
  isAddRemoveOrUpdateSavedSearchMutationError: boolean
  savedSearchesQueryParams: SavedSearchesApiRequest
  setSavedSearchesQueryParams: Dispatch<SetStateAction<SavedSearchesApiRequest>>
  getSavedSearchSuggestions: (start: number, end?: number) => SearchSuggestion[]
  savedSearchCount: SavedSearchCount
  isSavedSearchCountLoading?: boolean
  isRefetchingSavedSearch?: boolean
}

const queryClient = new QueryClient()
const savedSearchesContext = createContext<SavedSearchesContext>({
  savedSearches: undefined,
  activeSavedSearch: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveSavedSearch: () => {},
  hasNewResultCounts: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHasNewResultCounts: () => {},
  isSavedSearchesLoading: false,
  isSavedSearchesError: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchSavedSearches: () => {},
  hasSavedSearch: () => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getSavedSearch: () => undefined,
  addedRemovedOrUpdatedSavedSavedSearch: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addRemoveOrUpdateSavedSearchMutation: () => {},
  isAddRemoveOrUpdateSavedSearchMutationLoading: false,
  isAddRemoveOrUpdateSavedSearchMutationSuccess: false,
  isAddRemoveOrUpdateSavedSearchMutationError: false,
  savedSearchesQueryParams: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSavedSearchesQueryParams: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getSavedSearchSuggestions: () => [],
  savedSearchCount: {},
  isRefetchingSavedSearch: false,
})

export default function SavedSearchesProvider({ children }: PropsWithChildren): JSX.Element {
  const [savedSearchesQueryParams, setSavedSearchesQueryParams] =
    useState<SavedSearchesApiRequest>(DEFAULT_SAVED_SEARCHES_PARAMS)
  const [hasNewResultCounts, setHasNewResultCounts] = useState<boolean>(false)
  const [activeSavedSearch, setActiveSavedSearch] = useState<SavedSearchResponse | undefined>()
  const { searchParams } = useSearchResultsProvider()
  const [savedSearchIds, setSavedSearchIds] = useState<number[]>([])
  const { savedSearchCount, isSavedSearchCountLoading, isRefetchingSavedSearch } = useSavedSearchCount(savedSearchIds)
  const { userActivated } = useUserActivationState()
  const router = useRouter()

  const getSavedSearches = useCallback(
    async (savedSearchesQueryParams?: SavedSearchesApiRequest): Promise<SavedSearchResponse[]> => {
      return clientAPIRequest<SavedSearchesResponse, SavedSearchesApiRequest>(
        savedSearchesUri(),
        RequestMethods.GET,
        savedSearchesQueryParams
      ).then((data: SavedSearchesResponse) => {
        setSavedSearchIds(data.savedSearches.map((ss) => ss.id))
        setHasNewResultCounts(data.retrieveNewResultCount)
        return data.savedSearches
      })
    },
    []
  )
  const addSavedSearch = useCallback(async (resource: SavedSearchRequest) => {
    return clientAPIRequest<boolean, SavedSearchRequest>(`${savedSearchesUri()}/create`, RequestMethods.POST, resource)
  }, [])

  const modifySavedSearch = useCallback(async (id: number, patch: PatchParams[]) => {
    return clientAPIRequest<boolean, PatchParams[]>(savedSearchesUri(id), RequestMethods.PATCH, patch)
  }, [])

  const deleteSavedSearch = useCallback(async (id: number) => {
    return clientAPIRequest<boolean, null>(savedSearchesUri(id), RequestMethods.DELETE)
  }, [])

  const addRemoveOrUpdateSavedSearch = useCallback(
    async (method: RequestMethods, resource: SavedSearchResponse | SavedSearchRequest, patch?: PatchParams[]) => {
      switch (method) {
        case RequestMethods.POST:
          return addSavedSearch(resource)
        case RequestMethods.PATCH:
          return patch && modifySavedSearch((resource as SavedSearchResponse).id, patch)
        case RequestMethods.DELETE:
          return deleteSavedSearch((resource as SavedSearchResponse).id)
      }
    },
    [modifySavedSearch, deleteSavedSearch, addSavedSearch]
  )

  function useGetSavedSearchesQuery({ params, ...options }: GetSavedSearchQueryParams) {
    return useQuery({
      queryKey: options.key
        ? [SavedSearchQueryKeys.GetSavedSearches, options.key]
        : [SavedSearchQueryKeys.GetSavedSearches, params],
      queryFn: () => getSavedSearches(params || savedSearchesQueryParams),
      enabled: userActivated,
      ...options,
    })
  }

  const {
    isLoading: isSavedSearchesLoading,
    isError: isSavedSearchesError,
    data: savedSearches,
    refetch: fetchSavedSearches,
  } = useGetSavedSearchesQuery({ key: SavedSearchQueryKeys.GetAll })

  const hasSavedSearch = useCallback(
    (resourceOrId?: SavedSearchRequest | SavedSearchResponse | number) => {
      return SavedSearchUtils.includes(resourceOrId, savedSearches)
    },
    [savedSearches]
  )

  const getSavedSearch = useCallback(
    (resourceOrId?: SavedSearchResponse | SavedSearchRequest | number) => {
      return SavedSearchUtils.find(resourceOrId, savedSearches)
    },
    [savedSearches]
  )

  // TODO: Update this to not rely on loading all saved searches.
  const getSavedSearchSuggestions = useCallback(
    (start = 0, end?: number) => {
      return (
        (savedSearches &&
          savedSearches.slice(start, end).map((ss) => {
            return {
              id: ss.id,
              title: ss.title,
              type: SearchSuggestionType.SAVED_SEARCH,
              params: SavedSearchUtils.toSearchParams(ss),
            }
          })) ||
        []
      )
    },
    [savedSearches]
  )

  const {
    mutate: addRemoveOrUpdateSavedSearchMutation,
    isPending: isAddRemoveOrUpdateSavedSearchMutationLoading,
    isSuccess: isAddRemoveOrUpdateSavedSearchMutationSuccess,
    isError: isAddRemoveOrUpdateSavedSearchMutationError,
    data: addedRemovedOrUpdatedSavedSavedSearch,
  } = useMutation({
    mutationFn: (payload: {
      resource: SavedSearchResponse | SavedSearchRequest
      method: RequestMethods.DELETE | RequestMethods.PATCH | RequestMethods.POST
      patch?: PatchParams[]
    }) => {
      return addRemoveOrUpdateSavedSearch(payload.method, payload.resource, payload.patch).then(() => {
        // NOTE: SavedSearches API does not return created/modified items in the response
        if (payload.method !== RequestMethods.DELETE) {
          return fetchSavedSearches().then((result) => {
            return SavedSearchUtils.find(payload.resource, result.data)
          })
        } else {
          return undefined
        }
      })
    },
    // executes before mutationFn
    onMutate: async (payload: {
      resource: SavedSearchResponse | SavedSearchRequest
      method: RequestMethods.DELETE | RequestMethods.PATCH | RequestMethods.POST
    }) => {
      await queryClient.cancelQueries({ queryKey: [SavedSearchQueryKeys.GetSavedSearches] })

      const previousSavedSearches = queryClient.getQueryData<SavedSearchResponse[]>([
        SavedSearchQueryKeys.GetSavedSearches,
      ])

      if (previousSavedSearches) {
        switch (payload.method) {
          case RequestMethods.PATCH:
            queryClient.setQueryData<SavedSearchResponse[]>(
              [SavedSearchQueryKeys.GetSavedSearches],
              [...previousSavedSearches, payload.resource as SavedSearchResponse]
            )
            break
          case RequestMethods.DELETE:
            queryClient.setQueryData<SavedSearchResponse[]>(
              [SavedSearchQueryKeys.GetSavedSearches],
              [...previousSavedSearches.filter((ss) => ss.id !== (payload.resource as SavedSearchResponse).id)]
            )
            break
        }
      }

      return { previousSavedSearches }
    },

    // eslint-disable-next-line n/handle-callback-err
    onError: (err, variables, context) => {
      if (context?.previousSavedSearches) {
        queryClient.setQueryData<SavedSearchResponse[]>(
          [SavedSearchQueryKeys.GetSavedSearches],
          context.previousSavedSearches
        )
      }
    },
    onSuccess: (_data, variables) => {
      setSavedSearchesQueryParams({ retrieveNewResultCounts: false })
      const _savedSearches: SavedSearchResponse[] = []
      if (variables.method === RequestMethods.POST && _data) {
        setActiveSavedSearch(_data)
      }
      if (variables.method !== RequestMethods.DELETE) {
        queryClient.setQueryData<SavedSearchResponse[]>([SavedSearchQueryKeys.GetSavedSearches], (oldData) => {
          if (oldData) {
            _savedSearches.push(...oldData)
          }
          if (_data) {
            _savedSearches.push(_data)
          }
          return _savedSearches
        })
      } else {
        fetchSavedSearches().then(() => Promise.resolve())
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [SavedSearchQueryKeys.GetSavedSearches] }).then(() => Promise.resolve())
    },
  })

  /** Do once on search page load: check if current search is saved search */
  useMemo(() => {
    if (userActivated && savedSearches && !activeSavedSearch && router.pathname === SEARCH_BASE_HREF) {
      setActiveSavedSearch(getSavedSearch(SavedSearchUtils.toSavedSearchRequest('', searchParams)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearches?.length, userActivated, router.pathname])

  // Set activeSavedSearch when on Search page
  useEffect(() => {
    const createRouteEventHandler = (event: RouterEvent) => {
      return (url: string) => {
        switch (event) {
          case 'routeChangeStart':
            if (url.startsWith(SEARCH_BASE_HREF)) {
              const savedSearchRequest = SavedSearchUtils.toSavedSearchRequest('', urlToSearchParams(url))
              const found = SavedSearchUtils.equals(savedSearchRequest, activeSavedSearch)
                ? activeSavedSearch
                : getSavedSearch(savedSearchRequest)
              setActiveSavedSearch(found)
            } else {
              setActiveSavedSearch(undefined)
            }
            break
          case 'beforeHistoryChange':
          case 'routeChangeComplete':
          case 'routeChangeError':
          case 'hashChangeStart':
          case 'hashChangeComplete':
          default:
            break
        }
      }
    }
    const onRouteChangeStart = createRouteEventHandler('routeChangeStart')

    if (router.events) {
      router.events.on('routeChangeStart', onRouteChangeStart)
    }

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const provider = useMemo(
    () => ({
      savedSearches,
      activeSavedSearch,
      setActiveSavedSearch,
      isSavedSearchesLoading,
      isSavedSearchesError,
      fetchSavedSearches,
      hasSavedSearch,
      getSavedSearch,
      addRemoveOrUpdateSavedSearchMutation,
      addedRemovedOrUpdatedSavedSavedSearch,
      isAddRemoveOrUpdateSavedSearchMutationLoading,
      isAddRemoveOrUpdateSavedSearchMutationSuccess,
      isAddRemoveOrUpdateSavedSearchMutationError,
      savedSearchesQueryParams,
      setSavedSearchesQueryParams,
      hasNewResultCounts,
      setHasNewResultCounts,
      getSavedSearchSuggestions,
      savedSearchCount,
      isSavedSearchCountLoading,
      isRefetchingSavedSearch,
    }),
    [
      savedSearches,
      activeSavedSearch,
      setActiveSavedSearch,
      isSavedSearchesLoading,
      isSavedSearchesError,
      fetchSavedSearches,
      hasSavedSearch,
      getSavedSearch,
      addRemoveOrUpdateSavedSearchMutation,
      addedRemovedOrUpdatedSavedSavedSearch,
      isAddRemoveOrUpdateSavedSearchMutationLoading,
      isAddRemoveOrUpdateSavedSearchMutationSuccess,
      isAddRemoveOrUpdateSavedSearchMutationError,
      savedSearchesQueryParams,
      hasNewResultCounts,
      getSavedSearchSuggestions,
      savedSearchCount,
      isSavedSearchCountLoading,
      isRefetchingSavedSearch,
    ]
  )

  return <savedSearchesContext.Provider value={provider}>{children}</savedSearchesContext.Provider>
}

export const useSavedSearchesProvider = (): SavedSearchesContext => useContext(savedSearchesContext)
