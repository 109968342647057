import type { ResultWImage } from './resultWImage'
import type { ReturnedSearchParams } from './searchRequest'

export enum CARD_ATTRIBUTES {
  ROOKIE_CARD = 'RC',
  MEMORABILIA = 'MEM',
  AUTOGRAPHED = 'AUTO',
  SERIAL_NUMBERED = 'SN',
  PRE_ROOKIE_CARD = 'PRC',
  ROOKIE_YEAR = 'RY',
  ROOKIE_RELATED = 'RR',
  HALL_OF_FAME = 'HOF',
}

export interface PriceProps {
  price: number
  qtyForSale: number
  isOnSale: boolean
  discountedPrice: number
  auctionPromotionId: number
  bidCount: number
  auctionEndDate?: string // Double check
}

export interface SearchResultHit extends PriceProps, ResultWImage {
  productId: number
  catalogId: number
  grade?: string
  gradeId: number
  setName?: string
  listingNumber?: string
  description?: string
  conditionNotes?: string
  serialNumber?: string
  bidders: number
  bids: number
  itemId: number
  rookieDesignation: CARD_ATTRIBUTES
  isOwned: boolean
}

export interface Filter {
  name: string
  path: string
  count: number
}

export interface FilterWithId extends Filter {
  id: number
}

interface GradeFilter extends FilterWithId {
  gradeActionDescription: string
  gradeActionPath: string
  graderName: string
  graderPath: string
}

export type SearchResultsData = {
  searchResults: {
    newOwner?: string
    searchDescription?: string
    searchDescriptionWithMarkup?: string
    totalRows: number
    hits: SearchResultHit[]
    sports: FilterWithId[]
    categories: FilterWithId[]
    genres: FilterWithId[]
    decades: Filter[]
    years: Filter[]
    parentSets: Filter[]
    sets: Filter[]
    players: FilterWithId[]
    teams: FilterWithId[]
    attributes: Filter[]
    owners: FilterWithId[]
    gradeActions: Filter[]
    graders: Filter[]
    grades: GradeFilter[]
  }
  parameters: ReturnedSearchParams
  searchId: number
}
