import type { SavedSearchesApiRequest } from '@/interfaces/savedSearches/savedSearchesApiRequest'

// TODO: Convert to LaunchDarkly flags
export const SAVED_SEARCH_FLAGS = {
  ENABLE_BULK_OPERATIONS: false,
  ENABLE_EMAIL_NOTIFICATIONS: false,
  ENABLE_RESULT_COUNTS: false,
}
export const savedSearchesUri = (id?: number) => {
  const basePath = '/api/user/savedSearches'
  let path = basePath
  if (id) {
    path = `${path}/${id}`
  }
  return path
}
export const DEFAULT_SAVED_SEARCHES_PARAMS: SavedSearchesApiRequest = {
  retrieveNewResultCounts: SAVED_SEARCH_FLAGS.ENABLE_RESULT_COUNTS,
}

export enum SavedSearchQueryKeys {
  GetSavedSearches = 'getSavedSearches',
  SavedSearch = 'savedSearchCRUD',
  SearchSuggestion = 'savedSearchSuggestions',
  GetAll = 'ALL',
}
