import type { SearchResultsData } from '@/interfaces/searchResult'
import type { SearchParams } from '@/interfaces/searchRequest'
import type { AvailabilityOptions, ListingFormatOptions } from '@/interfaces/filters'

import { createContext, type PropsWithChildren, useCallback, useContext, useRef } from 'react'
import { useQueryState, parseAsInteger, useQueryStates, parseAsString, parseAsStringLiteral } from 'nuqs'

import { ITEM_SORT } from '@/constants/sort'
import { DEFAULT_SEARCH_REQUEST_VALUES } from '@/constants'
import useSearch from '@/hooks/data/useSearch'

type SearchResultsContext = {
  searchResults?: SearchResultsData
  searchResultsLoading: boolean
  searchParams: SearchParams
  updateSearchParams: (newSearchParams: SearchParams) => void
}

const searchResultsContext = createContext<SearchResultsContext>({
  searchResults: undefined,
  searchResultsLoading: false,
  searchParams: DEFAULT_SEARCH_REQUEST_VALUES(),
  updateSearchParams: () => {},
})

export interface SearchResultsProviderProps extends PropsWithChildren {
  initialSearchParams: SearchParams
}

export default function SearchResultsProvider({
  initialSearchParams,
  children,
}: SearchResultsProviderProps): JSX.Element {
  // Special attr that is essentially a signal to make an API request to get the recent search
  // I haven't decided if this is the right approach but leaving it for now
  const [recentSearchID, setRecentSearchID] = useQueryState('recentSearchID')

  const [searchUrlParams, setSearchUrlParams] = useQueryStates(
    {
      searchTerm: parseAsString.withDefault(initialSearchParams.searchTerm),
      sort: parseAsStringLiteral(Object.values(ITEM_SORT)).withDefault(DEFAULT_SEARCH_REQUEST_VALUES(true).sort),
      pageNum: parseAsInteger.withDefault(1),
      attributes: parseAsString.withDefault('').withOptions({ clearOnDefault: true }),
      availability: parseAsStringLiteral(['Ignore', 'Include', 'OnlySoldOut'] as AvailabilityOptions[]).withDefault(
        initialSearchParams.availability
      ),
      listingFormat: parseAsStringLiteral(['All', 'Auction', 'BuyNow'] as ListingFormatOptions[]).withDefault(
        initialSearchParams.listingFormat
      ),
      playerId: parseAsInteger.withDefault(initialSearchParams.playerId!).withOptions({ clearOnDefault: true }),
      teamId: parseAsInteger.withDefault(initialSearchParams.teamId!).withOptions({ clearOnDefault: true }),
      ownerName: parseAsString.withDefault(initialSearchParams.ownerName!).withOptions({ clearOnDefault: true }),
      sportPath: parseAsString.withDefault(initialSearchParams.sportPath!).withOptions({ clearOnDefault: true }),
      gradeActionPath: parseAsString
        .withDefault(initialSearchParams.gradeActionPath!)
        .withOptions({ clearOnDefault: true }),
      graderPath: parseAsString.withDefault(initialSearchParams.graderPath!).withOptions({ clearOnDefault: true }),
      gradePath: parseAsString.withDefault(initialSearchParams.gradePath!).withOptions({ clearOnDefault: true }),
      decadePath: parseAsString.withDefault(initialSearchParams.decadePath!).withOptions({ clearOnDefault: true }),
      yearPath: parseAsString.withDefault(initialSearchParams.yearPath!).withOptions({ clearOnDefault: true }),
      parentSetPath: parseAsString
        .withDefault(initialSearchParams.parentSetPath!)
        .withOptions({ clearOnDefault: true }),
      setPath: parseAsString.withDefault(initialSearchParams.setPath!).withOptions({ clearOnDefault: true }),
      subSearch: parseAsString.withDefault(initialSearchParams.subSearch!).withOptions({ clearOnDefault: true }),
    },
    {
      history: 'push',
    }
  )

  const prevSearchID = useRef<string | null>(recentSearchID)

  const searchParams = {
    attributePaths: searchUrlParams.attributes,
    hitsPerPage: DEFAULT_SEARCH_REQUEST_VALUES().hitsPerPage,
    recentSearchID,
    ...searchUrlParams,
  }

  const { searchResults, searchResultsLoading } = useSearch({ searchParams })

  const updateSearchParams = useCallback(
    (newParams: SearchParams) => {
      if (!newParams) return

      prevSearchID.current = null
      setSearchUrlParams(newParams)
      setRecentSearchID(null)
    },
    [setSearchUrlParams, setRecentSearchID]
  )

  return (
    <searchResultsContext.Provider
      value={{
        searchParams,
        updateSearchParams,
        searchResults,
        searchResultsLoading,
      }}
    >
      {children}
    </searchResultsContext.Provider>
  )
}

export const useSearchResultsProvider = (): SearchResultsContext => useContext(searchResultsContext)
