import type { FC, HTMLAttributes, ReactNode } from 'react'

import { Favorite } from '@mui/icons-material'
import { autocompleteClasses, Typography, type AutocompleteRenderOptionState } from '@mui/material'

import { searchTermEquals } from '@/helpers/savedSearches'
import { type SearchSuggestion, SearchSuggestionType } from '@/interfaces/autocompleteSearchOption'
import NewSavedSearchesChip from '@/components/ui/NewSavedSearchesChip'

type SearchBarAutocompleteOptionProps = {
  props: HTMLAttributes<HTMLLIElement>
  option: SearchSuggestion
  state: AutocompleteRenderOptionState
}

/** Simple text highlighter, styles controlled by the `highlight` class */
function highlight(value: string, matcher: RegExp) {
  let segments: ReactNode[] = [<span key="nohighlight">{value}</span>]
  const matches = Array.from(value.matchAll(matcher))
  if (matches) {
    segments = []
    let matchStart = 0
    matches.forEach((match, index) => {
      const highlightKey = `highlight-match-${index}`
      const noHighlightKey = `nohighlight-match-${index}`
      const matchIndex = match.index || 0
      const chomp = value.slice(matchStart, matchIndex)
      if (matchStart < matchIndex && chomp.length > 0) {
        segments.push(<span key={noHighlightKey}>{chomp}</span>)
      }
      segments.push(
        <span key={highlightKey} className="highlight">
          {match[0]}
        </span>
      )
      matchStart = matchIndex + match[0].length
    })
    if (matchStart < value.length) {
      segments.push(<span key="end">{value.slice(matchStart)}</span>)
    }
  }

  return segments
}

const SearchBarAutocompleteOption: FC<SearchBarAutocompleteOptionProps> = ({ props, option, state }) => {
  const isSavedSearch = option.type === SearchSuggestionType.SAVED_SEARCH
  // const isRecentSearch = option.type === SearchSuggestionType.RECENT_SEARCH
  const isActiveSearch = state.selected || searchTermEquals(option.params.searchTerm, state.inputValue)
  // eslint-disable-next-line no-useless-escape, prefer-regex-literals
  const escapeRegex = new RegExp(/([\[\]\(\)])/, 'ig')
  const escaped = state.inputValue.trim().replaceAll(escapeRegex, '\\$1')
  const matcher = new RegExp(escaped, 'ig')

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <li
      {...props}
      aria-selected={isActiveSearch && !isSavedSearch}
      className={autocompleteClasses.option}
      value={isSavedSearch ? option.id : option.params.searchTerm}
      data-test-class={`autocomplete-option-${option.type}`}
    >
      {isSavedSearch && <Favorite key="icon" />}
      <Typography key="label" className="label">
        {isSavedSearch ? option.title : highlight(option.title, matcher)}
      </Typography>
      {/** TODO: Add click handler to remove recent searches EN-2195 */}
      {isSavedSearch && option.id && <NewSavedSearchesChip savedSearchId={option.id} />}
    </li>
  )
}

export default SearchBarAutocompleteOption
