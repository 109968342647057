import styled from '@emotion/styled'
import { Dialog } from '@mui/material'

import { DIALOG_TYPES } from '@/interfaces/dialogTypes'

interface DialogProps {
  type?: DIALOG_TYPES
}

const BaseDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'type',
})<DialogProps>(({ type = DIALOG_TYPES.CONFIRMATION }) => ({
  '& .MuiPaper-root': {
    borderRadius: '24px',
    backgroundColor: type === DIALOG_TYPES.SUCCESS ? '#1C8F57' : '',
  },
}))

export default BaseDialog
