import type { OfferHistoryItem } from '@/interfaces/offer/offerHistory'

import { OFFER_STATUS } from '@/constants/offer'

export const isOfferWaitingOnMe = (offer: OfferHistoryItem) => {
  if (offer.offerStatus !== OFFER_STATUS.OPEN) {
    return false
  }
  return offer.isOfferWaitingOnMe
}
