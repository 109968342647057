import type { BasicAPIResponse } from '@/interfaces/api/basicAPIResponse'

import { type FC, useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { signOut } from 'next-auth/react'
import { sanitize } from 'isomorphic-dompurify'

import BaseDialog from '@/components/ui/baseComponents/BaseDialog'
import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import PageLoader from '@/components/layout/PageLoader'
import { useUserProvider } from '@/store/userProvider'
import useUserAgreement from '@/hooks/data/useUserAgreement'
import { STRINGS } from '@/constants'

type Props = {
  modalState: boolean
}

const TermsAndConditionsModal: FC<Props> = ({ modalState }) => {
  const { fetchUserSummary, isUserSummaryLoading } = useUserProvider()
  const { userAgreement, userAgreementLoading } = useUserAgreement()

  const acceptUserAgreement = useCallback(
    async () => clientAPIRequest<BasicAPIResponse, unknown>('/api/user/agreement', RequestMethods.PUT),
    []
  )

  const { isPending: acceptUserAgreementLoading, mutate: acceptUserAgreementQuery } = useMutation({
    mutationFn: () => acceptUserAgreement(),
    onSuccess(data) {
      if (data.success) {
        fetchUserSummary()
      }
    },
  })

  return (
    <BaseDialog open={modalState}>
      <DialogTitle id="terms-conditions-dialog-title" textAlign="center" variant="h3">
        {STRINGS.TERMS_CONDITIONS_LABEL}
      </DialogTitle>
      <DialogContent sx={{ mx: 2, mb: 2 }}>
        {userAgreementLoading || !userAgreement ? (
          <Box position="relative">
            <PageLoader />
          </Box>
        ) : (
          <Box>
            <Typography
              dangerouslySetInnerHTML={{
                __html: sanitize(userAgreement),
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        <Button onClick={() => signOut({ callbackUrl: '/' })}>{STRINGS.SIGN_OUT_ACTION}</Button>
        <LoadingButton
          loading={acceptUserAgreementLoading || isUserSummaryLoading}
          onClick={() => {
            acceptUserAgreementQuery()
          }}
          variant="contained"
        >
          {STRINGS.AGREE_ACTION}
        </LoadingButton>
      </DialogActions>
    </BaseDialog>
  )
}

export default TermsAndConditionsModal
