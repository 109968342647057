import type { Option } from '@/interfaces/controls'
import type { AvailabilityOptions, ListingFormatOptions } from '@/interfaces/filters'

export const availabilityOptionToText = (option: AvailabilityOptions) => {
  switch (option) {
    case 'Ignore':
      return 'Available'
    case 'OnlySoldOut':
      return 'Sold Out'
    case 'Include':
    default:
      return 'All Items'
  }
}

export const availabilityOptionToLabel = (option: AvailabilityOptions) => {
  switch (option) {
    case 'Ignore':
      return 'Only display items available to purchase'
    case 'OnlySoldOut':
      return 'Only display items that are sold out'
    case 'Include':
    default:
      return 'Display all items whether they are in stock or not'
  }
}

export const listingFormatOptionToText = (option: ListingFormatOptions) => {
  switch (option) {
    case 'BuyNow':
      return 'Buy Now'
    case 'Auction':
      return 'Auctions'
    case 'All':
    default:
      return 'All Items'
  }
}

export const listingFormatOptionToLabel = (option: ListingFormatOptions) => {
  switch (option) {
    case 'BuyNow':
      return 'Only display items available to purchase now'
    case 'Auction':
      return 'Only display items that are available for auction'
    case 'All':
    default:
      return 'Display all items whether they are in available to buy now or up for auction'
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumOptionsToOptionsControl = (options: any, ariaLabelCb: (option: any) => any): Option[] => {
  return Object.keys(options).map((option) => {
    return {
      text: options[option as keyof typeof options],
      value: option,
      ariaLabel: ariaLabelCb(options[option as keyof typeof options]),
    }
  })
}
