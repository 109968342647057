import type { Session } from 'next-auth'
import type { Nullable } from '@/types/utility'
import type { ErrorMessage } from '@/interfaces/api/errors'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import { HTTP_STATUS } from '@/interfaces/api/http'

const middlewareAPIURL = () => {
  if (process.env.NEXT_PUBLIC_MIDDLEWARE_API_BASE_URL) {
    return process.env.NEXT_PUBLIC_MIDDLEWARE_API_BASE_URL
  } else if (process.env.NEXT_PUBLIC_APP_ENV) {
    switch (process.env.NEXT_PUBLIC_APP_ENV) {
      case 'development':
        return 'https://beta.dev.comc.com'
      case 'test':
        return 'https://beta.test.comc.com'
      case 'production':
        return 'https://beta.comc.com'
    }
  } else {
    return window.location.origin
  }
}

export async function clientAPIRequest<T, U>(
  url: string,
  method: RequestMethods,
  requestData?: U,
  session?: Nullable<Session>
): Promise<T> {
  try {
    const { data } = await fetch(`${middlewareAPIURL()}${url}`, {
      method: RequestMethods.POST,
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        method,
        data: requestData,
        session,
      }),
      mode: 'same-origin',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then((responseData) => {
        return responseData
      })
    return data as T
  } catch (e) {
    throw new Error(`Failed to fetch ${url}. Reason: ${e}`, { cause: e })
  }
}

// TODO: make this more flexible as the API doesn't consistently return the same statusCode and body
export const directAPIRequest = async (
  acceptedMethods: RequestMethods[],
  url: string,
  requiresAuthentication = false,
  body?: { method: RequestMethods; data?: unknown; session?: Nullable<Session>; accessToken?: string }
) => {
  if (!body) {
    const respBody = {
      error: {
        statusCode: HTTP_STATUS.BAD_REQUEST,
        message: 'Unable to complete request: no body present. Expected: { method, data? }',
      },
    }
    console.error(respBody.error.message)
    throw respBody.error.message
  }

  const { method, data, session, accessToken } = body
  if (!acceptedMethods.some((acceptedMethod) => acceptedMethod === method)) {
    throw HTTP_STATUS.METHOD_NOT_ALLOWED
  }

  const apiAccessToken = session?.accessToken || accessToken

  if (requiresAuthentication && !apiAccessToken) {
    try {
      if (!session && !apiAccessToken) {
        throw new Error('Unable to get session.')
      }

      if (!apiAccessToken) {
        throw new Error('Unable to get access token from session or request.')
      }
    } catch (e) {
      throw new Error('Error retrieving access token from session or request.', { cause: e })
    }
  }

  const requestOptions: RequestInit = {
    method,
  }
  if (apiAccessToken) {
    requestOptions.headers = {
      Authorization: `Bearer ${apiAccessToken}`,
    }
  }

  if (method !== RequestMethods.GET) {
    requestOptions.headers = {
      ...requestOptions.headers,
      'Content-Type': 'application/json',
    }
    if (method !== RequestMethods.PUT || (RequestMethods.PUT && !!data)) {
      requestOptions.body = JSON.stringify(data)
    }
  }

  // TODO: make this more flexible as the API doesn't consistently return the same statusCode and body
  //       for the same HTTP verbs across resources
  const makeAPICall = async (): Promise<ErrorMessage | boolean> => {
    return await fetch(`${url}`, requestOptions)
      .then((response) => {
        // NOTE: Not all success responses are 200
        if (response.status >= 400) {
          return {
            statusCode: response.status,
            // TODO: HTTP2 and later does not support status messages
            message: response.statusText,
          }
        }

        const contentType = response.headers.get('content-type')
        return contentType && contentType.indexOf('application/json') !== -1 ? response.json() : response.text()
      })
      .catch((e) => {
        throw new Error('Error making api call', { cause: e })
      })
  }

  return new Promise((resolve: (value?: unknown | PromiseLike<unknown>) => void) => {
    try {
      makeAPICall().then((data) => {
        try {
          if (typeof data === 'string') {
            resolve(data)
          } else if (typeof data === 'boolean') {
            if (!data) {
              resolve(data)
            } else {
              resolve()
            }
          } else if ('statusCode' in data) {
            throw new Error()
          } else {
            resolve(data)
          }
        } catch (e) {
          const err = new Error('Unable to complete makeAPICall', { cause: e })
          console.error(err.message, err)
        }
      })
    } catch (e) {
      console.error('Unable to complete API fetch:', e)
      resolve({ error: e })
    }
  })
}
