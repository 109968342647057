import type { InventoryItem } from '@/interfaces/item/productDetailsResult'

import { useEffect, useState } from 'react'

const useItemState = (item: InventoryItem | undefined) => {
  const [isItemOwned, setIsItemOwned] = useState(false)
  const [isItemForSale, setIsItemForSale] = useState(true)

  useEffect(() => {
    if (item) {
      const isOwned = item?.owned
      const isForSale = item?.isForSale
      setIsItemOwned(isOwned)
      setIsItemForSale(isForSale)
    }
  }, [item])

  return {
    isItemOwned,
    isItemForSale,
  }
}

export default useItemState
