import type { ParsedUrlQuery } from 'querystring'
import type { ProductSearchParams } from '@/interfaces/item/productRequest'

import { reduceQueryParam } from './urlParser'

export const urlToProductSearchParams = (query: ParsedUrlQuery): ProductSearchParams | undefined => {
  const productSearchParams: ProductSearchParams = {}
  if (query.itemId) {
    productSearchParams.itemId = parseInt(reduceQueryParam(query.itemId))
  }
  if (query.gradeId) {
    productSearchParams.gradeId = parseInt(reduceQueryParam(query.gradeId))
  }
  if (query.productId) {
    productSearchParams.productId = parseInt(reduceQueryParam(query.productId))
  }
  if (query.ownerId) {
    productSearchParams.ownerId = reduceQueryParam(query.ownerId)
  }
  if (query.auctionId) {
    productSearchParams.auctionId = reduceQueryParam(query.auctionId)
  }
  return productSearchParams
}
