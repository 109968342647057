import { type FC, useRef, useState, useEffect } from 'react'
import { Chip, CircularProgress, type ChipProps } from '@mui/material'

import { useSavedSearchesProvider } from '@/store/savedSearchesProvider'
import Row from './layout/Row'

interface NewSavedSearchesChipProps extends Omit<ChipProps, 'label'> {
  savedSearchId: number
}

const NewSavedSearchesChip: FC<NewSavedSearchesChipProps> = ({ savedSearchId, ...props }) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const { savedSearchCount, isSavedSearchCountLoading, isRefetchingSavedSearch } = useSavedSearchesProvider()
  const [newResultCount, setNewResultCount] = useState(savedSearchCount[savedSearchId] || 0)

  useEffect(() => {
    setNewResultCount(savedSearchCount[savedSearchId] || 0)
  }, [savedSearchCount, savedSearchId])

  return (
    <div ref={elementRef}>
      {!newResultCount && !isSavedSearchCountLoading ? null : (
        <Row spacing={1} alignItems="center">
          {((!newResultCount && isSavedSearchCountLoading) || isRefetchingSavedSearch) && (
            <CircularProgress size={20} color="secondary" />
          )}
          {savedSearchCount && savedSearchCount[savedSearchId] && savedSearchCount[savedSearchId] !== 0 && (
            <Chip color="textBlue" size="small" label={newResultCount > 99 ? '99+' : newResultCount} {...props} />
          )}
        </Row>
      )}
    </div>
  )
}

export default NewSavedSearchesChip
