import type { ContactInformation } from '@/interfaces/user/contactInformation'

export const isAddressEntered = (address?: ContactInformation): boolean => {
  if (!address) {
    return false
  }
  const { firstName, lastName, address1, city, state, countryCode, postalCode, phoneNumber } = address
  const isAddressValid = !!(
    firstName &&
    lastName &&
    address1 &&
    city &&
    state &&
    countryCode &&
    postalCode &&
    phoneNumber
  )

  return isAddressValid
}

export function formatPhoneNumber(phoneNumber: string = ''): string {
  if (!phoneNumber) return ''

  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return ''
}
