import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'

import { CARD_ATTRIBUTES } from '@/interfaces/searchResult'

export const getRookieDesignationColor = (rookieDesignation: CARD_ATTRIBUTES): string => {
  switch (rookieDesignation) {
    case CARD_ATTRIBUTES.PRE_ROOKIE_CARD:
      return '#156639' // 'brandGreen.main'
    case CARD_ATTRIBUTES.ROOKIE_CARD:
      return 'primary.main'
    case CARD_ATTRIBUTES.ROOKIE_YEAR:
      // TODO: The contrast of this color is too low with white text
      return 'brandOrange.main'
    default:
      return 'brandBlue.main'
  }
}

export const formatAuctionEndTime = (auctionEndDate?: string, shortForm = true, remainingTime?: string) => {
  // TODO: Will probably have to come back to this after dateTime UTC is updated
  if (!auctionEndDate) {
    return ''
  }
  const endDate = new Date(auctionEndDate)
  const now = new Date()
  const days = differenceInDays(endDate, now)
  if (days >= 1 && shortForm) {
    return `${days}d${remainingTime ? ` ${remainingTime}` : ''}`
  }

  const hours = differenceInHours(endDate, now)
  if (hours >= 1 && shortForm) {
    return `${hours}h${remainingTime ? ` ${remainingTime}` : ''}`
  }

  const minutes = differenceInMinutes(endDate, now)
  if (minutes >= 1 && shortForm) {
    return `${minutes}m${remainingTime ? ` ${remainingTime}` : ''}`
  }

  if (minutes <= 0) {
    return 'ended'
  }

  return `${days ? `${days}d` : ''} ${hours ? `${hours - days * 24}h` : ''} ${minutes - hours * 60}m${
    remainingTime ? ` ${remainingTime}` : ''
  }`
}
