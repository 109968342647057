// TODO: Make an exhaustive list, replace all url strings with these constants

// General requests
export const browseCategoriesListURL = '/api/search/browseList'
export const searchURL = '/api/search'
export const userAgreementURL = '/api/user/agreement'
export const validShippingCountriesURL = `/api/validShippingCountries`
export const cardIssuesURL = '/api/corrections/issueOptions'

// Cart related requests
export const baseCartURL = (cartId: string) => `/api/cart/${cartId}`
export const getCartSummaryURL = (cartId: string) => `${baseCartURL(cartId)}/summary`

// User Information requests
export const contactInformationURL = '/api/user/contactInformation'
export const userSummaryURL = '/api/user'
export const userPurchaseHistoryURL = '/api/user/transaction/purchases'
export const userSalesHistoryURL = '/api/user/transaction/sales'
export const userWatchlistURL = '/api/user/watchlist'

// Offer related requests
export const offerSummaryURL = (offerId: number) => `/api/user/offer/${offerId}`
export const submitOfferURL = '/api/user/offer/action/submit'
export const cancelOfferURL = '/api/user/offer/action/cancel'
export const rejectOfferURL = '/api/user/offer/action/reject'
export const acceptOfferURL = '/api/user/offer/action/accept'
export const counterOfferURL = '/api/user/offer/action/counter'
export const pendingOffersSummaryURL = '/api/user/offer/pending'
export const offerConversationURL = '/api/user/offer/conversation'
export const offerItemsURL = '/api/user/offer/items'
export const offerHistoryURL = '/api/user/offer/history'
export const potentialOfferItemDetailsURL = '/api/user/offer/details'

// Processing Service related
export const getAuctionOptionsURL = '/api/inventory/getAuctionOptions'
export const submitItemToAuctionURL = '/api/inventory/submitToAuction'

// Purchase Related
export const userCreditRequestURL = '/api/user/credit/request'
export const shoppingCartInstantPurchaseURL = '/api/instantPurchase/shoppingCart'
export const itemInstantPurchaseURL = '/api/instantPurchase/item'
export const baseInventoryURL = '/api/inventory'
export const braintreeTokenURL = '/api/payments/token'

// Inventory related
export const userInventoryURL = '/api/inventory'
export const inventoryItemURL = '/api/inventory/item'
export const matchingItemsForSaleURL = '/api/inventory/matchingItemsForSale'
export const ownedMatchingItemsForSaleURL = '/api/inventory/ownedMatchingItems'
export const itemManagementDetailsURL = '/api/inventory/managementDetails'

// Invoice related
export const invoiceItemList = (invoiceId: string) => `/api/invoice/${invoiceId}/list`
export const invoiceOrderStatusURL = (invoiceId: string) => `/api/invoice/${invoiceId}/orderStatus`
export const invoiceSummaryURL = (invoiceId: string) => `/api/invoice/${invoiceId}/summary`

// Dashboard related
export const gradingHistoryURL = '/api/inventory/gradingHistory'

// Item action related
export const reportCorrectionURL = '/api/corrections/report'
