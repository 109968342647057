import type {
  RouteChangeErrorHandler,
  RouteChangeHandler,
  RouteOptions,
  RouterError,
  RouterEvent,
  RouterEventHandler,
} from 'next/router'
import type { MittEmitter } from 'next/dist/shared/lib/mitt'

export type RouterEventEntry = {
  event: RouterEvent
  handler: RouteChangeHandler | RouteChangeErrorHandler
}

export type CreateRouterEventHandlerParams = { url: string; options: RouteOptions; err?: RouterError }
export type CreateRouterEventHandler = (params: CreateRouterEventHandlerParams) => void
export function createRouterEventHandler(event: RouterEvent, handler: CreateRouterEventHandler): RouterEventEntry {
  if (event === 'routeChangeError') {
    const cb: RouterEventHandler<'routeChangeError'> = (err, url, options) => {
      const input = { err, url, options }
      if (process.env.APP_ENV !== 'production') {
        console.error('There was an error while routing: ', { ...input })
      }
      handler(input)
    }
    return {
      event,
      handler: cb,
    }
  } else {
    const cb: RouterEventHandler<typeof event> = (url, options) => {
      handler({ url, options })
    }
    return {
      event,
      handler: cb,
    }
  }
}

export function registerRouterEventHandlers(
  eventEmitter: MittEmitter<RouterEvent>,
  routerEventEntries: Array<RouterEventEntry>
) {
  routerEventEntries.forEach(({ event, handler }) => {
    eventEmitter.on(event, handler)
  })
}

export function unregisterRouterEventHandlers(
  eventEmitter: MittEmitter<RouterEvent>,
  routerEventEntries: Array<RouterEventEntry>
) {
  routerEventEntries.forEach(({ event, handler }) => {
    eventEmitter.off(event, handler)
  })
}
