import type { UserSummary } from '@/interfaces/user/summary'

import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { userQueryKeys } from '@/interfaces/user/userQueries'
import { userSummaryURL } from '@/constants/middlewareApiURLs'
import useAuthState from '@/hooks/useAuthState'

const useUserSummary = () => {
  const { isAuthenticated } = useAuthState()

  const getUserSummary = useCallback(
    async () => clientAPIRequest<UserSummary, unknown>(userSummaryURL, RequestMethods.GET),
    []
  )

  const {
    data: userSummary,
    isLoading: isUserSummaryLoading,
    ...props
  } = useQuery({
    queryKey: [userQueryKeys.GetUserSummary],
    queryFn: () => getUserSummary(),
    enabled: isAuthenticated,
  })

  const needsActivation = userSummary?.needsActivation

  return { userSummary, isUserSummaryLoading, needsActivation, ...props }
}

export default useUserSummary
