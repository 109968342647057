import type { StoreCreditCheckoutParams } from '@/interfaces/user/storeCredit/storeCreditChekout'
import type { CreateOrderRequestBody } from '@paypal/paypal-js'

import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { StoreCreditQueryKeys } from '@/interfaces/user/storeCredit/queryKeys'
import { userCreditRequestURL } from '@/constants/middlewareApiURLs'

const useStoreCreditPaypalOrder = (storeCreditCheckoutParams?: StoreCreditCheckoutParams) => {
  const getStoreCreditPaypalOrder = useCallback(async (params: StoreCreditCheckoutParams) => {
    return clientAPIRequest<CreateOrderRequestBody, StoreCreditCheckoutParams>(
      userCreditRequestURL,
      RequestMethods.GET,
      params
    )
  }, [])

  const {
    isLoading: isStoreCreditPaypalOrderLoading,
    data: storeCreditPaypalOrder,
    refetch: fetchStoreCreditPaypalOrder,
  } = useQuery({
    queryKey: [StoreCreditQueryKeys.GET_STORE_CREDIT_PAYPAL_ORDER, storeCreditCheckoutParams],
    queryFn: () => getStoreCreditPaypalOrder(storeCreditCheckoutParams as StoreCreditCheckoutParams),
    enabled: false,
  })

  return { isStoreCreditPaypalOrderLoading, storeCreditPaypalOrder, fetchStoreCreditPaypalOrder }
}

export default useStoreCreditPaypalOrder
