import type { ParsedUrlQuery } from 'querystring'
import type { ITEM_SORT } from '@/constants/sort'
import type { SearchParams } from '@/interfaces/searchRequest'

import { parseUrl } from 'next/dist/shared/lib/router/utils/parse-url'

import { DEFAULT_SEARCH_REQUEST_VALUES } from '@/constants'
import { reduceQueryParam } from './urlParser'

export const urlToSearchParams = (urlOrQuery: ParsedUrlQuery | string): SearchParams => {
  const query = typeof urlOrQuery === 'string' ? parseUrl(urlOrQuery).query : (urlOrQuery as ParsedUrlQuery)
  const { searchTerm, pageNum, availability, listingFormat, hitsPerPage } = query
  const searchParams = {
    searchTerm: searchTerm ? reduceQueryParam(searchTerm) : '',
    pageNum: pageNum ? parseInt(reduceQueryParam(pageNum)) : 1,
    hitsPerPage: hitsPerPage ? parseInt(reduceQueryParam(hitsPerPage)) : 100,
    availability: availability ? reduceQueryParam(availability) : DEFAULT_SEARCH_REQUEST_VALUES().availability,
    listingFormat: listingFormat ? reduceQueryParam(listingFormat) : DEFAULT_SEARCH_REQUEST_VALUES().listingFormat,
  } as SearchParams

  if (query.sportPath) {
    searchParams.sportPath = reduceQueryParam(query.sportPath)
  }
  if (query.subSearch) {
    searchParams.subSearch = reduceQueryParam(query.subSearch)
  }
  if (query.playerId) {
    searchParams.playerId = parseInt(reduceQueryParam(query.playerId))
  }
  if (query.teamId) {
    searchParams.teamId = parseInt(reduceQueryParam(query.teamId))
  }
  if (query.ownerName) {
    searchParams.ownerName = reduceQueryParam(query.ownerName)
  }
  if (query.attributes) {
    searchParams.attributePaths = reduceQueryParam(query.attributes)
  }
  if (query.gradeActionPath) {
    searchParams.gradeActionPath = reduceQueryParam(query.gradeActionPath)
  }
  if (query.graderPath) {
    searchParams.graderPath = reduceQueryParam(query.graderPath)
  }
  if (query.gradePath) {
    searchParams.gradePath = reduceQueryParam(query.gradePath)
  }
  if (query.decadePath) {
    searchParams.decadePath = reduceQueryParam(query.decadePath)
  }
  if (query.yearPath) {
    searchParams.yearPath = reduceQueryParam(query.yearPath)
  }
  if (query.parentSetPath) {
    searchParams.parentSetPath = reduceQueryParam(query.parentSetPath)
  }
  if (query.setPath) {
    searchParams.setPath = reduceQueryParam(query.setPath)
  }
  if (query.sort) {
    searchParams.sort = reduceQueryParam(query.sort) as ITEM_SORT
  }
  if (query.recentSearchID) {
    searchParams.recentSearchID = reduceQueryParam(query.recentSearchID)
  }
  return searchParams
}
