import { useRouter } from 'next/router'
import { type FC, useEffect } from 'react'
import { signOut, useSession } from 'next-auth/react'
import { Button, CircularProgress, useMediaQuery } from '@mui/material'

import { STRINGS } from '@/constants'
import ProfileMenu from './ProfileMenu'
import { REFRESH_TOKEN_ERROR } from '@/interfaces/api/errors'
import { DASHBOARD_BASE_HREF } from '@/constants/dashboard'
import { NextAuthStatuses } from '@/interfaces/auth/nextAuth'
import theme from '@/themes/comcTheme'
import useSignIn from '@/hooks/useSignIn'

const AuthNavItem: FC = () => {
  const { data: session, status } = useSession()
  const { signIn } = useSignIn()
  const router = useRouter()
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (session?.error === REFRESH_TOKEN_ERROR) {
      signOut({ callbackUrl: '/' }).then(() => Promise.resolve())
    }
  }, [session])

  if (status === NextAuthStatuses.LOADING) {
    return <CircularProgress size={16} />
  }

  if (session) {
    return <ProfileMenu />
  }

  return (
    <Button
      sx={{ whiteSpace: 'nowrap' }}
      onClick={() =>
        signIn(
          { alternateCallbackUrl: router.asPath === '/' ? DASHBOARD_BASE_HREF : undefined }
        )
      }
      color="primary"
      variant="contained"
      data-test-id="sign-in-up-button"
    >
      {isSmallScreenSize ? STRINGS.SIGN_IN_MOBILE_BUTTON : STRINGS.SIGN_IN_BUTTON}
    </Button>
  )
}

export default AuthNavItem
