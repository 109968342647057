import type { Option } from '@/interfaces/controls'

import isEmpty from 'lodash/isEmpty'

export const pluralize = (amount: number, word: string, excludeZero = false): string => {
  const pluralCondition = excludeZero ? amount > 1 || amount === 0 : amount > 1
  return pluralCondition ? `${word}s` : word
}

export const hasNoWhiteSpace = (str: string): boolean => {
  const reg = /^\S+$/
  return reg.test(str)
}

export const formatCount = (count: number) => {
  return new Intl.NumberFormat().format(count)
}

export const enumToOptions = (
  object: { [selector: string]: string },
  overrideTextValues?: { [selector: string]: string }
): Option[] => {
  return Object.values(object).map((option) => {
    return {
      text:
        overrideTextValues && overrideTextValues[option]
          ? overrideTextValues[option]
          : option
              .split(/(?=[A-Z])/)
              .join(' ')
              .trim(),
      value: option,
    }
  })
}

export const isValueInEnum = (str: string, obj: { [selector: string]: string }) => {
  return Object.values(obj)
    .map((val) => val.toLowerCase())
    .includes(str.toLowerCase())
}

export const stripToNull = (str?: string | null) => {
  let stripped: string | null = null
  if (isNotBlank(str)) {
    stripped = str?.trim() || null
  }
  return stripped
}

export const strip = (str?: string | null, fallback = ''): string => {
  return stripToNull(str) || fallback
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNotBlank = (value?: any): boolean => {
  return !isBlank(value)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Similar to lodash's {@linkcode isEmpty}, but also handles primitive values.
 *
 * A value is considered "blank" if:
 * - any type: value is `null` or `undefined`
 *   - this is the only test performed for `boolean`, `bigint`, `symbol`, and `function` values.)
 *
 * AND
 *
 * - `number`: *if and only if* it is `NaN`
 * - `string`: all whitespace or stringified 'null' or 'undefined' (case insensitive)
 * - all other types: call lodash's {@linkcode isEmpty}
 *
 * @see https://lodash.com/docs/#isEmpty
 */
export function isBlank(value?: any): value is null | undefined | '' | 'undefined' | 'null' {
  let blank = true
  const typ = typeof value
  if (value !== null || value !== undefined || typ !== 'undefined') {
    switch (typ) {
      case 'boolean':
      case 'bigint':
      case 'symbol':
      case 'function':
        blank = false
        break
      case 'number':
        blank = Number.isNaN(value)
        break
      case 'string':
        blank = /^(undefined|null|\s*)$/i.test(value.trim())
        break
      default:
        // isEmpty considers dates to be empty
        if (value instanceof Date) {
          blank = false
        } else {
          blank = isEmpty(value)
        }
        break
    }
  }
  return blank
}
/* eslint-enable @typescript-eslint/no-explicit-any */
