import type { FC } from 'react'

import Link from 'next/link'
import Image from 'next/image'
import { Box } from '@mui/material'

import NavigationItems from './NavigationItems'
import SearchBar from './SearchBar'
import BrowseMenu from './BrowseMenu'

const DesktopNavigation: FC = () => {
  return (
    <>
      <Link href="/" shallow={false}>
        <Image width={110} height={45} src="/assets/logo.png" alt="COMC logo" />
      </Link>

      <Box sx={{ flexGrow: 1 }} />
      <BrowseMenu />
      <Box sx={{ flexGrow: 1 }} />
      <SearchBar />
      <Box sx={{ flexGrow: 1 }} />
      <NavigationItems />
    </>
  )
}

export default DesktopNavigation
