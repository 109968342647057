import type { ItemManagementDetails } from '@/interfaces/inventory/inventoryItemManagementDetails'
import type { UserInventoryItem } from '@/interfaces/inventory/inventoryItemResults'
import type { InventoryItem, ProductDetailsResults } from '@/interfaces/item/productDetailsResult'

import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

import useItemState from '@/hooks/useItemState'
import useItemManagementDetails from '@/hooks/data/useItemManagementDetails'
import useInventoryItem from '@/hooks/data/useInventoryItem'

type ItemDetailsContext = {
  item?: InventoryItem
  setItem: Dispatch<SetStateAction<InventoryItem | undefined>>
  userInventoryItem?: UserInventoryItem
  productDetailsByItemId?: ProductDetailsResults
  setProductDetailsByItemId: Dispatch<SetStateAction<ProductDetailsResults | undefined>>
  isItemOwned: boolean
  isItemForSale: boolean
  managementDetails?: ItemManagementDetails
}

const itemDetailsContext = createContext<ItemDetailsContext>({
  item: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setItem: () => {},
  userInventoryItem: undefined,
  productDetailsByItemId: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setProductDetailsByItemId: () => {},
  isItemOwned: false,
  isItemForSale: true,
  managementDetails: {} as ItemManagementDetails,
})

export default function ItemDetailsProvider({ children }: PropsWithChildren): JSX.Element {
  const [item, setItem] = useState<InventoryItem | undefined>(undefined)
  const [productDetailsByItemId, setProductDetailsByItemId] = useState<ProductDetailsResults | undefined>(undefined)
  const { isItemOwned, isItemForSale } = useItemState(item)
  const { managementDetails } = useItemManagementDetails(isItemOwned, item?.itemId)
  const { userInventoryItem } = useInventoryItem(isItemOwned, item?.itemId)

  const itemDetailsValue = useMemo(
    () => ({
      item,
      setItem,
      userInventoryItem,
      productDetailsByItemId,
      setProductDetailsByItemId,
      isItemOwned,
      isItemForSale,
      managementDetails,
    }),
    [item, userInventoryItem, productDetailsByItemId, isItemOwned, isItemForSale, managementDetails]
  )

  return <itemDetailsContext.Provider value={itemDetailsValue}>{children}</itemDetailsContext.Provider>
}

export const useItemDetailsProvider = (): ItemDetailsContext => useContext(itemDetailsContext)
