import type { FC } from 'react'

import { Box, Fab, Fade, useScrollTrigger } from '@mui/material'
import { KeyboardArrowUp } from '@mui/icons-material'

import useScrollToTop from '@/hooks/useScrollToTop'

const ScrollToTop: FC = () => {
  const { scrollToTopHandler } = useScrollToTop()

  const trigger = useScrollTrigger({
    target: typeof window !== 'undefined' ? window : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  return (
    <Fade in={trigger}>
      <Box onClick={scrollToTopHandler} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </Fade>
  )
}

export default ScrollToTop
