import type { FC } from 'react'

import { Button, Skeleton, Stack, Tooltip, Typography, type TypographyProps } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material'

import { formatCurrency } from '@/helpers'
import { useUserProvider } from '@/store/userProvider'
import { addCreditHref } from '@/constants/pageHrefs'
import { STRINGS } from '@/constants'

type AvailableStoreCreditProps = {
  fontVariant?: TypographyProps['variant']
  fontWeight?: string
  fontColor?: string
  includeLabel?: boolean
  addMore?: boolean
  disableText?: boolean
}

const AvailableStoreCredit: FC<AvailableStoreCreditProps> = ({
  fontVariant = 'body1',
  fontWeight = 'initial',
  fontColor = 'inherit',
  includeLabel = true,
  addMore = false,
  disableText = false,
}) => {
  const { fundsSummary, isFundsSummaryLoading, isFundsSummaryError } = useUserProvider()

  return (
    <Stack direction="row" spacing={1} alignItems="left">
      {includeLabel && !disableText && (
        <Typography color={fontColor} fontWeight={fontWeight} variant={fontVariant} fontSize={14} noWrap>
          {`${STRINGS.STORE_CREDIT_LABEL}: `}
        </Typography>
      )}
      {isFundsSummaryLoading && !isFundsSummaryError && (
        <Typography>
          <Skeleton variant="text" width={40} sx={{ fontSize: '1rem' }} />
        </Typography>
      )}
      {isFundsSummaryError && !isFundsSummaryLoading && (
        <Typography fontWeight={fontWeight}>{STRINGS.UNABLE_TO_LOAD_MESSAGE}</Typography>
      )}
      {!isFundsSummaryError && !isFundsSummaryLoading && fundsSummary && (
        <Tooltip title="Store Credit" disableHoverListener={includeLabel}>
          <Typography
            color={fontColor}
            fontWeight={fontWeight}
            variant={fontVariant}
            noWrap
            data-test-id="available-store-credit-amount"
          >
            {formatCurrency(fundsSummary.availableCredit)}
          </Typography>
        </Tooltip>
      )}
      {addMore && (
        <Button
          href={addCreditHref()}
          size="small"
          variant="text"
          style={{ justifyContent: 'left', alignItems: 'flex-start' }}
        >
          {STRINGS.ADD_MORE_BUTTON} <AddCircleOutline fontSize="small" sx={{ ml: 0.5 }} />
        </Button>
      )}
    </Stack>
  )
}

export default AvailableStoreCredit
