import type { UserInventoryItem } from '@/interfaces/inventory/inventoryItemResults'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'
import { inventoryItemURL } from '@/constants/apiURLs'

const useInventoryItem = (isItemOwned: boolean, itemId?: number) => {
  const { data: userInventoryItem } = useAuthenticatedQuery<UserInventoryItem>(
    [RequestMethods.GET],
    RequestMethods.GET,
    itemId ? inventoryItemURL(itemId.toString()) : '',
    {
      queryKey: ['getInventoryItemDetails', { itemId }],
      enabled: !!itemId && isItemOwned,
    }
  )

  return { userInventoryItem }
}

export default useInventoryItem
