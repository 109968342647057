import { accordionClasses, alertClasses, cardMediaClasses } from '@mui/material'
import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { Roboto } from 'next/font/google'

export const roboto = Roboto({
  weight: ['100', '300', '400', '500', '700', '900'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
})

const palette = {
  primary: {
    light: '#721218',
    main: '#A41B23',
    dark: '#b6484f',
    contrastText: '#FFF',
    hover: '#F4D3D5',
  },
  secondary: {
    light: '#555C88',
    main: '#29335B',
    dark: '#000C31',
    contrastText: '#EBEBEB',
  },
  text: {
    primary: '#131313',
    secondary: '#5F5F5F',
  },
  lightGrey: {
    main: '#EFF1F3',
    contrastText: '#131313',
  },
  lightModeNeutral: {
    main: '#D9D9D9',
  },
  mediumGrey: {
    main: '#5F5F5F',
    contrastText: '#FFF',
  },
  brandGreen: {
    light: 'rgb(28, 143, 87, .1)',
    main: '#1C8F47',
    contrastText: '#FFF',
  },
  brandBlue: {
    main: '#00139C',
    contrastText: '#FFF',
  },
  brandOrange: {
    main: '#cd8032',
    contrastText: '#FFF',
  },
  brandGray: {
    main: '#333',
    contrastText: '#CCC',
  },
  textSecondary: {
    main: 'rgba(0, 0, 0, 0.6)',
    contrastText: '#FFF',
  },
  textBlue: {
    main: '#1358B4',
    contrastText: '#FFF',
  },
  linkBlue: {
    main: '#1A73E9',
    contrastText: '#FFF',
  },
  error: {
    dark: 'rgba (255, 165, 168, 1)',
    main: 'rgba(248, 95, 74, 1)',
  },
  secondaryAction: {
    main: 'rgba(248, 95, 74, .2)',
    contrastText: '#F85F4A',
  },
  background: {
    paper: '#FFF',
    default: grey[50],
  },
  attention: {
    main: 'rgba(245, 243, 195, 0.45)',
    dark: 'rgba(245, 243, 195, 0.7)',
    contrastText: '#000',
    border: '#FFF847',
  },
}

const theme = createTheme({
  palette,
  typography: {
    fontFamily: roboto.style.fontFamily,
    h1: {
      fontSize: '3.5rem',
      lineHeight: '4.9rem',
    },
    h2: {
      fontSize: '3rem',
      lineHeight: '4.2rem',
    },
    h3: {
      fontSize: '2.5rem',
      lineHeight: '3.5rem',
    },
    h4: {
      fontSize: '2rem',
      lineHeight: '2.8rem',
    },
    h5: {
      fontSize: '1.75rem',
      lineHeight: '2.45rem',
    },
    h6: {
      lineHeight: '1.75rem',
    },
    subheading: {
      fontSize: '1.125rem',
      lineHeight: '1.575rem',
    },
    body1: {
      lineHeight: '1.4rem',
      letterSpacing: '0.02em',
    },
    body2: {
      lineHeight: '1.225rem',
      letterSpacing: '0.02em',
    },
    link: {
      fontSize: '.75rem',
      lineHeight: '1.05rem',
      cursor: 'pointer',
      color: palette.linkBlue.main,
    },
    caption1: {
      fontSize: '.75rem',
      lineHeight: '1.05rem',
      letterSpacing: '0.02em',
    },
    caption2: {
      fontSize: '.625rem',
      lineHeight: '.875rem',
      letterSpacing: '0.02em',
    },
    overline: {
      fontSize: '.563rem',
      lineHeight: '.7882rem',
      letterSpacing: '0.02em',
    },
    tradingCardHeadingSmall: {
      fontSize: '0.95rem',
      lineHeight: 'normal',
      fontWeight: 400,
    },
    tradingCardHeading: {
      fontSize: '1.05rem',
      fontWeight: 500,
      lineHeight: '18pt',
      letterSpacing: '0.01px',
    },
    tradingCardHeadingLarge: {
      fontSize: '28px',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '0.2px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      tablet: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAlert: {
      variants: [
        {
          props: { variant: 'banner' },
          style: {
            justifyContent: 'center',
            borderRadius: 0,
          },
        },
        {
          props: { variant: 'banner', color: 'attention' },
          style: {
            backgroundColor: palette.attention.dark,
            color: palette.attention.contrastText,
            border: '1px solid',
            borderColor: palette.attention.border,
          },
        },
        {
          props: { variant: 'dashboard' },
          style: {
            padding: '8px 20px',
            border: 8,
            [`& .${alertClasses.message}`]: {
              width: '100%',
            },
          },
        },
        {
          props: { variant: 'dashboard', color: 'attention' },
          style: {
            backgroundColor: palette.attention.dark,
          },
        },
        {
          props: { variant: 'dashboard', color: 'alert' },
          style: {
            border: '1px solid',
            borderColor: palette.primary.main,
            backgroundColor: palette.secondaryAction.main,
          },
        },
      ],
    },
    MuiCard: {
      // both of these variants are using the base styles from the outlined paper variant
      variants: [
        {
          props: { variant: 'inventory' },
          style: {
            width: 510,
            backgroundColor: '#FFF',
            color: '#131313',
            webkitTransition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            transation: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: '20px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            [`& .${cardMediaClasses.root}`]: {
              width: 165,
              height: 222,
            },
          },
        },
        {
          props: { variant: 'action' },
          style: {
            width: 423,
            backgroundColor: '#FFF',
            color: '#131313',
            webkitTransition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            transation: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: '20px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            [`& .${cardMediaClasses.root}`]: {
              width: 140,
              height: 187,
            },
          },
        },
      ],
    },
    MuiAccordion: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            [`&.${accordionClasses.root}`]: {
              borderRadius: 16,
              border: '1px solid',
              borderColor: palette.mediumGrey.main,
            },
          },
        },
      ],
    },
  },
})

export default theme
