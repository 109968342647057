import type { CartSummaries } from '@/interfaces/cart/cartSummary'

import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { useCallback } from 'react'

import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { cartQueryKeys } from '@/interfaces/cart/cartQueries'
import { DEFAULT_CART_SUMMARY } from '@/constants'
import { getCartSummaryURL } from '@/constants/middlewareApiURLs'
import { NextAuthStatuses } from '@/interfaces/auth/nextAuth'

// TODO: Need to move active Cart ID to a hook
const useCartSummary = () => {
  const { data: session, status } = useSession()
  let cartToUse = DEFAULT_CART_SUMMARY

  const getCartSummaries = useCallback(async () => {
    const anonymousUserToken = localStorage.getItem('comc:$anonCartToken')
    return clientAPIRequest<CartSummaries, unknown>(
      getCartSummaryURL(anonymousUserToken!),
      RequestMethods.GET,
      undefined,
      session
    )
  }, [session])

  const {
    isLoading: isCartSummaryLoading,
    isError: isCartSummaryError,
    refetch: fetchCartSummaries,
    data: cartSummaries,
  } = useQuery<CartSummaries>({
    queryKey: [cartQueryKeys.GetCartSummary],
    queryFn: getCartSummaries,
    enabled: !!(
      (status === NextAuthStatuses.UNAUTHENTICATED &&
        typeof localStorage !== 'undefined' &&
        localStorage.getItem('comc:$anonCartToken')) ||
      status === NextAuthStatuses.AUTHENTICATED
    ),
    initialData: { localCart: DEFAULT_CART_SUMMARY },
  })

  if (cartSummaries) {
    if (status === NextAuthStatuses.AUTHENTICATED) {
      if (cartSummaries.universalCart) {
        cartToUse = cartSummaries.universalCart
      } else {
        cartToUse = DEFAULT_CART_SUMMARY
      }
    } else {
      cartToUse = cartSummaries.localCart
    }
  }

  return { cartToUse, cartSummaries, isCartSummaryLoading, isCartSummaryError, fetchCartSummaries }
}

export default useCartSummary
