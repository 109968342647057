import type { RequestMethods } from '@/interfaces/api/requestMethods'

import { useSession } from 'next-auth/react'
import { type UseQueryOptions, useQuery, type QueryKey } from '@tanstack/react-query'

import { NextAuthStatuses } from '@/interfaces/auth/nextAuth'
import { directAPIRequest } from '@/helpers'
import useUserSummary from './data/useUserSummary'

const useAuthenticatedQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  acceptedRequestMethods: RequestMethods[],
  method: RequestMethods,
  url: string,
  props: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn'>,
  requestData?: unknown
) => {
  const { status, data: session } = useSession()
  const { needsActivation, isUserSummaryLoading } = useUserSummary()

  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...props,
    queryFn: async () => {
      const data = await directAPIRequest(acceptedRequestMethods, url, true, { method, session, data: requestData })
      return data as TQueryFnData
    },
    enabled:
      status === NextAuthStatuses.AUTHENTICATED &&
      (typeof needsActivation !== 'undefined' ? !needsActivation : false) &&
      !isUserSummaryLoading &&
      !!session,
  })
}

export default useAuthenticatedQuery
