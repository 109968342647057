import type { SearchParams } from '@/interfaces/searchRequest'
import type { SearchResultsData } from '@/interfaces/searchResult'

import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'

export interface UseSearchProps {
  searchParams: SearchParams
  staleTimeSeconds?: number
}

const useSearch = ({ searchParams, staleTimeSeconds = 60 * 5 }: UseSearchProps) => {
  const { data: session } = useSession()
  const router = useRouter()

  const {
    data: searchResults,
    isFetching: searchResultsLoading,
    ...props
  } = useQuery({
    queryKey: ['searchResults', searchParams],
    queryFn: () =>
      clientAPIRequest<SearchResultsData, unknown>('/api/search', RequestMethods.GET, searchParams, session),
    placeholderData: (prev: SearchResultsData | undefined) => prev,
    refetchOnWindowFocus: false,
    staleTime: 1000 * staleTimeSeconds,
    enabled: router.isReady,
  })

  return { searchResults, searchResultsLoading, ...props }
}

export default useSearch
