export const STRINGS = {
  PAGE_UNDER_DEVELOPMENT: 'This page is under development.',
  PLEASE_TRY_AGAIN_MESSAGE: 'Please try again.',
  TECHNICAL_ERROR_MESSAGE: 'We ran into a problem. Please try again.',
  INVENTORY_ERROR_MESSAGE: 'We ran into a problem getting your inventory.',
  PRICE_UPDATE_ERROR_MESSAGE: 'We ran into issue updating price, try again.',
  GRADING_SUBMISSIONS_ERROR_MESSAGE: 'We ran into a problem getting your submissions for grading.',
  PURCHASE_HISTORY_ERROR_MESSAGE: 'We ran into a problem getting your purchase history.',
  ACTIVE_OFFER_ERROR_MESSAGE: 'We ran into a problem getting your active offers.',
  PAST_OFFER_ERROR_MESSAGE: 'We ran into a problem getting your past offers.',
  CLOSED_OFFER_ERROR_MESSAGE: 'We ran into a problem getting your closed offers.',
  SALES_HISTORY_ERROR_MESSAGE: 'We ran into a problem getting your sales history.',
  WATCH_LIST_RETRIEVAL_ERROR_MESSAGE: 'We ran into a problem getting your watch list.',
  SAVED_SEARCHES_RETRIEVAL_ERROR_MESSAGE: 'We ran into a problem getting your saved searches.',
  PAYMENT_ISSUE_ERROR_MESSAGE: 'There was an issue with your payment, please try again.',
  TRANSACTION_LOADING_ERROR_MESSAGE: 'There was an issue loading these transactions. Please try again.',
  SEARCH_ERROR_MESSAGE: 'There was a problem saving your search. Please try again later',
  OFFER_PAGE_LOAD_ERROR_MESSAGE: 'There was an error loading this page of offers, please try again.',
  OFFER_TRANSACTIONS_LOAD_ERROR_MESSAGE: 'There was an error loaading the offer transactions, please try again.',
  PROMOTIONS_LOAD_ERROR_MESSAGE: 'There was an issue loading these promotions. Please try again.',
  NO_PROMOTIONS_AVAILABLE_PAGE_MESSAGE: 'There are no promotions available on this page.',
  USER_HAS_NO_ITEMS_MESSAGE: "You don't have any items.",
  USER_HAS_NO_WATCHED_ITEMS_MESSAGE: 'You have no watched items.',
  USER_HAS_MONOPOLY_ON_ITEM: 'You have a monopoly on this item!',
  USER_HAS_NOT_PURCHASED_ANY_ITEMS_MESSAGE: "You haven't purchased any items yet.",
  USER_HAS_NOT_SOLD_ANY_ITEMS_MESSAGE: "You haven't sold any items yet.",
  REQUEST_SHIPMENT_COMING_SOON_MESSAGE: 'Request shipment is only available on comc.com. Coming soon to the beta site!',
  UNABLE_FIND_PAGE_VERIFY_URL_MESSAGE: 'The specified page could not be found. Please verify that the URL is correct.',
  PURCHASE_ADD_CREDIT_DESCRIPTION_MESSAGE:
    'Add the items to your online inventory, and request a shipment when you are done shopping today, tomorrow, or in a month.',
  REMOVE_ITEM_FROM_CART_CONFIRMATION_MESSAGE: 'Are you sure you want to remove this item from your cart?',
  USER_SHOPPING_CART_EMPTY_MESSAGE: 'Your shopping cart is empty.',
  OFFERS_MATCH_FULL_ASKING_PRICE_DESCRIPTION_MESSAGE:
    'Offers that match the full asking price will be instantly accepted.',
  OFFER_SUBMISSION_SUCCESSFUL_MESSAGE: 'Your offer was submitted successfully',
  OFFER_ACCEPTANCE_SUCCESSFUL_DESCRIPTION_MESSAGE: 'You have successfully accepted this offer.',
  OFFER_ACCEPTED_MESSAGE: 'Your offer was accepted!',
  DISCLAIMER_PRIVACY_MESSAGE:
    'Any information you share will never be given or sold to anyone. Your personal information will only be used for shipping, contacting you regarding your account, and learning more about our customers to help us provide valuable products and services.',
  SETTING_OFFER_NOTIFICATION_LIMITS_MESSAGE:
    "Set limits on incoming offers to only allow notifications from offers you're interested in",
  COMING_SOON_MESSAGE: 'Coming Soon',
  USER_INSUFFICIENT_STORE_CREDIT_FOR_PURCHASE_MESSAGE: 'You have insufficient store credit for this purchase.',
  UNABLE_TO_LOAD_STORE_CREDIT_MESSAGE: 'Unable to load Store Credit',
  USER_INSUFFICIENT_CREDIT_FOR_UPGRADE:
    "You don't have sufficient credit to upgrade this item to set it to that price.",
  THANK_YOU_ORDER_MESSAGE: 'Thank you for your order!',
  HAVE_NOT_SEEN_UPDATE_TROUBLESHOOT_MESSAGE_LESS_FIVE_MINUTES:
    "If you haven't seen an update within a few minutes please contact support",
  HAVE_NOT_SEEN_UPDATE_TROUBLESHOOT_MESSAGE_GREATER_FIVE_MINUTES:
    "If you haven't seen an update in over 5 minutes please try refreshing the page",
  CHECK_BACK_LATER_TRACKING_INFORMATION_MESSAGE: 'Check back later for tracking information',
  GO_TO_PAGE_COMC_LEGACY_MESSAGE: 'Go to this page on comc.com',
  ORDER_ON_WAY_MESSAGE: 'Your order is on the way!',
  CHECK_AGAIN_FOR_UPDATES_MESSAGE: 'Check back for updates',
  USER_NO_NOTIFICATIONS_MESSAGE: 'You have no notifications at this time.',
  USER_SAVED_CART_MESSAGE: 'We saved your cart!',
  SOLD_OUT_MESSAGE: 'Sold Out',
  SEARCH_SAVED_MESSAGE: 'Search Saved',
  SAVED_MESSAGE: 'Saved',
  NO_ITEMS_IN_OFFER_MESSAGE: 'There are no items in this offer',
  USER_ADD_TO_SAVED_SEARCHES_MESSAGE: 'Add this search to your Saved Searches',
  USER_NO_SAVED_SEARCHES_MESSAGE: 'You have no saved searches.',
  PRICE_UPDATED_SUCCESSFULLY_MESSAGE: 'Price updated successfully',
  SAVE_PRICE_TOO_LOW_MESSAGE: 'Save again to confirm taking a loss.',
  SAVE_PRICE_TOO_HIGH_MESSAGE: 'Save again to confirm above average price.',
  USER_NO_GRADING_SUBMISSIONS_MESSAGE: "You haven't submitted any items for grading.",
  USER_WHAT_NEXT_QUESTION_MESSAGE: 'What would you like to do now?',
  USER_MUST_BE_SIGNED_IN_ALERT_MESSAGE:
    'To use the instant purchase feature or to manage an item, you must be logged in.',
  CONTINUE_SEARCHING_MATCHING_ITEMS_MESSAGE: 'Continue Searching All Items Matching',
  NONE_FOR_SALE_OTHER_OWNERS_MESSAGE: 'There are none for sale by other owners.',
  USER_NONE_FOR_SALE_MESSAGE: 'You have none for sale.',
  REPROCESS_AS_ELITE_BEFORE_AUCTION_MESSAGE: 'Must reprocess as elite before sending to auction',
  SPECIFY_DECLARED_VALUE_MESSAGE: 'Specify the Declared Value',
  SPECIFY_DECLARED_VALUE_DESCRIPTION_MESSAGE:
    'We need to report a declared value for insurance purposes. Please specify how much you would like to insure this item for in case something happens to the item in transit.',
  USER_SPECIFY_PRICE_OR_CHOOSE_ACTION_MESSAGE: 'Specify your price or choose an action',
  USER_UPDATE_PRICE_HERE_MESSAGE: 'If you like, you can update the price here.',
  USER_OWNS_THIS_ITEM_MESSAGE: 'You own this item',
  WHAT_IS_PROBLEM_QUESTION_MESSAGE: 'What is the problem?',
  NO_ACQUISITION_INFO_MESSAGE: 'No Acquisition Info',
  SET_ASKING_PRICE_PURCHASE_GET_DIFFERENCE_VALUE_MESSAGE:
    'Set an asking price & purchase, get the difference when it sells!',
  USER_STILL_WANT_TO_BUY_MESSAGE: 'Do you still want to buy it now?',
  USER_THANKS_FOR_ALERTING_PROBLEM: 'Thank you for alerting us to this problem.',
  PROBLEM_REPORTED_MESSAGE: 'Problem Reported',
  PURCHASE_RELIST_FOR_SALE_MESSAGE: 'Purchase & Relist for Sale',
  PURCHASE_RELIST_FOR_SALE_DESCRIPTION_MESSAGE:
    'Flip that item! Purchase, set an asking price, and profit the difference when it sells!',
  PURCHASE_NOW_REQUEST_SHIPPING_LATER_MESSAGE: 'Purchase Now & Request Shipping Later',
  PURCHASE_NOW_REQUEST_SHIPPING_LATER_DESCRIPTION_MESSAGE:
    'Add the card to your online inventory, and request a shipment when you are done shopping today, tomorrow, or in a month.',
  BETA_DEV_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE:
    'You are in the Beta Dev website state. This means the code is not stable and transactions are not live. There is limited ability to this website. If you find bugs, please report them.',
  BETA_TEST_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE:
    'You are in the Beta Testing website state. There is limited ability to this website. Transactions will not be processed. Please report any bugs or functionality issues before we send the site live.',
  BETA_FEEDBACK_INSTRUCTIONS_LONG_MESSAGE: `Please give us as much context as possible. For example, give us step-by-step instructions of how you encountered what went wrong. This will help us take care of it faster. When you're done, press "Generate email." An email addressed to us will automatically appear on your screen. If you took any screenshots or screen recordings attach them to the email before sending it to us. Note: You still have to send this email for us to get it. Once you've sent the email, come back here and press "Done" to close this message. If we have any questions about your feedback, we'll email you.`,
  BETA_FEEDBACK_TROUBLESHOOTING_PREFIX_MESSAGE: `If pressing "Generate email" did not work, here's how to share your feedback. First, press the arrow next to this message to expand it. Then, copy the text shown and send it with any screenshots or screen recordings, as well as a description of what went wrong, to`,
  COMC_COMPANY_RIGHTS_RESERVED_MESSAGE: 'Check Out My LLC, All Rights Reserved',
  COMC_COMPANY_WEBSITE_UTILIZING_PATENTED_TECHNOLOGY_SUFFIX_MESSAGE:
    'is doing business for Check Out My LLC and is utilizing patented technology.',
  START_SHOPPING_ITEMS_ON_HOMEPAGE_PREFIX_MESSAGE: 'Start shopping for items at our',
  ITEM_REMOVED_MESSAGE: 'Item Removed',
  ITEM_NOT_FOR_SALE_MESSAGE: 'Item Not For Sale',
  NOT_FOR_SALE_MESSAGE: 'Not For Sale',
  FOR_SALE_MESSAGE: 'For sale',
  ON_SALE_MESSAGE: 'On Sale',
  NO_ACTIVE_OFFERS_MESSAGE: 'No active offers',
  NO_OFFER_HISTORY_MESSAGE: 'No offer history',
  SUCCESS_MESSAGE: 'Success!',
  THIRD_PARTY_PURCHASE_COMMISSION_DISCLAIMER_MESSAGE:
    'When you click on links to various merchants on this site and make a purchase, this can result in this site earning a commission. Affiliate programs and affiliations include, but are not limited to, the eBay Partner Network.',
  COMC_SHIPPING_HANDLING_FEE_NOTICE_MESSAGE: 'S&H will apply if transferred to and shipped from COMC',
  SELECT_PLAYER_TEAM_SELECT_ATTRIBUTES_INSTRUCTIONS_MESSAGE: 'Select a Player or Team to select attributes',
  UPDATE_FILTERS_SPECIFIC_SEE_RESULTS_MESSAGE:
    'Update filters to be more specific or go back to prior page numbers to see results',
  MAX_PAGE_NUMBER_IS_PREFIX_MESSAGE: 'Max page number is',
  USER_CHECK_SEARCH_QUERY_MESSAGE: 'Please check your search query.',
  USER_CHANGE_FILTER_MESSAGE: 'Try changing your filter.',
  USER_SEARCH_NO_RESULTS_RETURNED_MESSAGE: 'Your search did not return any results.',
  ITEM_HAS_CONDITION_NOTES_MESSAGE: 'This item has the following condition notes',
  UNABLE_TO_LOAD_MESSAGE: 'Unable to Load',
  _255_CHAR_MAX_WARNING_MESSAGE: 'Warning: 255 character max',
  COMC_SUBMISSION_PITCH_LONG_MESSAGE: `Let COMC do the work to get your collectibles online. All you need to do is set asking prices and respond to offers. We'll even take care of shipping and payment processing.`,
  COMC_SUBMISSION_MORE_INFO_LINK_MESSAGE: 'Sell baseball cards & other collectibles with COMC.',
  PAGE_NOT_FOUND_MESSAGE: 'Page not found',
  USER_CART_ITEMS_REMINDER_MESSAGE: "Don't forget these items!",
  REQUEST_SHIPMENT_TOP_LOADER_PRICE_MESSAGE: '*All Toploaders are 35&#162; each.',
  REQUEST_SHIPMENT_REMOVE_ALL_ITEMS_MESSAGE: 'Remove all items from this pending shipment',
  SHIPPING_ADDRESS_FORM_ERROR_SAVING_ADDRESS_MESSAGE:
    'There was an error saving your shipping address. Please verify your information.',

  OFFER_HAS_BEEN_ACCEPTED_MESSAGE: 'This offer has been accepted.',
  OFFER_HAS_BEEN_COUNTERED_MESSAGE: 'This offer has been countered.',
  OFFER_HAS_BEEN_REJECTED_MESSAGE: 'This offer has been rejected.',
  OFFER_HAS_BEEN_CANCELED_MESSAGE: 'This offer has been canceled.',
  OFFER_HAS_BEEN_FINISHED_MESSAGE: 'This offer has been finished.',

  USER_CONFIRMATION_CANCEL_OFFER_MESSAGE: 'Are you sure you want to cancel this offer?',
  USER_CONFIRMATION_ACCEPT_OFFER_MESSAGE: 'Are you sure you want to accept this offer?',
  USER_CONFIRMATION_COUNTER_OFFER_MESSAGE: 'Are you sure you want to counter this offer?',
  USER_CONFIRMATION_REJECT_OFFER_MESSAGE: 'Are you sure you want to reject this offer?',

  EXPIRES_LABEL: 'Expires',
  OFFER_DETAILS_LABEL: 'Offer Details',
  AVAILABLE_STORE_CREDIT_LABEL: 'Available Store Credit',
  USER_AVAILABLE_STORE_CREDIT_LABEL: 'Your available store credit',
  PROPOSE_OFFER_TO_SELLER_LABEL: 'Propose your offer to seller',
  OFFER_SUBMITTED_LABEL: 'Offer Submitted',
  INCOMING_OFFERS_LABEL: 'Incoming Offers',
  INSUFFICIENT_FUNDS_LABEL: 'Insufficient Funds',
  CONTINUE_WITH_PURCHASE_LABEL: 'Continue With Purchase?',
  ITEMS_SUMMARY_LABEL: 'Items Summary',
  DELIVERY_METHOD_LABEL: 'Delivery Method',
  ORDER_SUMMARY_LABEL: 'Order Summary',
  PURCHASE_STORE_CREDIT_LABEL: 'Purchase Store Credit',
  SHIPPING_FEE_LABEL: 'Shipping Fee',
  SALES_TAX_LABEL: 'Sales Tax',
  TOTAL_LABEL: 'Total',
  TOTAL_ASKING_PRICE_LABEL: 'Total Asking Price',
  ASKING_LABEL: 'Asking',
  SHIPPING_METHOD_LABEL: 'Shipping Method',
  DASHBOARD_LABEL: 'Dashboard',
  IMMEDIATE_ACTION_LABEL: 'Immediate Action',
  SEND_ITEMS_TO_COMC_LABEL: 'Send items to COMC here',
  USER_LAST_PURCHASES_LABEL: 'Your Last Purchases',
  USER_LAST_SALES_LABEL: 'Your Last Sales',
  GRADE_LABEL: 'Grade',
  ALL_FILTERS_LABEL: 'All Filters',
  AVAILABILITY_LABEL: 'Availability',
  LISTING_FORMAT_LABEL: 'Listing Format',
  AVAILABILITY_FILTER_SELECTION_LABEL: 'Availability & Format',
  SAVED_SEARCH_SETTINGS_LABEL: 'Saved Search Settings',
  SAVED_SEARCHES_LABEL: 'Saved Searches',
  RECENT_SEARCHES_LABEL: 'Recent Searches',
  USER_NAME_SEARCH_LABEL: 'Name Your Search',
  ITEM_INFO_LABEL: 'Item Info',
  ITEM_COST_LABEL: 'Item cost',
  SUFFICIENT_STORE_CREDIT_LABEL: 'Sufficient Store Credit',
  UNCIRCULATED_STOCK_IMAGE_LABEL: 'Uncirculated - Stock Image',
  SELLERS_HUB_MARKETPLACE_LABEL: "Seller's Hub - Marketplace",
  ITEM_DETAILS_LABEL: 'Item Details',
  SELECT_SERVICE_LABEL: 'Select a Service',
  ALL_ITEMS_LABEL: 'All Items',
  UPDATE_PRICE_LABEL: 'Update Price',
  REPORT_PROBLEM_LABEL: 'Report a problem',
  PURCHASE_PRICE_LABEL: 'Purchase price',
  PURCHASE_DATE_LABEL: 'Purchase date',
  ENTER_ASKING_PRICE_LABEL: 'Enter an asking price',
  PURCHASE_OPTIONS_LABEL: 'Purchase Options',
  ITEM_LABEL: 'Item',
  ACQUISITION_INFO_LABEL: 'Acquisition Info',
  COMC_AVAILABILITY_LABEL: 'COMC Availability',
  PRICE_ITEM_ACTIONS_LABEL: 'Price Item and Actions',
  SHIP_ITEMS_TO_COMC_LABEL: 'Ship Items to COMC',
  BROWSE_LABEL: 'Browse',
  OPEN_BROWSE_MENU_LABEL: 'Open Browse Menu',
  SEARCH_COMC_ITEMS_LABEL: 'Search COMC collectibles',
  RESULTS_LABEL: 'Results',
  FUNDS_IN_PENDING_OFFERS_LABEL: 'Funds locked in pending offers',
  LOCKED_IN_OFFERS_LABEL: 'Locked in offers:',
  OFFER_PRICE_LABEL: 'Offer Price',
  ORIGINAL_ASKING_PRICE_LABEL: 'Original Asking Price',
  CONDITION_NOTES_LABEL: 'Condition Notes',
  SERIAL_LABEL: 'Serial',
  OFFER_LABEL: 'Offer',
  OFFER_ACCEPTED_LABEL: 'Offer Accepted',
  ERROR_LABEL: 'Error',
  OWNER_LABEL: 'Owner',
  EBAY_AUCTION_LABEL: 'eBay Auction',
  EXAMPLE_PRODUCT_IMAGE_LABEL: 'Example Product Image',
  START_DATE_LABEL: 'Start Date',
  STARTING_BID_LABEL: 'Starting Bid',
  END_DATE_LABEL: 'End Date',
  WINNING_BID_LABEL: 'Winning Bid',
  ZERO_BIDS_LABEL: '0 Bids',
  ZERO_RESULTS_LABEL: '0 Results',
  ZERO_ITEMS_LABEL: '0 Items',
  AUCTION_ENDED_LABEL: 'Auction Ended',
  CARD_FRONT_BACK_SIDE_BY_SIDE_LABEL: 'Card Front, Back Side-by-side',
  ATTRIBUTES_LABEL: 'Attributes',
  MAGNIFY_HOVER_LABEL: 'Magnify on Hover',
  SHOW_CARD_BACKS_LABEL: 'Show Card Backs',
  PROMOTIONS_LABEL: 'Promotions',
  FIND_SELLER_LABEL: 'Find Seller',
  ALL_LABEL: 'All',
  SELLERS_LABEL: 'Sellers',
  SELLER_LABEL: 'Seller',
  ALL_SELLERS_LABEL: 'All Sellers',
  BUYER_LABEL: 'Buyer',
  ACQUISITION_DATE_LABEL: 'Acquisition Date',
  SALE_DATE_LABEL: 'Sale Date',
  SALE_PRICE_LABEL: 'Sale Price',
  ITEM_PICTURE_LABEL: 'Item Picture',
  STORE_CREDIT_LABEL: 'Store Credit',
  TERMS_CONDITIONS_LABEL: 'Terms & Conditions',
  STARTED_WATCHING_LABEL: 'Started watching',
  NOTE_TO_SELF_LABEL: 'Note to self',
  FIND_OUT_MORE_LABEL: 'Find out more',
  PROFILE_SECTION_LABEL: 'Profile Section',
  ORDER_DETAILS_LABEL: 'Order Details',
  ADD_TOPLOADER_LABEL: 'Add Toploader',
  EDIT_SHIPMENT_REQUEST_LABEL: 'Edit shipment request',
  UNITED_STATES_LABEL: 'United States',

  CONTACT_INFO_LABEL: 'Contact Info',
  PERSONAL_INFO_LABEL: 'Personal Info',

  CONTINUE_SHOPPING_BUTTON: 'Continue Shopping',
  PURCHASE_NOW_BUTTON: 'Purchase Now',
  ADD_STORE_CREDIT_BUTTON: 'Add Store Credit',
  SIGN_IN_BUTTON: 'Sign In/Sign Up',
  SIGN_IN_MOBILE_BUTTON: 'Sign In',
  SIGN_OUT_ACTION: 'Sign Out',
  TRACK_YOUR_ORDER_BUTTON: 'Track your order',
  ADD_MORE_BUTTON: 'Add More',
  SUBMISSION_WIZARD_BUTTON: 'Submission Wizard',
  GO_TO_ITEMS_BUTTON: 'Go to items',
  VIEW_ALL_OFFERS_BUTTON: 'View All Offers',
  SEE_RESULTS_ACTION: 'See results',
  CLEAR_ACTION: 'Clear',
  SAVE_SEARCH_ACTION: 'Save Search',
  SAVE_ACTION: 'Save',
  ITEM_OVERVIEW_ACTION: 'Item Overview',
  SEND_TO_AUCTION_ACTION: 'Send To Auction',
  SUBMIT_FOR_GRADING_ACTION: 'Submit For Grading',
  ELITE_PROCESSING_ACTION: 'Elite Processing',
  SUBMIT_THIS_ITEM_ACTION: 'Submit this item',
  UNLIST_SALE_ACTION: 'Unlist Sale',
  LIST_FOR_SALE_ACTION: 'List For Sale',
  REPORT_FEEDBACK_ACTION: 'Report Feedback',
  PRIVACY_POLICY_ACTION: 'Privacy Policy',
  USER_AGREEMENT_ACTION: 'User Agreement',
  SEE_MORE_ACTION: 'See more',
  VIEW_COUNTER_ACTION: 'View Counter',
  PREVIOUS_OFFER_ACTION: 'Previous Offer',
  PREVIOUS_PAGE_ACTION: 'Previous Page',
  CANCEL_OFFER_ACTION: 'Cancel offer',
  FREQUENTLY_ASKED_QUESTIONS_ACTION: 'Frequently Asked Questions',
  PLACE_BID_ACTION: 'Place Bid',
  SEARCH_ACTION: 'Search',
  CLOSE_ACTION: 'Close',
  BACK_FIRST_PAGE_ACTION: 'Return to the first page',
  CHANGE_ACTION: 'Change',
  ADD_TO_CART_ACTION: 'Add to Cart',
  REMOVE_FROM_CART_ACTION: 'Remove From Cart',
  AGREE_ACTION: 'Agree',
  REMOVE_FROM_WATCH_LIST_ACTION: 'Remove from Watch List',
  NO_DELETE_ITEMS_ACTION: 'No, Delete These Items',
  YES_MOVE_ITEMS_TO_CART_ACTION: 'Yes, Move Items Into Cart',
  GO_TO_SHIPMENT_OPTIONS_ACTION: 'Go to shipment options',
  COMPLETE_ON_COMC_COM_ACTION: 'Complete flow on comc.com',
  ADD_TO_SHIPMENT_ACTION: 'Add to shipment',
  BUYERS_HUB_SHIP_ITEMS_ACTION: "Buyer's Hub: Ship Items",

  ITEM_WORD: 'item',
  ITEMS_WORD: 'items',
  WAS_WORD: 'was',
  WERE_WORD: 'were',
  STRIKE_WORD: 'strike',
  STRIKES_WORD: 'strikes',

  pages: {
    home: {
      title: 'Check Out My Baseball Cards, Comics & Collectibles',
      description:
        'Buy and sell baseball, football, basketball, and hockey cards online with COMC. Order from multiple sellers, but pay shipping one time! Find rookie cards, memorabilia, autographed cards, vintage, modern, and more on COMC.',
    },
  },

  segments: {
    mergeCart: {
      MERGE_CART_ALERT_MESSAGE_0: 'We noticed that there',
      MERGE_CART_ALERT_MESSAGE_1:
        'added to your cart before you signed in. Would you like to move these items into your cart?',
    },
    makeOfferForm: {
      ITEM_STRIKES_LIMIT_ALERT_0: 'You have made',
      ITEM_STRIKES_LIMIT_ALERT_1: 'on this item. Each item only allows 3 attempts.',
    },
    saveSearchForm: {
      INCORRECT_TITLE_LENGTH_ERROR_MESSAGE_0: 'Please choose a title between',
      INCORRECT_TITLE_LENGTH_ERROR_MESSAGE_1: 'characters in length.',
    },
    environmentAlert: {
      BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_0:
        'Thanks for checking out our new website! It&apos;s still in beta, but all buying and selling you do is real. Certain features may not be available yet, but you can always use the',
      LEGACY_COMC_SITE_LINK_TEXT: 'regular COMC site',
      BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_1: 'while we work on the new one.',
      BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_2: 'Something not quite right? Let us know so we can fix it!',
    },
    requestShipmentEdit: {
      ADD_ITEMS_TO_YOUR_SHIPMENT_INSTRUCTIONS_MESSAGE_0: 'Add items to your shipment by clicking',
      ADD_ITEMS_TO_YOUR_SHIPMENT_INSTRUCTIONS_MESSAGE_1: 'in the',
    },
  },
} as const
