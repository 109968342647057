/**
 * @summary Enum for {@link SearchParams.sort} and maps to {@link SavedSearchResponse.sort}.
 *
 *  By default, the API sorts search results using the "Oldest"/"Card #" sorting algorithm and
 *  there is functionally no difference between the following sort options:
 *    - "Oldest" and "Card #"
 *    - "Recently Added" and "Acquisition Date"
 *
 *  These redundant options are represented here to support
 *  cross-platform compatibility when saving and executing searches.
 *
 * @see Comc.Web.Api/Comc.Web.Core/Search/Services/AwsSearchServiceV1.cs#GetSearchCacheOrderBy
 */
export enum ITEM_SORT {
  RECENTLY_ADDED = 'Recently Added',
  CARD_NUMBER = 'Card #',
  OLDEST = 'Oldest',
  NEWEST = 'Newest',
  HIGHEST_SRP = 'Highest SRP',
  HIGHEST_PRICE = 'Highest Price',
  LOWEST_PRICE = 'Lowest Price',
  BIGGEST_DISCOUNT = 'Biggest Discount',
  HIGHEST_PERC_OFF = 'Highest Percent Off',
  PRINT_RUN = 'Print Run',
  LEAST_IN_STOCK = 'Least in Stock',
  MOST_IN_STOCK = 'Most in Stock',
  ENDING_SOONEST = 'Ending Soonest',
  RECENTLY_ACQUIRED = 'Acquisition Date',
}

export const sortEnumToQueryValue = (sort?: ITEM_SORT) => {
  switch (sort) {
    case ITEM_SORT.RECENTLY_ADDED:
      return 'r'
    case ITEM_SORT.CARD_NUMBER:
      return 'c'
    case ITEM_SORT.OLDEST:
      return 'o'
    case ITEM_SORT.NEWEST:
      return 'n'
    case ITEM_SORT.HIGHEST_PRICE:
      return 'h'
    case ITEM_SORT.LOWEST_PRICE:
      return 'l'
    case ITEM_SORT.BIGGEST_DISCOUNT:
      return 'd'
    case ITEM_SORT.HIGHEST_PERC_OFF:
      return 'p'
    case ITEM_SORT.PRINT_RUN:
      return 'q'
    case ITEM_SORT.LEAST_IN_STOCK:
      return 's'
    case ITEM_SORT.MOST_IN_STOCK:
      return 'm'
    case ITEM_SORT.ENDING_SOONEST:
      return 'e'
    case ITEM_SORT.RECENTLY_ACQUIRED:
      return 'a'
    case ITEM_SORT.HIGHEST_SRP:
    default:
      return 'b'
  }
}

export const sortQueryValueToItemSort = (param?: string): ITEM_SORT | undefined => {
  const sort = param?.toLocaleLowerCase()
  switch (sort) {
    case 'o':
    case 'oldest':
      return ITEM_SORT.OLDEST
    case 'c':
    case 'card #':
      return ITEM_SORT.CARD_NUMBER
    case 'n':
    case 'newest':
      return ITEM_SORT.NEWEST
    case 'b':
    case 'highest book value':
      return ITEM_SORT.HIGHEST_SRP
    case 'h':
    case 'highest price':
      return ITEM_SORT.HIGHEST_PRICE
    case 'l':
    case 'lowest price':
      return ITEM_SORT.LOWEST_PRICE
    case 'd':
    case 'biggest discount':
      return ITEM_SORT.BIGGEST_DISCOUNT
    case 'p':
    case 'highest percent off':
      return ITEM_SORT.HIGHEST_PERC_OFF
    case 'r':
    case 'recently added':
    case 'most recently added':
      return ITEM_SORT.RECENTLY_ADDED
    case 'a':
    case 'recently acquired':
    case 'acquired':
    case 'acquisitiondate':
      return ITEM_SORT.RECENTLY_ACQUIRED
    case 'q':
    case 'quantity manufactured':
      return ITEM_SORT.PRINT_RUN
    case 's':
    case 'least in stock':
      return ITEM_SORT.LEAST_IN_STOCK
    case 'm':
    case 'most in stock':
      return ITEM_SORT.MOST_IN_STOCK
    case 'e':
    case 'ending soonest':
      return ITEM_SORT.ENDING_SOONEST
    default:
      return undefined
  }
}
