import type { WatchListResponse } from '@/interfaces/watchList/watchList'
import type { WatchlistApiRequest } from '@/interfaces/watchList/watchListApiRequest'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import { userQueryKeys } from '@/interfaces/user/userQueries'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'
import { watchListItemsURL } from '@/constants/apiURLs'

const useWatchlistItems = (watchlistQueryParams?: WatchlistApiRequest) => {
  const {
    isLoading: isWatchListLoading,
    isError: isWatchListError,
    data: watchList,
    refetch: fetchWatchList,
  } = useAuthenticatedQuery<WatchListResponse>(
    [RequestMethods.GET],
    RequestMethods.GET,
    watchListItemsURL(watchlistQueryParams),
    {
      queryKey: [userQueryKeys.GetWatchList, watchlistQueryParams],
    }
  )

  return { isWatchListLoading, isWatchListError, watchList, fetchWatchList }
}

export default useWatchlistItems
