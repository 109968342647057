import type { FundsSummary } from '@/interfaces/user/fundsSummaryAPI'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import { userFundsSummaryURL } from '@/constants/apiURLs'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'

const useCreditSummary = () => {
  const { data: fundsSummary, ...props } = useAuthenticatedQuery<unknown, unknown, FundsSummary>(
    [RequestMethods.GET],
    RequestMethods.GET,
    userFundsSummaryURL,
    {
      queryKey: ['userCreditSummary'],
    }
  )

  return { fundsSummary, ...props }
}

export default useCreditSummary
