import {
  Campaign,
  CampaignOutlined,
  Dashboard,
  DashboardOutlined,
  Extension,
  ExtensionOutlined,
  Favorite,
  FavoriteBorder,
  ShoppingBasket,
  ShoppingBasketOutlined,
  StarBorderOutlined,
  StarSharp,
  StorefrontOutlined,
  StorefrontRounded,
} from '@mui/icons-material'
import { get, omit } from 'lodash'
import URI from 'urijs'

import {
  AUCTION_HISTORY_PAGE,
  CASHOUT_PAGE,
  CREDIT_HISTORY_PAGE,
  ELITE_PROCESSING_PAGE,
  MY_PORT_PAGE,
  PROMOTIONS_CREATION_PAGE,
  REDEEM_CREDIT_PAGE,
  REFUND_REQUEST_PAGE,
  SALES_HISTORY_PAGE,
  SELLERS_STATS_PAGE,
  SUBMISSION_WIZARD_PAGE,
  ADD_STORE_CREDIT,
  getLegacyUrl,
  PROCESSING_SUBMISSIONS,
} from './vintageLinks'
import PaymentsFilledSymbol from '@/components/icons/PaymentsFilledSymbol'
import PaymentsOutlinedSymbol from '@/components/icons/PaymentsOutlinedSymbol'
import { canonicalizePath } from '@/helpers/urlParser'
import {
  type DashboardNodeLink,
  type DashboardRoute,
  DashboardSection,
  type IDashboardSection,
} from '@/interfaces/dashboard'

export const DASHBOARD_BASE_HREF = '/user/dashboard'

export const makeDashboardHref = (...path: string[]): string => {
  const subpath = path.join('/')
  if (subpath.startsWith(DASHBOARD_BASE_HREF)) {
    return subpath
  } else {
    return [DASHBOARD_BASE_HREF, subpath].join('/')
  }
}

export enum DashboardSections {
  SUMMARY = 'SUMMARY',
  GRADING = 'GRADING',
  OFFERS = 'OFFERS',
  SELLERS_HUB = 'SELLERS_HUB',
  STORE_CREDIT = 'STORE_CREDIT',
  WATCHING = 'WATCHING',
  CHALLENGES = 'CHALLENGES',
  BUYERS_HUB = 'BUYERS_HUB',
}

export enum DashboardSubPages {
  SELLERS_ACTIVE = 'Active',
  HISTORY = 'History',
  AUCTION_ITEMS = 'Send to Auction',
  ACTIVE_AUCTIONS = 'Active Auctions',
  PROCESS_ITEMS = 'Reprocess as Elite',
  PROCESSING_SUBMISSIONS = 'Current Submissions',
  SHIP_ITEMS = 'Ship Items',
  REQUEST = 'Request',
  PURCHASE_HISTORY = 'Purchase History',
}

type DashboardSectionDict<T> = Record<DashboardSections, T>

export const DashboardSectionSlugs: DashboardSectionDict<string> = {
  [DashboardSections.SUMMARY]: '',
  [DashboardSections.GRADING]: 'grading',
  [DashboardSections.OFFERS]: 'offers',
  [DashboardSections.SELLERS_HUB]: 'sellers-hub',
  [DashboardSections.STORE_CREDIT]: 'store-credit',
  [DashboardSections.WATCHING]: 'watching',
  [DashboardSections.CHALLENGES]: 'challenges',
  [DashboardSections.BUYERS_HUB]: 'buyers-hub',
} as const

export const DashboardPageKeys = {
  [DashboardSections.SUMMARY]: {
    DEFAULT: 'default',
  },
  [DashboardSections.GRADING]: {
    GRADE_ITEMS: 'grade-items',
    GRADE_IN_PROGRESS: 'grading-in-progress',
    GRADE_HISTORY: 'history',
  },
  [DashboardSections.OFFERS]: {
    ACTIVE_OFFERS: 'active',
    OFFER_HISTORY: 'history',
  },
  [DashboardSections.SELLERS_HUB]: {
    MARKETPLACE: 'marketplace',
    AUCTION: 'auction',
    ELITE: 'elite-submission',
    PROMOTION: 'promotion',
    SUBMISSION_WIZARD: 'submission-wizard',
    PROCESSING_SUBMISSIONS: 'processing-submissions',
    ALL_SALES_HISTORY: 'all-sales-history',
    SELLERS_STATS: 'sellers-stats',
    MY_PORT: 'my-port',
  },
  [DashboardSections.STORE_CREDIT]: {
    ADD: 'add',
    REFUND: 'refund',
    CASHOUT: 'cashout',
    REDEEM: 'redeem',
    HISTORY: 'history',
  },
  [DashboardSections.WATCHING]: {
    WATCH_LIST: 'watchlist',
    SAVED_SEARCHES: 'saved-searches',
  },
  [DashboardSections.BUYERS_HUB]: {
    SHIP_ITEMS: 'ship-items',
    PURCHASE_HISTORY: 'purchase-history',
  },
} as const

export const makeDashboardRouteGuid = (sectionKey: DashboardSections, pageKey: string) => {
  return [sectionKey, pageKey].join(':')
}

type DashboardNodeLinkInput = Partial<Omit<DashboardNodeLink, 'key' | 'text'>> & {
  key: string
  text: string
}

type DashboardSectionInput = Omit<IDashboardSection, 'pages' | 'slug'> & {
  slug?: string
  pages: DashboardNodeLinkInput[]
}

/** @deprecated This needs to be added to DashboardSection as a class constructor */
const createDashboardSection = (init: DashboardSectionInput): DashboardSection => {
  const slug = init.slug || DashboardSectionSlugs[init.key]
  const noPages = omit(init, 'pages')
  const nodes = [...init.pages]
  const parentKey = init.key
  const pages: DashboardNodeLink[] = nodes.map((node) => {
    const guid = node.guid || makeDashboardRouteGuid(parentKey, node.key)
    const enabled = node.enabled === undefined ? true : node.enabled
    const href = node.href || makeDashboardHref(slug, node.key)
    const asUri = () => new URI(href)
    return { ...node, parentKey, guid, enabled, href, asUri } as DashboardNodeLink
  })

  return new DashboardSection({
    ...noPages,
    slug,
    pages,
  })
}

export const DASHBOARD_SECTIONS: DashboardSectionDict<DashboardSection> = {
  [DashboardSections.SUMMARY]: createDashboardSection({
    key: DashboardSections.SUMMARY,
    primaryText: 'Summary',
    icon: DashboardOutlined,
    activeIcon: Dashboard,
    pages: [
      {
        key: DashboardPageKeys.SUMMARY.DEFAULT,
        text: 'Summary',
        href: DASHBOARD_BASE_HREF,
      },
    ],
  } as DashboardSectionInput),
  [DashboardSections.GRADING]: createDashboardSection({
    key: DashboardSections.GRADING,
    primaryText: 'Grading',
    icon: StarBorderOutlined,
    activeIcon: StarSharp,
    pages: [
      {
        key: DashboardPageKeys.GRADING.GRADE_ITEMS,
        text: 'Grade Items',
      },
      {
        key: DashboardPageKeys.GRADING.GRADE_IN_PROGRESS,
        text: 'In Progress',
      },
      {
        key: DashboardPageKeys.GRADING.GRADE_HISTORY,
        text: 'History',
      },
    ],
  } as DashboardSectionInput),
  [DashboardSections.OFFERS]: createDashboardSection({
    key: DashboardSections.OFFERS,
    primaryText: 'Offers',
    icon: CampaignOutlined,
    activeIcon: Campaign,
    pages: [
      {
        key: DashboardPageKeys.OFFERS.ACTIVE_OFFERS,
        text: 'Current Offers',
        tabName: 'Active',
      },
      {
        key: DashboardPageKeys.OFFERS.OFFER_HISTORY,
        text: 'Offer History',
        tabName: 'Offer History',
        enabled: false,
      },
    ],
  } as DashboardSectionInput),
  [DashboardSections.SELLERS_HUB]: createDashboardSection({
    key: DashboardSections.SELLERS_HUB,
    primaryText: `Seller's Hub`,
    secondaryText: 'Marketplace, Auction, Promotions',
    pages: [
      {
        key: DashboardPageKeys.SELLERS_HUB.MARKETPLACE,
        text: 'Marketplace',
        initalActiveTab: DashboardSubPages.SELLERS_ACTIVE,
        subSections: [
          { tabName: DashboardSubPages.SELLERS_ACTIVE },
          { tabName: DashboardSubPages.HISTORY, href: SALES_HISTORY_PAGE },
        ],
      },
      {
        key: DashboardPageKeys.SELLERS_HUB.AUCTION,
        text: 'Auction',
        initalActiveTab: DashboardSubPages.AUCTION_ITEMS,
        subSections: [
          { tabName: DashboardSubPages.AUCTION_ITEMS },
          { tabName: DashboardSubPages.HISTORY, href: AUCTION_HISTORY_PAGE },
        ],
      },
      {
        key: DashboardPageKeys.SELLERS_HUB.ELITE,
        text: 'Elite Submission',
        initalActiveTab: DashboardSubPages.PROCESS_ITEMS,
        subSections: [
          { tabName: DashboardSubPages.PROCESS_ITEMS },
          { tabName: DashboardSubPages.PROCESSING_SUBMISSIONS, href: ELITE_PROCESSING_PAGE },
          { tabName: DashboardSubPages.HISTORY, href: ELITE_PROCESSING_PAGE },
        ],
      },
      {
        key: DashboardPageKeys.SELLERS_HUB.PROMOTION,
        text: 'Promotion',
        externalHref: PROMOTIONS_CREATION_PAGE,
      },
      {
        key: DashboardPageKeys.SELLERS_HUB.SUBMISSION_WIZARD,
        text: 'Submission Wizard',
        externalHref: SUBMISSION_WIZARD_PAGE,
      },
      {
        key: DashboardPageKeys.SELLERS_HUB.PROCESSING_SUBMISSIONS,
        text: 'Processing Submissions',
        externalHref: PROCESSING_SUBMISSIONS,
      },
      {
        key: DashboardPageKeys.SELLERS_HUB.ALL_SALES_HISTORY,
        text: 'Sales History',
      },
      {
        key: DashboardPageKeys.SELLERS_HUB.SELLERS_STATS,
        text: `Seller's Stats`,
        externalHref: SELLERS_STATS_PAGE,
      },
      {
        key: DashboardPageKeys.SELLERS_HUB.MY_PORT,
        text: 'My Port',
        externalHref: MY_PORT_PAGE,
      },
    ],
    icon: StorefrontOutlined,
    activeIcon: StorefrontRounded,
  } as DashboardSectionInput),
  [DashboardSections.STORE_CREDIT]: createDashboardSection({
    key: DashboardSections.STORE_CREDIT,
    primaryText: 'Store Credit',
    secondaryText: 'Add, Cash, Redeem',
    icon: PaymentsOutlinedSymbol,
    activeIcon: PaymentsFilledSymbol,
    pages: [
      {
        key: DashboardPageKeys.STORE_CREDIT.ADD,
        text: 'Add Store Credit',
        externalHref: ADD_STORE_CREDIT,
      },
      {
        key: DashboardPageKeys.STORE_CREDIT.REFUND,
        text: 'Refund',
        externalHref: REFUND_REQUEST_PAGE,
      },
      {
        key: DashboardPageKeys.STORE_CREDIT.CASHOUT,
        text: 'Cash Out Credit',
        externalHref: CASHOUT_PAGE,
      },
      {
        key: DashboardPageKeys.STORE_CREDIT.REDEEM,
        text: 'Redeem Credit',
        externalHref: REDEEM_CREDIT_PAGE,
      },
      {
        key: DashboardPageKeys.STORE_CREDIT.HISTORY,
        text: 'History',
        externalHref: CREDIT_HISTORY_PAGE,
      },
    ],
  } as DashboardSectionInput),
  [DashboardSections.WATCHING]: createDashboardSection({
    key: DashboardSections.WATCHING,
    primaryText: 'Watching',
    pages: [
      {
        key: DashboardPageKeys.WATCHING.WATCH_LIST,
        text: 'Watch List',
      },
      {
        key: DashboardPageKeys.WATCHING.SAVED_SEARCHES,
        text: 'Saved Searches',
      },
    ],
    icon: FavoriteBorder,
    activeIcon: Favorite,
  } as DashboardSectionInput),
  [DashboardSections.CHALLENGES]: createDashboardSection({
    key: DashboardSections.CHALLENGES,
    primaryText: 'COMC Challenge',
    icon: ExtensionOutlined,
    activeIcon: Extension,
    pages: [],
    externalHref: getLegacyUrl('/Challenges/Default'),
  } as DashboardSectionInput),
  [DashboardSections.BUYERS_HUB]: createDashboardSection({
    key: DashboardSections.BUYERS_HUB,
    primaryText: `Buyer's Hub`,
    secondaryText: 'Ship Items, Purchase History',
    pages: [
      {
        key: DashboardPageKeys.BUYERS_HUB.SHIP_ITEMS,
        text: 'Ship Items',
        initalActiveTab: DashboardSubPages.REQUEST,
        subSections: [{ tabName: DashboardSubPages.REQUEST }, { tabName: DashboardSubPages.HISTORY }],
      },
      {
        key: DashboardPageKeys.BUYERS_HUB.PURCHASE_HISTORY,
        text: 'Purchase History',
      },
    ],
    icon: ShoppingBasketOutlined,
    activeIcon: ShoppingBasket,
  } as DashboardSectionInput),
}

export const DEFAULT_DASHBOARD_SECTION_KEY: DashboardSections = DashboardSections.SUMMARY
export const DEFAULT_DASHBOARD_SECTION = DASHBOARD_SECTIONS[DEFAULT_DASHBOARD_SECTION_KEY]
export const DEFAULT_DASHBOARD_PAGE = DEFAULT_DASHBOARD_SECTION.pages[0]
export const DEFAULT_DASHBOARD_ROUTE: DashboardRoute = {
  guid: DEFAULT_DASHBOARD_PAGE.guid,
  section: DEFAULT_DASHBOARD_SECTION,
  link: DEFAULT_DASHBOARD_PAGE,
  pathname: DEFAULT_DASHBOARD_PAGE.href,
} as const

/**
 * Dashboard Structure
 * - Ordered by index
 */
export const DASHBOARD_PAGES: DashboardSection[] = [
  DASHBOARD_SECTIONS.SUMMARY,
  DASHBOARD_SECTIONS.OFFERS,
  DASHBOARD_SECTIONS.GRADING,
  DASHBOARD_SECTIONS.WATCHING,
  DASHBOARD_SECTIONS.SELLERS_HUB,
  DASHBOARD_SECTIONS.BUYERS_HUB,
  DASHBOARD_SECTIONS.STORE_CREDIT,
  DASHBOARD_SECTIONS.CHALLENGES,
]

export const DASHBOARD_ROUTES_BY_HREF = Object.fromEntries(
  DASHBOARD_PAGES.flatMap((section) =>
    section.pages.map((link) => [link.href, { section, link, pathname: link.href, guid: link.guid } as DashboardRoute])
  )
)

export const DASHBOARD_ROUTES_BY_GUID = Object.fromEntries(
  DASHBOARD_PAGES.flatMap((section) =>
    section.pages.map((link) => [link.guid, { section, link, pathname: link.href, guid: link.guid } as DashboardRoute])
  )
)

export const getDashboardRouteByHref = (
  path?: string | URL,
  fallback: DashboardRoute | null = DEFAULT_DASHBOARD_ROUTE
) => {
  let route = fallback
  if (path) {
    const pathname = canonicalizePath(path)
    route = get(DASHBOARD_ROUTES_BY_HREF, pathname, fallback)
  }
  return route
}

export const getDashboardRouteByGuid = (guid: string, fallback: DashboardRoute | null = DEFAULT_DASHBOARD_ROUTE) => {
  return get(DASHBOARD_ROUTES_BY_GUID, guid, fallback)
}
export const getDashboardRouteByKeys = (sectionKey: DashboardSections, pageKey: string) => {
  const guid = makeDashboardRouteGuid(sectionKey, pageKey)
  return getDashboardRouteByGuid(guid)
}

export function getDashboardRouteByKeysOrDefault(
  sectionKey: DashboardSections,
  pageKey: string,
  fallback: DashboardRoute = DEFAULT_DASHBOARD_ROUTE
): DashboardRoute {
  return getDashboardRouteByKeys(sectionKey, pageKey) || fallback
}

export const getSellersHubHref = (): string => {
  const sectionKey = DashboardSections.SELLERS_HUB
  const pageKey = DashboardPageKeys[sectionKey].MARKETPLACE
  const route = getDashboardRouteByKeys(sectionKey, pageKey)
  return route!.pathname
}

export const getAddCreditUri = () => {
  const route = getDashboardRouteByKeys(DashboardSections.STORE_CREDIT, DashboardPageKeys.STORE_CREDIT.ADD)
  return route!.link.asUri()
}
