import {
  INVENTORY_PRICE_SETTING,
  INVENTORY_SORT,
  type UserInventoryApiRequest,
} from '@/interfaces/inventory/inventoryApiRequest'

export const INVENTORY_ITEMS_PER_PAGE = 100

export const DEFAULT_INVENTORY_PARAMS: UserInventoryApiRequest = {
  sort: INVENTORY_SORT.ACQUISITION_DATE,
  priceSetting: INVENTORY_PRICE_SETTING.ALL,
  startRowIndex: 0,
  maximumRows: INVENTORY_ITEMS_PER_PAGE,
}

export const UPGRADE_ITEM_TO_PRICE_HIGHER_AMOUNT = 2

export const MATCHING_INVENTORY_ITEMS_PER_PAGE = 25
