import type { FC } from 'react'

import { AppBar, Toolbar, useMediaQuery } from '@mui/material'

import theme from '@/themes/comcTheme'
import MobileNavigation from '@/components/navigation/MobileNavigation'
import DesktopNavigation from '@/components/navigation/DesktopNavigation'

const Header: FC = () => {
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <AppBar position="static" color="default">
      <Toolbar
        id="back-to-top-anchor"
        sx={{
          minHeight: { xs: '128px', md: 'initial' },
          pb: { xs: 1, md: 'initial' },
          display: 'flex',
          justifyContent: { md: 'center' },
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {isSmallScreenSize ? <MobileNavigation /> : <DesktopNavigation />}
      </Toolbar>
    </AppBar>
  )
}

export default Header
