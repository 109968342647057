import { useState } from 'react'

const useToggleState = (initialState = false) => {
  const [toggleState, setToggleState] = useState(initialState)

  const toggleStateHandler = () => {
    setToggleState((prevState) => {
      return !prevState
    })
  }

  return {
    toggleState,
    setToggleState,
    toggleStateHandler,
  }
}

export default useToggleState
