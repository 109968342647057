import type { NextComponentType, NextPageContext } from 'next'
import type { AppProps } from 'next/app'
import type { AuthPageProp } from '@/interfaces/auth/auth'

import AuthGuard from './AuthGuard'
import Layout from './Layout'
import ErrorBoundaryWrapper from '@/components/ui/ErrorBoundary'

interface PageContentProps extends AppProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: NextComponentType<NextPageContext, any, any> & AuthPageProp
}

const PageContent = (props: PageContentProps) => {
  const { Component, pageProps } = props

  return (
    <ErrorBoundaryWrapper>
      <Layout>
        {Component.requireAuth ? (
          <AuthGuard>
            <Component {...pageProps} />
          </AuthGuard>
        ) : (
          <Component {...pageProps} />
        )}
      </Layout>
    </ErrorBoundaryWrapper>
  )
}

export default PageContent
