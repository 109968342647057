import URI from 'urijs'

// Global navigation Links
export function getLegacyUrl(path: string | string[]): URI {
  const host = process.env.NEXT_PUBLIC_LEGACY_CANONICAL_HOST
  return new URI(`https://${host}/`).segment(path).normalize()
}

export const ON_SALE_ITEMS = getLegacyUrl('/Promotions/Sale')
export const EXPLORE_PLAYERS = getLegacyUrl('/Players')
export const EXPLORE_TEAMS = getLegacyUrl('/Teams')
export const EXPLORE_BRAND_SETS = getLegacyUrl('/Brands')
export const EXPLORE_TOP_SELLERS = getLegacyUrl('/Users')
export const CHARITY_ACCOUNTS = getLegacyUrl('/Charity')
export const PORT_SALES = getLegacyUrl('/Promotions/Port_Sale')

// Dashboard Pages - Seller's Hub
export const PROMOTIONS_CREATION_PAGE = getLegacyUrl('/Manage/Promotions/Create')
export const SUBMISSION_WIZARD_PAGE = getLegacyUrl('/Manage/SubmissionWizard')
export const PROCESSING_SUBMISSIONS = getLegacyUrl('/Manage/ProcessingStatus')
export const SALES_HISTORY_PAGE = getLegacyUrl('/Manage/MyStats')
export const SELLERS_STATS_PAGE = getLegacyUrl('/Manage/MyStats')
export const MY_PORT_PAGE = (currentUser: string) => getLegacyUrl(`/Users/${currentUser},sr,i100`)
export const PROFILE_PAGE = getLegacyUrl('/Manage/Profile')
export const QR_CODE = getLegacyUrl('/Manage/MyQRCode')

// Dashboard Pages - Seller's Hub - Auction
export const AUCTION_HISTORY_PAGE = getLegacyUrl('/Manage/Auctions')

// Dashboard Pages - Seller's Hub - Elite
export const ELITE_PROCESSING_PAGE = getLegacyUrl('/Manage/GradingSubmissions')

// Dashboard Pages - Store Credit
export const ADD_STORE_CREDIT = getLegacyUrl('/Manage/AddCredit')
export const REFUND_REQUEST_PAGE = getLegacyUrl('/Manage/CashOuts/Request')
export const CASHOUT_PAGE = getLegacyUrl('/Manage/CashOuts/Request')
export const REDEEM_CREDIT_PAGE = getLegacyUrl('/Manage/RedeemStoreCredit')
export const CREDIT_HISTORY_PAGE = getLegacyUrl('/Manage/CreditHistory')

// Dashboard Pages - Buyer's Hub
export const SHIP_ITEMS_PAGE = getLegacyUrl('/Manage/Shipment/EditRequest')
export const SHIPMENT_HISTORY_PAGE = getLegacyUrl('/Manage/ShipmentHistory')
export const PURCHASE_HISTORY_PAGE = getLegacyUrl(`/Manage/Purchases`).query({
  PurchaseYear: new Date().getFullYear(),
})

// Link to the COMC Selling Info page
export const CARD_SELLING_INFO_PAGE = getLegacyUrl('/Sell').toString()
