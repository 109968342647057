import type { FC } from 'react'

import Link from 'next/link'
import Image from 'next/image'
import { Stack } from '@mui/material'

import NavigationItems from './NavigationItems'
import SearchBar from './SearchBar'
import BrowseMenu from './BrowseMenu'

const MobileNavigation: FC = () => {
  return (
    <Stack width="100%" spacing={1} py={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={5}>
          <Link href="/" shallow={false}>
            <Image height={34} width={45} src="/assets/comc_logo_shield_red.svg" alt="COMC logo" />
          </Link>
          <BrowseMenu />
        </Stack>
        <NavigationItems />
      </Stack>
      <SearchBar />
    </Stack>
  )
}

export default MobileNavigation
