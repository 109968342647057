import type { ResultWImage } from '@/interfaces/resultWImage'
import type { Nullable } from '@/types/utility'
import type { PaginatedApiRequest, PaginatedApiResponse } from '@/interfaces/api/pagination'

export const PROMOTIONS_PER_PAGE = 30

export enum PROMOTION_SORT {
  RECENTLY_POSTED = 'RecentlyPosted',
  ENDING_SOONEST = 'EndingSoonest',
  PERC_OFF_ASKING = '%OffAskingPrice',
  PERC_OFF_SRP = '%OffSRP',
  AVERAGE_SRP = 'AverageSRP',
  MOST_ITEMS = 'MostItems',
  SELLER = 'Seller',
}

export const PROMOTION_SORT_TEXT = {
  [PROMOTION_SORT.RECENTLY_POSTED]: 'Recently Posted',
  [PROMOTION_SORT.ENDING_SOONEST]: 'Ending Soonest',
  [PROMOTION_SORT.PERC_OFF_ASKING]: '% Off Asking Price',
  [PROMOTION_SORT.PERC_OFF_SRP]: '% Off SRP',
  [PROMOTION_SORT.AVERAGE_SRP]: 'Average SRP',
  [PROMOTION_SORT.MOST_ITEMS]: 'Most Items',
  [PROMOTION_SORT.SELLER]: 'Seller',
}

export enum PROMOTION_TYPE {
  EBAY_AUCTION = 'eBay_Auction',
  SALE = 'Sale',
  PORT_SALE = 'Port_Sale',
}

export const PROMOTION_TYPE_TEXT = {
  [PROMOTION_TYPE.EBAY_AUCTION]: 'eBay Auction',
  [PROMOTION_TYPE.SALE]: 'Sale',
  [PROMOTION_TYPE.PORT_SALE]: 'Port Sale',
}

export enum PROMOTION_SELLER_FILTER {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  Number = '#-##',
}

export const transformSortOrder = (sortString?: PROMOTION_SORT) => {
  switch (sortString) {
    case PROMOTION_SORT.PERC_OFF_SRP:
      return 'b'
    case PROMOTION_SORT.RECENTLY_POSTED:
      return 'r'
    case PROMOTION_SORT.PERC_OFF_ASKING:
      return 'p'
    case PROMOTION_SORT.AVERAGE_SRP:
      return 'a'
    case PROMOTION_SORT.MOST_ITEMS:
      return 'm'
    case PROMOTION_SORT.SELLER:
      return 's'
    case PROMOTION_SORT.ENDING_SOONEST:
      return 'e'
    default:
      return undefined
  }
}

export const transformSellerFilter = (seller?: PROMOTION_SELLER_FILTER) => {
  switch (seller) {
    case PROMOTION_SELLER_FILTER.Number:
      return '-'
    default:
      return seller
  }
}

export type PromotionFilterParams = {
  type?: PROMOTION_TYPE
  sortOrder?: PROMOTION_SORT
  seller?: PROMOTION_SELLER_FILTER
} & PaginatedApiRequest

export type PromotionRequest = {
  sellerFilter?: string
  categoryPath?: string
  sortOrder?: string
} & PaginatedApiRequest

export const DEFAULT_PROMOTION_FILTERS: PromotionFilterParams = {
  pageNumber: 1,
  pageSize: PROMOTIONS_PER_PAGE,
}

// TODO: Once more data is available update these props with the appropriate types
export interface PromotionResult extends Omit<ResultWImage, 'itemId'> {
  averageDiscountPercentage?: Nullable<number>
  averageSuggestedPrice?: Nullable<number>
  averageSuggestedPriceDiscountPercentage?: Nullable<number>
  averageSuggestedPriceTopDiscountPercentage?: Nullable<number>
  averageTopDiscountPercentage?: Nullable<number>
  category: string
  categoryPath: string
  endDate: string
  fromYear: null // Still unknown
  isAuction: boolean
  minimumBid?: Nullable<number>
  portPrice: null // Still unknown
  promotionId: number
  sellerName: string
  sportId: null // Still unknown
  sportName: string
  startDate: string
  title: string
  titlePath: string
  toYear: null // Still unknown
  totalItemShipping?: Nullable<number>
  totalItems?: Nullable<number>
  totalSuggestedPrice?: Nullable<number>
}

// TODO: Make a variation of interface for other promotion variations
export interface PromotionView extends Omit<ResultWImage, 'itemId'> {
  id: number
  promotionType: string
  promotionTypeTitle: string
  title: string
  sellerName: string
  endDate: string
  discountPercentage?: Nullable<number>
}

export const transformPromotionToView = (promotion: PromotionResult): PromotionView => {
  return {
    id: promotion.promotionId,
    imageId: promotion.imageId,
    listingPath: promotion.listingPath,
    promotionType: promotion.categoryPath,
    promotionTypeTitle: promotion.category,
    title: promotion.title,
    sellerName: promotion.sellerName,
    endDate: promotion.endDate,
  }
}

export interface PromotionTypeInformation {
  categoryPaths: PROMOTION_TYPE[]
  categoryCounts: {
    [key in PROMOTION_TYPE]?: number
  }
}

export interface PromotionResponse extends PaginatedApiResponse<PromotionResult>, PromotionTypeInformation {}

export const transformPromotionType = (type?: PROMOTION_TYPE) => {
  switch (type) {
    case PROMOTION_TYPE.EBAY_AUCTION:
      return 'eBay_Auction'
    case PROMOTION_TYPE.SALE:
      return 'Sale'
    case PROMOTION_TYPE.PORT_SALE:
      return 'Port_Sale'
    default:
      return undefined
  }
}
