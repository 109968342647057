import type { BrowseItem, BrowseItemsByCategory, BrowseListResponse } from '@/interfaces/navigation/browse'

import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { browseCategoriesListURL } from '@/constants/middlewareApiURLs'

// TODO: Update to be a direct API request
const useBrowseCategories = () => {
  const getBrowseList = useCallback(async () => {
    return clientAPIRequest<BrowseListResponse, unknown>(browseCategoriesListURL, RequestMethods.GET)
  }, [])

  const { isLoading: isBrowseListLoading, data: browseList } = useQuery({
    queryKey: ['getBrowseList'],
    queryFn: getBrowseList,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data.searches.reduce((browseListByCategory: BrowseItemsByCategory, item: BrowseItem) => {
        if (!browseListByCategory[item.category]) {
          browseListByCategory[item.category] = []
        }
        browseListByCategory[item.category].push(item)
        return browseListByCategory
      }, {})
    },
  })

  return { browseList, isBrowseListLoading }
}

export default useBrowseCategories
