import type { LinkProps } from 'next/link'
import type { FC } from 'react'

import { Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import Link from '@/components/ui/Link'

type FooterSectionProps = {
  title: string
  links: {
    text: string
    props: LinkProps
  }[]
}

const FooterSection: FC<FooterSectionProps> = ({ title, links }) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant="body2" color={grey[400]} mb={1} fontWeight="bold">
        {title}
      </Typography>
      {links.map((link) => {
        return (
          <Link {...link.props} color={grey[50]} fontSize=".8em" key={link.text}>
            {link.text}
          </Link>
        )
      })}
    </Stack>
  )
}

export default FooterSection
